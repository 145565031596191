import { takeEvery, call, put } from "redux-saga/effects";

import axios from "axios";
import { LoginApi } from "../apis/apis";
import { SuccessfullyLoginAction, UnsuccesFullyLogin } from "../actions/loginAction";

export function* watchLoginAction() {
  yield takeEvery("LOGIN_ACTION", handleLoginAction);
}

function* handleLoginAction({ payload }) {
  try {
    const auth = yield call(handleloginAxios, payload);
    if (auth.hzStatus === 200) {
      //  window.location="/admin"
      yield put(SuccessfullyLoginAction(auth));
    } else if (auth.hzStatus === 400) {
      console.log("Is it working");
      yield put(UnsuccesFullyLogin(auth.message));
    }
  } catch (err) {}
}

const handleloginAxios = async (authDetails) => {
  try {
    const res = await axios.post(LoginApi, authDetails, {
      headers: { "x-api-key": process.env.REACT_APP_AUTH_X_API_KEY },
    });
    if (res.data.hzStatus == 200) {
      localStorage.setItem("token", res.data.jwtToken);
      localStorage.setItem("refreshToken", res.data.refreshToken);

      return res.data;
    } else {
      return res.data;
    }
  } catch (err) {
    return { message: err.response.data.message };
  }
};
