import { successColor, whiteColor, grayColor, hexToRgb } from "assets/jss/material-dashboard-react.js";

const projectStyle = {
  avatar: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    textAlign: "center",
    background: "rgba(255, 255, 255, 0.6)",
    boxShadow: "0px 8px 20px rgba(63, 39, 102, 0.12)",
    backdropFilter: "blur(20px)",
    borderRadius: "12px",
    width: "48px",
    height: "48px",
  },
  icon: { width: "48px" },
  typography: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    height: "16px",
    width: "87px",
    left: "9.17%",
    right: "84.79%",
    top: "calc(50% - 16px/2 + 11px)",
  },
  card: {
    background: "rgba(255, 255, 255, 0.2)",
    boxShadow: "13.0709px 13.0709px 104.567px rgba(63, 39, 102, 0.1)",
    backdropFilter: "blur(26.1418px)",
    borderRadius: "24px",
    maxWidth: "412px",
    maxheight: "447px",
    marigin: "24px",
    // cursor:'pointer',
  },
  cardHeader: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#424242",
  },
  divider: {
    height: "0px",
    opacity: "0.5",
    border: "1px solid #FFFFFF",
    background: "rgba(255, 255, 255, 0.2)",
  },
  heading: {
    width: "98px",
    height: "33px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "33px",
    color: "#141D46",
  },
  Button: {
    position: "absolute",
    width: "180px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    alignItem: "right",
    "&.MuiButton-root": {
      padding: 0,
      textTransform: "none",
    },
  },
  subheader: {
    height: "30px",
  },
  popper: {
    maxWidth: "259px",
    maxHeight: "322px",
    width: "168px",
    height: "auto",
    left: "425px",
    top: "278px",
    background: "#FFFFFF",
    "box-shadow": "0px 2px 24px rgba(0, 0, 0, 0.1)",
    "border-radius": "8px",
  },
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
  popTypo: {
    width: "60px",
    height: "22px",
    left: "1262px",
    top: "275px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "400",
    "font-size": "16px",
    "line-height": "22px",
    color: "#424242",
  },
  totalScreen: {
    width: "21px",
    height: "20px",
    left: "124px",
    top: "269px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "400",
    "font-size": "18px",
    "line-height": "0px",
    "text-align": "center",
    color: "#424242",
  },
  screenTypo: {
    width: "159px",
    height: "20px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "200",
    "font-size": "16px",
    "line-height": "22px",
    "text-align": "left",
    color: "#777777",
  },
};

export default projectStyle;
