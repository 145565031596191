import React, { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";

// @material-ui/core components
import Primary from "../../../components/Typography/Primary";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import {
  Box,
  Typography,
  InputBase,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

//Components
import Table from "components/Table/_Table";
import Pagination from "../../../components/widgetManagerComponents/Pagination";
import Menuitem from "components/Menu/MenuItem";
import Snackbar from "components/Snackbar/CustomSnackbar";
// Actions
import { getCategoryTreeAction, getWidgetAction,getSearchWidget } from "../actions";
//Images
import AddSign from "assets/img/addWidgetManager/AddSign.svg";
import BackIcon from "../../../assets/img/platformScreenVisualization/BackIcon.svg";
import NextIcon from "../../../assets/img/platformScreenVisualization/NextIcon.svg";
import SearchIcon from "../../../assets/img/icons/SearchIcon.svg";
import DropDown from "../../../assets/img/icons/dropDown.svg";

//Style
import styles from "assets/jss/material-dashboard-react/views/widgetMangerStyle";
import tableStyle from "../../../assets/jss/material-dashboard-react/components/tableStyle";
import NoResults from "components/widgetManagerComponents/Notfound";
import { ClockLoader } from "react-spinners";
import { useQueryParams } from "hooks";


const useStyles = makeStyles(styles);
const layouts = [
  { name: "ALL", label: "ALL" },
  { name: "HRO", label: "Hero" },
  { name: "CAR", label: "Carousel" },
  { name: "HOR", label: "Horizontal Rails" },
  { name: "CUS", label: "Custom" },
  { name: "BAN", label: "AdBanner" },
  { name: "MRC", label: "AdRectangle" }
]
const PlatformNames = {
  WEB_DES: "Web Desk",
  WEB_MOB: "Web Mob",
  AND_MOB: "Android Mob",
  AND_TAB: "Android Tab",
  IOS_MOB: "IOS Mob",
  IOS_TAB: "IOS Tab",
  AND_TV: "Android TV",
  SAMSUNG_SMART_TV: "Samsung TV",
  LG_SMART_TV: "LG TV",
  APPLE_TV: "Apple TV",
  VIDAA_TV: "Vidaa TV",
  DONGLE:"Dongle",
  FIRESTICK:"Firestick",
  STB:"Setup Box",
  SKYGLASS_TV:"Sky glass TV"
};
const platforms = Object.entries(PlatformNames).map(([key, value]) => ({
  name: key,
  label: value
}));

platforms.unshift({
  name:"ALL",
  label:"ALL"
})

const statusTypes = [{
  name:"Published",
  label:"Published"
},
{
  name:"Draft",
  label:"Draft"
}
];

statusTypes.unshift({
  name:"ALL",
  label:"ALL"
})

//Pagination Box Array
const WidgetManager = (props) => {

  const [accessSnack, setAccessSnack] = React.useState(false);
  const [accessSnackSeverity, setAccessSnackSeverity] = React.useState("warning");
  const [accessSnackMessage, setAccessSnackMessage] = React.useState("");

  const [widgetPublishedOpen, setWidgetPublishedOpen] = React.useState(false);
  const [openSnackbarCreate, setOpenSnackbarCreate] = React.useState(false);
  const [openSnackbarEdit, setOpenSnackbaEdit] = React.useState(false);
  const [openSnackbarDelete, setOpenSnackbarDelete] = React.useState(false);


  const [widgetTypesOpen, setWidgetTypeOpen] = React.useState(false);
  const [statusTypesOpen, setStatusTypeOpen] = React.useState(false);
  const [platformTypeOpen, setplatformTypeOpen] = React.useState(false);

  const [selectedLayoutType,setSelectedLayoutType]=React.useState('All')
  const [selectedStatusType,setSelectedStatusType]=React.useState('All')
  const [selectedPlatformType,setSelectedPlatformType]=React.useState('All')
  const [searchInput,setSearchInput] = React.useState("");
  const [currpageNumber, setCurrpageNumber] = React.useState(1);

  const [getQueryparams, setQueryparams, removeQueryparams] = useQueryParams()


  const searchInputTimeoutId = useRef(null)
  const history = useHistory();

  const classes = useStyles();
  
  const _postsPerPage = 10
  
  const totalPages =  Math.ceil(props.categoryList?.widgetCount/_postsPerPage)
  let selectedPlatforms = platforms.filter((plt)=>{
    if(props?.projectDetails?.platformList?.includes(plt.name) || plt.name==="ALL"){
      return true
    }
  })
  const getWidgets = (layout="All", searchInput="", pageNumber=1 , origin="render" , status="All", platform ="All") => {

    let payload
    if(searchInput !== "" || (layout.toLowerCase()!=="all") || (status.toLowerCase()!=="all") || (platform.toLowerCase()!=="all") ){
        if (origin==="render" || origin==="widgetTypeChange" || origin==="searchInputChange"){
            payload = {
              pageNumber: 1,
              projectId: props?.projectDetails?.p_id,
              searchWidget:searchInput || "" ,
              layout:layout.toLowerCase()==='all' ? "": layout.toLocaleUpperCase(),
              status:status.toLowerCase()==='all' ? "": status.toUpperCase(),
              platform:platform.toLowerCase()==='all' ? "": platform.toUpperCase(),
              sara:props?.featureConfig?.sara || false
            }
            setCurrpageNumber(1)
        }
        else if (origin==="Pagination"){
             payload = {
                pageNumber: pageNumber,
                projectId: props?.projectDetails?.p_id,
                "searchWidget":searchInput||"",
                layout:layout.toLowerCase()==='all' ? "": layout.toLocaleUpperCase(),
                status:status.toLowerCase()==='all' ? "": status.toUpperCase(),
                platform:platform.toLowerCase()==='all' ? "": platform.toUpperCase(),
                sara:props?.featureConfig?.sara || false
              }
        }
        props.getSearchWidget(payload);
    }else{
       if (origin==="Pagination"){
         payload ={
          pageNumber: pageNumber,
          projectId: props?.projectDetails?.p_id,
          sara:props?.featureConfig?.sara || false
        }
   }else{

    payload ={
      pageNumber: 1,
      projectId: props?.projectDetails?.p_id,
      sara:props?.featureConfig?.sara || false
    }
    setCurrpageNumber(1)
   }

      props.getWidgetAction(payload)
    }

  }

  const handleCurrPageNumber = (type,value=1)=>{
    console.log(type,currpageNumber,value)
      if(type ==="inc" && currpageNumber<totalPages){
        setCurrpageNumber(page=>page+1)
        getWidgets(selectedLayoutType,"", currpageNumber+1,"Pagination",selectedStatusType|| "All",selectedPlatformType||"All")
      }
      else if(type ==="dec" && currpageNumber>1){
        setCurrpageNumber(page=>page-1)
        getWidgets(selectedLayoutType,"", currpageNumber-1,"Pagination",selectedStatusType|| "All",selectedPlatformType||"All")
      }
      else if (type === "cus" && value<=totalPages){
        setCurrpageNumber(page=>value)
        getWidgets(selectedLayoutType, searchInput, value, "Pagination",selectedStatusType|| "All",selectedPlatformType||"All")
      }
  }

  React.useEffect(() => {
    const layoutType = getQueryparams("type") || "ALL"
    const statusType = getQueryparams("status") || "ALL"
    const platformType = getQueryparams("plt") || "ALL"
    setSelectedLayoutType(layoutType)
    setSelectedStatusType(statusType)
    setSelectedPlatformType(platformType)
    let pageNumberLocal;
    if(localStorage.getItem("VIVRE_WidgetManagerPageNumber")){
       pageNumberLocal=localStorage.getItem("VIVRE_WidgetManagerPageNumber")
      pageNumberLocal?setCurrpageNumber(Number(pageNumberLocal)):setCurrpageNumber(1)
      localStorage.removeItem("VIVRE_WidgetManagerPageNumber")
     }
     getWidgets(layoutType, searchInput, pageNumberLocal||currpageNumber, "render",statusType,platformType)
  }, [props.pageDetails,props.widgetDeleteRes,props?.featureConfig?.sara]);


  //Messages for Widget Creation Snackbar
  const createMessages = {
    success: `${props.createWidget?.data?.name} widget has been created.`,
    warning: ``,
    error: `There seems to be an error creating ${props.createWidget?.data?.name} widget. Please try again.`,
  };
  const editMessages = {
    success: `${props.editWidget?.data?.name} widget has been edited.`,
    warning: ``,
    error: `There seems to be an error editing ${props.createWidget?.data?.name} widget. Please try again.`,
  };

  const deleteMessage={
    success: props?.widgetDeleteRes?.message,
    warning: ``,
    error: props?.widgetDeleteRes?.message,
  }

  const handleClickAway = (clickVar) => {
    if (clickVar === "Type") {
      setWidgetTypeOpen(false);
    }
    else if (clickVar === "Status") {
      setStatusTypeOpen(false);
    }
    else if (clickVar === "Platform") {
      setplatformTypeOpen(false);
    }
  };

  const openTypesMenu = () => {

    setWidgetTypeOpen((prev) => !prev);
  };

  const openStatusMenu = () => {
    setStatusTypeOpen((prev) => !prev);
    
  };

  const openPlatformMenu = () => {
    setplatformTypeOpen((prev) => !prev);
  };

  const layoutSelected=(menu)=>{
    if(menu["name"].toLowerCase()!=="all" ){
      setQueryparams("type",menu["name"])
    }else{
      removeQueryparams("type")
    }
    setSelectedLayoutType(menu["name"])
    getWidgets(menu["name"].toUpperCase(),searchInput,currpageNumber,"widgetTypeChange",selectedStatusType|| "All",selectedPlatformType||"All")
    
  }

  const platformSelected=(menu) => {
    if(menu["name"].toLowerCase()!=="all" ){
      setQueryparams("plt",menu["name"])
    }else{
      removeQueryparams("plt")
    }
    setSelectedPlatformType(menu["name"])
    getWidgets(selectedLayoutType,searchInput,currpageNumber,"widgetTypeChange",selectedStatusType|| "All",menu["name"].toUpperCase())
    
  }
  const statusSelected  = (menu) => {
    setSelectedStatusType(menu["name"])
    if(menu["name"].toLowerCase()!=="all" ){
      setQueryparams("status",menu["name"])
    }else{
      removeQueryparams("status")
    }
    getWidgets(selectedLayoutType,searchInput,currpageNumber,"widgetTypeChange",menu["name"].toUpperCase(),selectedPlatformType||"All")
  }

  function debounceRequest(func,delay) {

        if(searchInputTimeoutId.current) {
          clearTimeout(searchInputTimeoutId.current)
        }
        searchInputTimeoutId.current=setTimeout(() => {
            func()
        }, delay)
    
}
  const handleSearchInput = (e)=>{
      setSearchInput(e.target.value);
      debounceRequest(()=>{getWidgets(selectedLayoutType,e.target.value,1,"searchInputChange",selectedStatusType|| "All",selectedPlatformType||"All")},300)
  }

  React.useEffect(() => {
    props.createWidget?.hzStatus === 200 && props.widgetReset ? setOpenSnackbarCreate(true) : props.createWidget?.hzStatus === 400&& props.widgetReset?setOpenSnackbarCreate(true) :null;
  }, [props.createWidget]);

  React.useEffect(() => {
    props.editWidget?.hzStatus === 200 && props.widgetEdit ?setOpenSnackbaEdit(true) :props.editWidget?.hzStatus === 400 && props.widgetEdit?setOpenSnackbaEdit(true): null;
  }, [props.editWidget]);

  React.useEffect(() => {
    props.widgetDeleteRes?.hzStatus === 200 && props.widgetDelete ? setOpenSnackbarDelete(true) : null;
  }, [props.widgetDeleteRes]);
  return (
    <div>
   {/*  <Snackbar
          open={openSnackbarCreate}
          severity={props.createWidget?.hzStatus === 200 ? "success" : "error"}
          message={
            props.createWidget?.hzStatus === 200 ? createMessages.success : createMessages.error
          }
          setOpen={() => setOpenSnackbarCreate(false)}
        />
         <Snackbar
          open={openSnackbarEdit}
          severity={props.editWidget?.hzStatus === 200 ? "success" : "error"}
          message={
            props.editWidget?.hzStatus === 200 ? editMessages.success : editMessages.error
          }
          setOpen={() => setOpenSnackbaEdit(false)}
        /> */}
         <Snackbar
          open={openSnackbarDelete}
          severity={props.widgetDeleteRes?.hzStatus === 200 ? "success" : "error"}
          message={
            props.widgetDeleteRes?.hzStatus === 200 ? deleteMessage.success : deleteMessage.error
          }
          setOpen={() => setOpenSnackbarDelete(false)}
        />
        
        <Snackbar
          open={accessSnack}
          severity={accessSnackSeverity}
          message={accessSnackMessage}
          setOpen={() => setAccessSnack(false)}
        />
        <>
        <Primary>Widget Manager</Primary>
        <GridContainer>
          <GridItem>
            <Box  className={classes.searchBoxWidgetManager}>
              <img className={classes.searchicon} src={SearchIcon} alt="Search Icon" />
              <InputBase
                placeholder="Search widget name"
                className={classes.widgetManagerViewTextField}
                labelwidth={0}
                onChange={handleSearchInput}
                value={searchInput}
              />
            </Box>
            <ClickAwayListener
              onClickAway={() => {
                handleClickAway("Platform");
              }}
            >
              <div onClick={openPlatformMenu} style={{ cursor: "pointer" }}>
                <Box className={classes.widgetManagerViewCategoryContainer}>
                  <p >{selectedPlatformType.toLocaleLowerCase()!=="all"?selectedPlatformType:"Platform"} </p>
                  <img
                    className={classes.dropDownClassWidgetCategory}
                    src={DropDown}
                    alt="dropDown Icon"
                  />
                </Box>{" "}
                {platformTypeOpen ? (
                  <div
                    style={{
                      width: "180px",
                      position: "absolute",
                      backgroundColor: "white",
                      marginTop: "6px",
                      borderRadius: "10px",
                      zIndex:"100"
                    }}
                  >
                    <Menuitem items={selectedPlatforms} getValue={item=>item.name} getLabel={item=>item.label} selectItem={platformSelected} />
                  </div>
                ) : null}{" "}
              </div>
            </ClickAwayListener>
            <ClickAwayListener
              onClickAway={() => {
                handleClickAway("Type");
              }}
            >
              <div onClick={openTypesMenu} style={{ cursor: "pointer" }}>
                <Box className={classes.widgetManagerViewCategoryContainer}>
                <p >{selectedLayoutType.toLocaleLowerCase()!=="all"?selectedLayoutType.toUpperCase():"Type"} </p>  
                  <img
                    className={classes.dropDownClassWidgetCategory}
                    src={DropDown}
                    alt="dropDown Icon"
                  />
                </Box>{" "}
                {widgetTypesOpen ? (
                  <div
                    style={{
                      width: "180px",
                      position: "absolute",
                      backgroundColor: "white",
                      width: "150px",
                      marginTop: "6px",
                      borderRadius: "10px",
                      zIndex:"100"
                    }}
                  >
                    <Menuitem items={layouts} getValue={item=>item.name} getLabel={item=>item.label} selectItem={layoutSelected}  />
                  </div>
                ) : null}{" "}
              </div>
            </ClickAwayListener>
            <ClickAwayListener
              onClickAway={() => {
                handleClickAway("Status");
              }}
            >
              <div onClick={openStatusMenu} style={{ cursor: "pointer" }}>
                <Box className={classes.widgetManagerViewCategoryContainer}>
                <p >{selectedStatusType.toLocaleLowerCase()!=="all"?selectedStatusType.toUpperCase():"Status"} </p>  
               <img 
                    className={classes.dropDownClassWidgetCategory}
                    src={DropDown}
                    alt="dropDown Icon"
                  /> 
                </Box>
                {statusTypesOpen ? (
                  <div
                  style={{
                    width: "180px",
                    position: "absolute",
                    backgroundColor: "white",
                    width: "150px",
                    marginTop: "6px",
                    borderRadius: "10px",
                    zIndex:"100"
                  }}
                  >
                    {" "}
                    <Menuitem items={statusTypes} getValue={item=>item.name} getLabel={item=>item.label} selectItem={statusSelected}  />
             
                  </div>
                ) : null}
              </div>
            </ClickAwayListener>

            <Box
              className={classes.widgetManagerAddButtonBox}
              onClick={() => {
                localStorage.setItem("VIVRE_WidgetManagerPageNumber",currpageNumber)
                props.accessLevel !== "user"
                  ? history.push({
                      pathname: "/admin/widgetmanager/add",
                      state: { prevPage: "WidgetManager" },
                    })
                  : [
                      setAccessSnackMessage("You do not have access to add widgets."),
                      setAccessSnack(true),
                    ];
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
                  borderRadius: "50%",
                  height: "32px",
                  width: "32px",
                }}
              >
                <img style={{ width: "11.6px", height: "11.6px" }} src={AddSign} />
              </span>
              <Typography
                style={{
                  color: "#339FED",
                  marginLeft: "12px",
                  background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "22px",
                  textAlign: "center",
                }}
              >
                {" "}
                Add Widget
              </Typography>
            </Box>
          </GridItem>{" "}
        </GridContainer>
        {
          typeof props.categoryList?.widgetCount ==="number" ? (props.categoryList?.widgetCount>0 )? (
            <GridContainer>
          <Table
            tableHead={[
              "Widget ID",
              "Widget Name",
              "Type",
              "Playist ID",
              "Platform Supported",
              "Status",
              ""
            ]}
            pageNumber={currpageNumber}
           projectDetails={props.projectDetails}
            tableData={props?.categoryList?.widgets}
            selectedLayoutType={selectedLayoutType}
            setSelectedLayoutType={setSelectedLayoutType}
            pagination={
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  // marginTop: "20px",
                }}
              >
                {
                totalPages>1 && <>
                <span
                role="button"
                  style={{
                    background:currpageNumber>1 ? "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)":"#D3D3D3",
                    borderRadius: "4px",
                    width: "32px",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: currpageNumber>1 ?"pointer":"not-allowed",
                    marginRight: "8px",
                  }}
                  onClick={() => handleCurrPageNumber("dec")}
                >
                  <img src={BackIcon} />
                </span>
                <Pagination
                  currpageNumber={currpageNumber}
                  totalPages={totalPages}
                  handlePageChange={handleCurrPageNumber}
                />
                <span
                 role="button"
                  style={{
                    background:currpageNumber<totalPages ? "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)":"#D3D3D3",
                    borderRadius: "4px",
                    width: "32px",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: currpageNumber<totalPages  ?"pointer":"not-allowed",

                  }}
                  onClick={() => handleCurrPageNumber("inc")}
                >
                  <img src={NextIcon} />
                </span>
                </>
            }
              </div>
            }
          />
        </GridContainer>
          ): <NoResults/>
          :<Box      display="flex" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center" 
          height="80vh"
          textAlign="center"><ClockLoader  color="rgb(63, 81, 181)" /></Box>
        }
        </>
    </div>
  );
};




const mapStateToProps = (state) => {
  return {
    categoryList: state.PlayListReducer,
    projectDetails: state.ProjectReducer.projectDetails,
    featureConfig: state.ProjectReducer.featureConfig,
    createWidget: state.PlayListReducer.createdWidget,
    widgetReset: state.PlayListReducer.widgetReset,
    widgetEdit: state.PlayListReducer.widgetEdit,
    editWidget: state.PlayListReducer.editWidget,
    widgetDeleteRes: state.PlayListReducer.widgetDeleteRes,
    widgetDelete: state.PlayListReducer.widgetDelete,
    accessLevel: state.loginReducer.authTokens?.accessLevel,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryTreeAction: () => dispatch(getCategoryTreeAction()),
    getWidgetAction: (pageNumber) => dispatch(getWidgetAction(pageNumber)),
    getSearchWidget:(data)=>dispatch(getSearchWidget(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetManager);
