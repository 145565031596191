import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import RTLNavbarLinks from "./RTLNavbarLinks.js";
import Button from "components/CustomButtons/Button.js";
import NavCollapseLogo from '../../assets/img/headNavLogo/threeLine.png'
import icons from '../../assets/img/headNavLogo/avatar.png'

import { addProjectNameInNavbarAction } from "screens/Projects/actions/projectActions.js";


//hooks
import { useRouteName } from "hooks";

import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import { Avatar, Box, ListItemIcon, MenuItem, Select, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import {useHistory} from "react-router-dom"

const useStyles = makeStyles(styles);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
      width: 230,
      borderRadius:'8px',
    }
  },
  variant:'menu',
  getContentAnchorEl: null,
  anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    }
};

const Header = (props)=> {
  const classes = useStyles();
  const routeName = useRouteName();
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });

  const history = useHistory();

  const handleChange = (event)=>{
    props?.allProjects?.map((x)=>{  
      if(event.target.value === "All"){
        props.addProjectNameInNavbarAction({name:props?.allProjects[0]?.commonName,icon:props?.allProjects[0]?.projectIcon["1x1"],p_id:props?.allProjects[0]?.p_id,platformList:props?.allProjects[0]?.platforms,appConfig:props?.allProjects[0]?.config,isAll:true});
        history.push({
          pathname:'/admin/projects',
         })
      }
      else if(event.target.value === x.commonName){
           props.addProjectNameInNavbarAction({name:x.commonName,icon:x.projectIcon["1x1"],p_id:x.p_id,platformList:x.platforms,appConfig:x.config,splashScreens:x.splash});
           history.push({
            pathname:'/admin/projectsplateformmanager',
            state:x.p_id
           })
       }
    })
  }
  
  //Function to close and open the drawer for the side nav
  const closeAndOpenDrawer=()=>{
   
    props.setDrawerStatuss(!props.drawerStatus)
    
  }
  return (
    <AppBar className={classes.appBar + appBarClasses} >
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name *
          <Button color="transparent" href="#" className={classes.title}>
            {routeName}
  </Button>*/}
           <Box paddingTop='10px' >
             <img style={{cursor:'pointer'}} src={NavCollapseLogo} onClick={closeAndOpenDrawer}/>
           </Box>
            <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly', paddingLeft:'24px'}}>
            <Box>
             
              <Select 
            onChange={handleChange}
            className={classes.projectName}
            value={props?.ProjectDetails?.isAll?"All":props?.ProjectDetails?.name}
            MenuProps={MenuProps}   
            variant='standard'
            fullWidth
            size='small'
            defaultValue=''
            // notchedOutline='true'
            //classes={{input:classes.pr}}
            disableUnderline

            //sx={{'.MuiOutlinedInput-notchedOutline': { border: 0 },boxShadow:'none'}}
        >
            <MenuItem value={"All"}
                          style={{color:"#777777",paddingTop:'10px'}} key={"All"}>
                          <div style={{display:'flex',flexDirection:'row'}}>
                             <img style={{backgroundColor:"#3f51b5"}} className={classes.projectIcon} src={props.logo}/>
                             <div style={{paddingTop:'7px',paddingLeft:'10px'}}>
                             All Projects
                             </div>
                          </div>
                          </MenuItem>
         {props?.allProjects?.map((x, i)=>{
                return (
                    <MenuItem value={x.commonName}
                          style={{color:"#777777",paddingTop:'10px'}} key={i}>
                          <div style={{display:'flex',flexDirection:'row'}}>
                             <img className={classes.projectIcon} src={x.projectIcon['1x1']}/>
                             <div style={{paddingTop:'7px',paddingLeft:'10px'}}>
                             {x.commonName}
                             </div>
                          </div>
                          </MenuItem>
                    )
          })}
        </Select>
            </Box>
            </div>
        </div> 
        <Hidden smDown implementation="css">
          {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks name={props.userName}  />}
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (State)=>{
  return {

  }
}

const mapDispatchToProps = (dispatch)=>{
  return{
    addProjectNameInNavbarAction: (details) => {
      dispatch(addProjectNameInNavbarAction(details));
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Header)