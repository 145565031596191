import { Typography } from '@material-ui/core';
import React from 'react';

const Analytics = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' ,}}>
  <div>
  <Typography  style={{fontSize:"20px" , textAlign:"center"}} align="center">
      🚀 Great things are on the horizon! This feature is in the works and will enhance your experience in ways you won't want to miss.
      
      </Typography>
      <Typography style={{fontSize:"20px" , textAlign:"center"}} align="center">
      Keep an eye out for its launch!
      </Typography>
  </div>
    </div>
  );
};

export default Analytics;
