import React from "react";
import { AntSwitch } from "components/Switch/AntSwitch";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button,
  Paper,
  Switch,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import VivreAutocomplete from '../Autocomplete/Autocomplete'

import HeroLandingPageWidget from "./HeroWidgetLandingPage";

import styles from "assets/jss/material-dashboard-react/views/widgetMangerStyle";
import PlayList from "screens/WidgetManager/views/PlayList";

const useStyles = makeStyles(styles);

const mannualList = [
  { name: "PlayList", code: "PLT" },
  { name: "Asset", code: "AST" },
  { name: "HTML", code: "HTM" },
];

const assetMannualList = [
  { name: "PlayList", code: "PLT" },
  { name: "HTML", code: "HTM" },
]

const HeroType = (props) => {
 // const [selectedCategory, setSelectedCategory] = React.useState(0);
  const [manualListName,setMannualName]=React.useState()

  const classes = useStyles();

React.useEffect(()=>{
/*if(props.heroWidgetData?.landingPage==="PLT"){}
else if(props.heroWidgetData?.landingPage){

}
else if(props.heroWidgetData?.landingPage){

} */

mannualList.map((value)=>{
  
  if(value.code===props.heroWidgetData?.landingPage){
   
    setMannualName(value.name)
  }
})

  
},[])

  //onClick Function

  // Hero Banner Asset and Mannual click button functions 

const selectMannual = () => {
  console.log("Chahch",props.path);
  if(props?.path!=="/admin/widgetmanager/edit"){
    console.log("sdfhjsdfhj");
    props.setSelectedCategory(1);
    props.setHeroWidgetDataType({
      ...props.heroWidgetData,
      imageSource: "MNL",
      isProgram:false,
      landingPage: "",
    });
    props.setParameters({ ...props.parameters, type: "MNL" });
    props.setAssetOrMannuel(true);
    // props.setHeroWidgetDataType({
    //   ...props.heroWidgetData,
    //   landingPage: "",
    // });
  }
  };

  const selctedAsset = () => {
    if(props?.path!=="/admin/widgetmanager/edit"){
    props.setHeroWidgetDataType({
      ...props.heroWidgetData,
      imageSource: "AST",
      landingPage: "",
    });
    props.setSelectedCategory(0);
    props.setParameters({ ...props.parameters, type: "AST" });
    props.setAssetOrMannuel(false);
   }
  };

  const getLandingImageLink = (event) => {

    props.setHeroManualWidgetDetailsHTM({
      ...props.heroManualWidgetDetailsHTM,
      imageURL:event.target.value
    });
    props.setHeroWidgetDataType({
      ...props.heroWidgetData,
      imageURL: event.target.value
    });
  };
 
  const getPlayListId = () => {
    props.setHeroWidgetDataType({
      ...props.heroWidgetData,
      landingPage: "PLT",
    });
    //props.setAssetIdBrowse(!props.getAssetIdBrowse)
    props.setOpenModal(!props.openModal);
  };
  const getAssetIdInPlayList = () => {
    if(props.heroWidgetData.landingPage != ""){
    props.setAssetIdBrowse(true);
    props.setOpenModal(!props.openModal);
    }
    else{
      props.setOpenMandatoryWarn(true);
    }
  };

  //Onchange Function
  const getLandingPageValue = (event, value) => {
    props.setHeroWidgetDataType({
      ...props.heroWidgetData,
      landingPage: value?.code,
    });
  };

  const enablePlayProgram = (event) => {
    props.setHeroWidgetDataType({ ...props.heroWidgetData, isProgram: true });
  };

  var selectedMannual;
  var selectedAssets;
  if (props.selectedCategory === 1) {
    selectedMannual = classNames({
      ["  " + classes.selectedType]: true,
    });
  } else if (props.selectedCategory === 0) {
    selectedAssets = classNames({
      ["  " + classes.selectedType]: true,
    });
  }

  return (
    <div>

  <Typography>Select Source</Typography>
     <span className={classes.widgetDetailContainer} style={{ width: "20%" }}>
        {" "}
        <span
          className={classNames(classes.categoryForHro, selectedAssets)}
          onClick={selctedAsset}
        >
          Asset
        </span>
        <span
          className={classNames(classes.categoryForHro, selectedMannual)}
          onClick={selectMannual}
        >
          Manual
        </span>
      </span> 
      {/* <div>
        {" "}
        <Typography style={{ marginTop: "20px" }}>Select Source</Typography>
        <Box className={classes.validateBox}>
          <Box className={classes.playListBrowseContainer}>
            <Box style={{ 
              display: "flex",
              alignItems: "center",
              overflow: "auto",
              height: "inherit",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none", }}
              >
              {props.heroWidgetData?.imageURL
                ? props.heroWidgetData.imageURL
                : "Image URL *"}
            </Box>
            <Box
              className={classes.playlistBrowseButton}
              onClick={getAssetIdInPlayList}
              style={{ 
                display: "flex", 
                marginLeft: "5px",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "5rem",
              }}
            >
              Browse
            </Box>{" "}
          </Box>
          {props.openMandatoryWarn ? (
            props.heroWidgetData.imageURL ? null : (
              <span className={classes.validationText} style={{ marginBottom: "10px" }}>* Mandatory Field</span>
            )
          ) : null}
        </Box>{" "}
      </div> */}
      {/* <div>
        <Typography style={{}}>Landing Page*</Typography>
        <div className={classes.widgetDetailContainer}>
          <Box className={classes.validateBox}>
            <Autocomplete
            
              options={mannualList}
              getOptionLabel={(option) => option.name}
              // style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                label={manualListName?manualListName:""}
                  size="medium"
                  className={classes.textField}
                  {...params}
                  placeholder="Select Landing Page *"
                  variant="outlined"
                />
              )}
              onChange={getLandingPageValue}
            />
            {props.openMandatoryWarn ? (
              props.heroWidgetData?.landingPage ? null : (
                <span className={classes.validationText}>
                  * Mandatory Field
                </span>
              )
            ) : null}
          </Box>
        </div>
      </div> */}
     

  {/* hero type asset */}
  {props.assetsOrMannuel ? null : (<>
  
    <div>
            {/* <Typography style={{}}>Landing Page*</Typography> */}
            <div className={classes.widgetDetailContainer}>
              <Box sx={{py:2}} className={classes.validateBox}>
              {/* <Autocomplete
                  options={assetMannualList}
                  getOptionLabel={(option) => option.name}
                  // style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      size="medium"
                      className={classes.textField}
                      {...params}
                      placeholder="Select Landing Page *"
                      variant="outlined"
                      label={props?.heroWidgetData?.landingPage}
                    />
                  )}
                    /> */}
                <VivreAutocomplete  
      options={assetMannualList}
      defaultValue={props?.heroWidgetData?.landingPage}
      optionListStyle = {{width:"412px"}}
      getOptionLabel={(option) => option.name}           
      renderInput={(props) => {
              return <TextField
                {...props}
                size="medium"
                className={classes.textField}
                placeholder="Select Landing Page *"
                variant="outlined"
                label={"Landing Page"}
      
              />
      }}  
      onChange={getLandingPageValue}
        />
                {props.openMandatoryWarn ? (
                  props.heroWidgetData?.landingPage ? null : (
                    <span className={classes.validationText}>
                      * Mandatory Field
                    </span>
                  )
                ) : null}
              </Box>
            </div>
          </div>
        <div>
          {" "}
          <Typography style={{ marginTop: "20px" }}>Asset ID</Typography>
          <Box className={classes.validateBox}>
            <Box className={classes.playListBrowseContainer}>
              <Box> {props.assetId ? props.assetId : "Asset ID *"}</Box>
              <Box
                className={classes.playlistBrowseButton}
                onClick={getAssetIdInPlayList}
              >
                Browse
              </Box>{" "}
            </Box>
            {props.openMandatoryWarn ? (
              props.assetId ? null : (
                <span className={classes.validationText}>
                  * Mandatory Field
                </span>
              )
            ) : null}
          </Box>{" "}
        </div>
          <span
          className={classes.widgetDetailContainer}
          style={{
            width: "30%",
          }}
        >
         
          <Typography style={{}}>PlayProgram</Typography>{" "}
          <AntSwitch key={"PlayProgram"+props.heroWidgetData?.isProgram?props.heroWidgetData?.isProgram:false} defaultChecked={props.heroWidgetData?.isProgram?props.heroWidgetData?.isProgram:false} name="checkedC"  onChange={enablePlayProgram} />
        </span>
        <HeroLandingPageWidget
        heroWidgetData={props.heroWidgetData}
        setHeroWidgetDataType={props.setHeroWidgetDataType}
        getPlayListId={getPlayListId}
        playListId={props.playListId}
        playlistName={props.playlistName}
        assetId={props.assetId}
        getAssetIdInPlayList={getAssetIdInPlayList}
        enablePlayProgram={enablePlayProgram}
        heroManualWidgetDetailsHTM={props.heroManualWidgetDetailsHTM}
        setHeroManualWidgetDetailsHTM={props.setHeroManualWidgetDetailsHTM}
        openMandatoryWarn={props.openMandatoryWarn}
      /></>
      )} 

    {console.log("jhsfjfsh",props.heroManualWidgetDetailsHTM)}
    
    {props.assetsOrMannuel ? (
        <>
         <div>
          <Typography style={{}}>Select Image Source*</Typography>
             <div className={classes.widgetDetailContainer}>
               {" "}
             <Box className={classes.validateBox}>
               <TextField
              // disabled={!props.caresoulWidgetdataType?.autoRotate}
               placeholder="select Image source *"
               size="medium"
               variant="outlined"
               className={classes.textField}
               onChange={getLandingImageLink}
                value={props?.heroWidgetData?.imageURL}
               />
              {props.openMandatoryWarn ? (
                props?.heroWidgetData?.imageURL? null : (
                 <span className={classes.validationText}>
                   * Mandatory Field
                  </span>
                )
             ) : null}
           </Box>
          </div>
         </div>
          <div>
            {/* <Typography style={{}}>Landing Page*</Typography> */}
            <div className={classes.widgetDetailContainer}>
              <Box className={classes.validateBox}>
              {/* <Autocomplete
                  options={mannualList}
                  getOptionLabel={(option) => option.name}
                  // style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      size="medium"
                      className={classes.textField}
                      {...params}
                      placeholder="Select Landing Page *"
                      variant="outlined"
                    label={props?.heroWidgetData?.landingPage}
                    />
                  )}
                  onChange={getLandingPageValue}
                /> */}
                 <VivreAutocomplete
      options={mannualList}
      optionListStyle = {{width:"412px"}}
      getOptionLabel={(option) => option.name}           
      renderInput={(props) => {
              return <TextField
   
                {...props}
                size="medium"
                className={classes.textField}
                placeholder="Select Landing Page *"
                variant="outlined"
                label={"Landing Page"}
      
              />
      }}  
      onChange={getLandingPageValue}
        />
                {props.openMandatoryWarn ? (
                  props.heroWidgetData?.landingPage ? null : (
                    <span className={classes.validationText}>
                      * Mandatory Field
                    </span>
                  )
                ) : null}
              </Box>
            </div>
          </div>
         
          <HeroLandingPageWidget
            heroWidgetData={props.heroWidgetData}
            setHeroWidgetDataType={props.setHeroWidgetDataType}
            getPlayListId={getPlayListId}
            playListId={props.playListId}
            playlistName={props.playlistName}
            assetId={props.assetId}
            getAssetIdInPlayList={getAssetIdInPlayList}
            enablePlayProgram={enablePlayProgram}
            heroManualWidgetDetailsHTM={props.heroManualWidgetDetailsHTM}
            setHeroManualWidgetDetailsHTM={props.setHeroManualWidgetDetailsHTM}
            openMandatoryWarn={props.openMandatoryWarn}
          />
        </>
      ) : null} 
    </div>
  );
};

export default HeroType;
