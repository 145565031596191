import { GET_CATEGORY_TREE_DATA, GET_CATEGORY_TREE_VISUALIZATION, SUCCESSFULLY_GET_ASSET_DETAILS, SUCCESSFULLY_GET_PLAYLIST_BY_ID,GET_CATEGORY_TREE_VISUALIZATION_ERROR_MESSAGE } from "../actionTypes";
import { InitialState } from "../initalState";

export function ContentExplorerReducer (state = InitialState,action){
    switch(action.type){
        case GET_CATEGORY_TREE_VISUALIZATION :{
            return{...state,loading:true,visualizationErrorMessage:''}
        }
        case SUCCESSFULLY_GET_ASSET_DETAILS :{
            return {...state,assetDetails:action.payload,}
        }
        case GET_CATEGORY_TREE_DATA:{
            return{...state,allCategories:action.payload,loading:false,visualizationErrorMessage:''}
        }
        case SUCCESSFULLY_GET_PLAYLIST_BY_ID:{
            return{...state,allAssetsDetails:action.payload}
        }
        case GET_CATEGORY_TREE_VISUALIZATION_ERROR_MESSAGE:
            return{...state,visualizationErrorMessage:action.payload}
        default:{
            return state
        }
    }
}