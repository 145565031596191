import { Box, Button, Card, CardContent, CardHeader, Collapse, Grid, IconButton, Switch, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";

import Styles from '../../../assets/jss/material-dashboard-react/views/contentExplorerStyle';

import Empty_Img from '../../../assets/img/icons/svg/Img_Empty.svg';
import Vector from '../../../assets/img/icons/svg/Vector.svg';
import { ExpandMore } from "@material-ui/icons";
import { getAssetByIdAction } from "../actions";
import { connect } from "react-redux";

const useStyle = makeStyles(Styles)

const AssestExplorer = (props)=>{
    const classes = useStyle();

    const [openDetails,setOpenDetails] = useState(false)
    const [expanded, setexpanded] = useState(false)
    const [OpenTag,setOpenTag] = useState(false)
    const [assetId,setAssetId] = useState();
    const [OpenImageCard,setOpenImageCard] = useState(false)
    const [OpenMediaCard,setOpenMediaCard] = useState(false)


    const handleGetAssetDetails = ()=>{
        setOpenDetails(true);
        props.getAssetByIdAction(assetId,props.projectDetails.p_id)
    }

    const handleDateConvertor = (value)=>{
        var s = new Date(value*1000).toLocaleDateString('en-IN',{ year: 'numeric', month: '2-digit', day: '2-digit' })
        return s;
    }

    const handleExpand = ()=>{
        if(expanded == false){
        setexpanded(true)
        setOpenTag(false)
        setOpenImageCard(false)
        setOpenMediaCard(false)
        }
        else{
            setexpanded(false)
        }
    }

    const handleOpenTag = ()=>{
        if(OpenTag == false){
            setexpanded(false)
            setOpenTag(true)
            setOpenImageCard(false)
            setOpenMediaCard(false)
            }
            else{
                setOpenTag(false)
            }
    }

    const handleImageCard = ()=>{
         if(OpenImageCard == false){
             setOpenImageCard(true);
             setexpanded(false);
             setOpenTag(false)
             setOpenMediaCard(false)
         }
         else{
             setOpenImageCard(false)
         }
    }
    
    const handleMediacard =()=>{
        if(OpenMediaCard == false){
            setOpenMediaCard(true);
            setOpenImageCard(false)
            setOpenTag(false)
            setexpanded(false)
        }
        else{
            setOpenMediaCard(false)
        }
    }


    const handleAssetId = (event)=>{
        setAssetId(event.target.value)
    }

    return(
        <Box>
         <Grid>
             <Grid item sm={12} md={12} lg={12}>
            <Box paddingTop='24px'>
                <Typography style={{
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '140%',
                    color: '#424242',
                }}>
                    Asset ID
                </Typography>
            </Box>
            <Box display='flex' flexDirection='row'  paddingTop='8px' gridGap='20px'>
                <TextField className={classes.textfield}
                                fullWidth
                                variant="outlined"
                                size="small"
                                placeholder="Enter Asset ID"
                                style={{width:'700px',height:'40px'}}
                                onChange={handleAssetId}
                                ></TextField>          
                <Button className={classes.addButton} onClick={handleGetAssetDetails}>
                    <Typography className={classes.ButtonText} >Get details</Typography>
                </Button>
            </Box>
            {props?.assetDetails?.status === true ? 
                <Box paddingTop='24px'>
                  <Box>
                   <Card className={classes.card}>
                       <CardHeader title='Meta Data' classes={{title:classes.cardTitle}}
                       action={
                           <IconButton onClick={handleExpand}>
                               <ExpandMore/>
                           </IconButton>
                       }></CardHeader>
                       <Collapse in={expanded}>
                            <CardContent>
                               <Box  display='flex' flexWrap='wrap' gridColumnGap='150px'>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Name</Typography>
                                       <Typography className="">{props?.assetDetails?.name}</Typography>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Entery ID</Typography>
                                       <Typography>{props?.assetDetails?.entryId}</Typography>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>AssetId</Typography>
                                       <Typography>{props?.assetDetails?.id}</Typography>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Description</Typography>
                                       <Box width='180px' overflow= "hidden" textOverflow="ellipsis" whiteSpace='noWrap'>
                                           <Typography>{props?.assetDetails?.description}</Typography>
                                       </Box>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Type</Typography>
                                       <Typography>{props?.assetDetails?.type}</Typography>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Type Description</Typography>
                                       <Typography>{props.assetDetails?.typeDescription}</Typography>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Duration</Typography>
                                       <Typography>{props.assetDetails?.metas?.duration?.value}</Typography>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Downloadable</Typography>
                                       <Typography>{props.assetDetails?.metas?.Downloadable?.value  ? "true" : "false"}</Typography>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Adult</Typography>
                                       <Typography >{props.assetDetails?.metas?.Adult?.value ? "true" : "false"}</Typography>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Star Rating</Typography>
                                       <Typography>{props?.assetDetails?.metas?.StarRating?.value}</Typography>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Offline viewing</Typography>
                                       <Typography>{props.assetDetails?.metas?.offlineviewing  ? "true" : "false"}</Typography>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Created Date</Typography>
                                       <Typography>{handleDateConvertor(props?.assetDetails?.createDate)}</Typography>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>End Date</Typography>
                                       <Typography>{ handleDateConvertor(props?.assetDetails?.endDate)}</Typography>
                                   </Box>
                                   {props?.assetDetails?.typeDescription === "Linear" ?
                                    ( <>
                                        <Box paddingTop='12px'>
                                          <Typography className={classes.title}>Channel No</Typography>
                                          <Typography>{ props?.assetDetails?.metas?.ChannelNumber?.value}</Typography>
                                       </Box>
                                       <Box paddingTop='12px'>
                                          <Typography className={classes.title}>Catalog StartDate Time</Typography>
                                          <Typography>{ handleDateConvertor(props?.assetDetails?.metas?.CatalogStartDateTime?.value)}</Typography>
                                       </Box>
                                       <Box paddingTop='12px'>
                                          <Typography className={classes.title}>PlaybackEndDateTime</Typography>
                                          <Typography>{ handleDateConvertor(props?.assetDetails?.metas?.PlaybackEndDateTime?.value)}</Typography>
                                       </Box>
                                       <Box paddingTop='12px'>
                                          <Typography className={classes.title}>channelType</Typography>
                                          <Typography>{ props?.assetDetails?.channelType}</Typography>
                                       </Box>
                                        <Box paddingTop='12px'>
                                          <Typography className={classes.title}>External Ids</Typography>
                                          <Typography>{ props?.assetDetails?.externalIds}</Typography>
                                       </Box>
                                       <Box paddingTop='12px'>
                                          <Typography className={classes.title}>External CdvrId</Typography>
                                          <Typography>{ props?.assetDetails?.externalCdvrId}</Typography>
                                       </Box>
                                       <Box paddingTop='12px'>
                                          <Typography className={classes.title}>External EpgIngestId</Typography>
                                          <Typography>{ props?.assetDetails?.externalEpgIngestId}</Typography>
                                       </Box>
                                       <Box paddingTop='12px'>
                                          <Typography className={classes.title}>catchUpBuffer</Typography>
                                          <Typography>{props?.assetDetails?.catchUpBuffer}</Typography>
                                       </Box>
                                       <Box paddingTop='12px'>
                                          <Typography className={classes.title}>Enable CdvrState</Typography>
                                          <Typography>{props?.assetDetails?.enableCdvrState}</Typography>
                                       </Box>
                                       <Box paddingTop='12px'>
                                          <Typography className={classes.title}>Enable CatchUp</Typography>
                                          <Typography>{props?.assetDetails?.enableCatchUpState}</Typography>
                                       </Box>
                                       <Box paddingTop='12px'>
                                          <Typography className={classes.title}>enableStartOver</Typography>
                                          <Typography>{props?.assetDetails?.enableStartOverState}</Typography>
                                       </Box>
                                       <Box paddingTop='12px'>
                                          <Typography className={classes.title}>enableTrickPlay</Typography>
                                          <Typography>{props?.assetDetails?. enableTrickPlayState}</Typography>
                                       </Box>
                                       <Box paddingTop='12px'>
                                          <Typography className={classes.title}>external Id</Typography>
                                          <Box width='180px' overflow= "hidden" textOverflow="ellipsis" whiteSpace='noWrap'>
                                              <Typography>{props?.assetDetails?.externalId}</Typography>
                                          </Box>
                                       </Box>
                                       <Box paddingTop='12px'>
                                          <Typography className={classes.title}>CatalogStartDateTime</Typography>
                                          <Typography>{ handleDateConvertor(props?.assetDetails?.metas?.CatalogStartDateTime?.value)}</Typography>
                                       </Box>
                                       <Box paddingTop='12px'>
                                          <Typography className={classes.title}>PlaybackEndDateTime</Typography>
                                          <Typography>{handleDateConvertor(props?.assetDetails?.metas?.PlaybackEndDateTime?.value)}</Typography>
                                       </Box>
                                       </>
                                    )
                                    :
                                    ""
                                   }
                               </Box>
                            </CardContent>
                       </Collapse>
                   </Card>
                   </Box>

                   <Box paddingTop='24px'>
                   <Card className={classes.card}>
                       <CardHeader title='Image' classes={{title:classes.cardTitle}}
                       action={
                           <IconButton onClick={handleImageCard}>
                               <ExpandMore/>
                           </IconButton>
                       }></CardHeader>
                       <Collapse in={OpenImageCard}>
                           <CardContent>
                           <Box  display='flex' flexWrap='wrap'>
                           {props?.assetDetails?.images?.map((x,index)=>{
                               return (
                                <Box paddingRight='14px'>
                                <Card className={classes.ImageCard} key={index}>
                                   <Box paddingTop='16px' paddingLeft='16px'>
                                   <Typography className={classes.ImageRatio}>Image Ratio {x.ratio}</Typography>
                                   </Box>
                                   <Box paddingLeft='16px' paddingTop='16px'>
                                       <Box display='flex' flexDirection='row' gridColumnGap='60px'>
                                       <Box >
                                       <Typography className={classes.title}>URL</Typography>
                                       <Box overflow= "hidden" textOverflow="ellipsis" whiteSpace='noWrap' width='200px' >
                                       <Typography>{x.url}</Typography>
                                       </Box>
                                       </Box>
                                       <Typography>Default</Typography>
                                       <Typography>{x.isDefault === true ? "true" : "false"}</Typography>
                                       </Box>
                                       <Box paddingTop='16px'>
                                       <img className={x.ratio === '16x9' ? classes.image :"" ||
                                                       x.ratio === '2x3'  ? classes.image_2:"" ||
                                                       x.ratio === '1x1' ? classes.image_3:""} src={`${x.url}/width/500/height/280`} ></img>
                                       </Box>
                                   </Box>
                               </Card>
                               </Box>
                               )
                           })}
                           </Box>
                           </CardContent>
                       </Collapse>
                   </Card>
                   </Box>

                   <Box paddingTop='24px'>
                   <Card className={classes.card}>
                       <CardHeader title='Media Files' classes={{title:classes.cardTitle}}
                       action={
                           <IconButton onClick={handleMediacard}>
                               <ExpandMore/>
                           </IconButton>
                       }></CardHeader>
                       <Collapse in={OpenMediaCard}>
                           <CardContent>
                              <Box  display='flex' flexWrap='wrap'>
                                 {props?.assetDetails?.mediaFiles?.map((x,i)=>{
                                     return (
                                        <Box paddingRight='14px'>
                                         <Card className={classes.ImageCard} key={i}>
                                             <Box  paddingTop='16px' paddingLeft='16px'>
                                             <Typography className={classes.ImageRatio}>Media #{i}</Typography>
                                             </Box>
                                             <Box display='flex' flexDirection='row' paddingLeft='14px' paddingTop='24px'>
                                                 <Box>
                                                    <Typography className={classes.title}>Type</Typography>
                                                    <Typography>{x.type}</Typography>
                                                 </Box>
                                                 <Box>
                                                    <Typography className={classes.title}>Duration</Typography>
                                                    <Typography>{x.duration}</Typography>
                                                 </Box>
                                             </Box>
                                             <Box paddingTop='16px' paddingLeft='16px'>
                                                  <Typography className={classes.title}>URL</Typography>
                                                  <Box overflow= "hidden" textOverflow="ellipsis" whiteSpace='noWrap' width='325px'>
                                                      <Typography>{x.url}</Typography>
                                                  </Box>
                                             </Box>
                                             <Box paddingTop='16px' paddingLeft='16px'>
                                                  <Typography className={classes.title}>External Id</Typography>
                                                  <Box overflow= "hidden" textOverflow="ellipsis" whiteSpace='noWrap' width='325px'>
                                                      <Typography>{x.externalId}</Typography>
                                                  </Box>
                                             </Box>
                                         </Card>
                                         </Box>
                                     )
                                 })}
                              </Box>
                           </CardContent>
                       </Collapse>
                   </Card>
                   </Box>

                   
                   <Box paddingTop='24px'>
                   <Card className={classes.card}>
                       <CardHeader title='Tags' classes={{title:classes.cardTitle}}
                       action={
                           <IconButton onClick={handleOpenTag}>
                               <ExpandMore/>
                           </IconButton>
                       }></CardHeader>
                       <Collapse in={OpenTag}>
                           <CardContent>
                           <Box  display='flex' flexWrap='wrap' gridColumnGap='150px'>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Actors</Typography>
                                       <Box width='120px'>
                                       <Typography>{props?.assetDetails?.tags?.Actors?.objects?.map((x)=>{
                                           return x.value
                                       })}</Typography>
                                       </Box>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Directors</Typography>
                                       <Typography>{props?.assetDetails?.tags?.Director?.objects?.map((x)=>{
                                           return x.value
                                       })}</Typography>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Video Quality</Typography>
                                       <Typography>{props?.assetDetails?.tags?.Quality?.objects?.map((x)=>{
                                           return x.value
                                       })}</Typography>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Audio Language</Typography>
                                       <Box>
                                           <Typography>{props?.assetDetails?.tags?.AudioLanguage?.objects?.map((x)=>{
                                           return x.value
                                       })}</Typography>
                                       </Box>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Subtitle Language</Typography>
                                       <Typography>{props?.assetDetails?.tags?.SubtitleLanguage?.objects?.map((x)=>{
                                           return x.value
                                       })}</Typography>
                                   </Box>
                                   <Box paddingTop='12px' width='180px'>
                                       <Typography className={classes.title}>Genre</Typography>
                                       <Typography>{props?.assetDetails?.tags?.Genre?.objects?.map((x)=>{
                                           return x.value
                                       })}</Typography>
                                   </Box>
                                   <Box paddingTop='12px'>
                                       <Typography className={classes.title}>Parental Rating</Typography>
                                       <Typography>{props?.assetDetails?.tags?.ParentalRating?.objects?.map((x)=>{
                                           return x.value
                                       })}</Typography>
                                   </Box>
                                </Box>
                           </CardContent>
                       </Collapse>
                   </Card>
                   </Box>
                </Box> 

            :
            <Box>
                <Box display='flex' flexDirection='row' justifyContent='center' paddingTop='100px' >
                    <img src={Empty_Img}/>
                </Box>
            </Box>
            }
            </Grid>
         </Grid>
        </Box>
    )
}

//overflow= "hidden" textOverflow="ellipsis" whiteSpace='noWrap'

const mapStateToProps = (State)=>{
    return{
        assetDetails:State.ContentExplorerReducer.assetDetails,
        projectDetails:State.ProjectReducer.projectDetails,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        getAssetByIdAction:(assetId,projectID)=>{
           dispatch(getAssetByIdAction(assetId,projectID))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AssestExplorer);