import { connect } from "react-redux";
//Material Ui Components
import { Grid, Card, Box, Avatar, Tabs, Tab, makeStyles } from "@material-ui/core";
//Child Component
import Snackbar from "components/Snackbar/CustomSnackbar";
import LoadingBar from "react-top-loading-bar";
import GridOrListViewScreenButton from "components/PlatformScreensView/GridOrListViewScreenView";
import SystemScreens from "./SystemScreens";
import AppScreenView from "components/PlatformScreensView/AppScreenView";
import AppScreenDevice from "components/PlatformScreensView/AppScreenDevice";
//Style Component
import style from "assets/jss/material-dashboard-react/views/screenStyle";
import { useState, useEffect } from "react";
//Static Files
import Live_icon from "assets/img/icons/Group_live_icon.svg";
import Draft_icon from "assets/img/icons/Group_draft_icon.svg";
import { getProjectByIdAction } from "screens/WidgetManager/actions";
import { getPlatformAction } from "screens/PlatformManager/actions/PlatformAction";
import { updateIntros,updateSplashScreens } from "screens/Projects/actions/projectActions";
import { isDev } from "utils/getEnvironment";

const useStyle = makeStyles(style);

const AppScreens = (props) => {
  console.log("AppScreen Props", props);

  const classes = useStyle();

  const screens = ["Splash Screen", "Intro Screens"];
  const SPLASH_URL = `${process.env.REACT_APP_BASE_ASSETS_URL}${isDev() ? "" : "/assets"}/${props.authToken?.t_id}/${
    props?.location?.state?.projectId
  }/splash/${props?.location?.state?.platform}.png`;

  const [splashScreen, setSplashScreen] = useState(props?.splashScreens?.[props?.location?.state?.platform]);
 
  const [introScreens, setIntroScreens] = useState(props.appConfig?.introScreens?.[props.platform]?.imageUrls);

  const [value, setValue] = useState(0);
  const [splashEvent, setSplashEvent] = useState(false);
  const [introEvent, setIntroEvent] = useState(false);

  //Status icon state
  const [splashIcon, setSplashIcon] = useState(<img src={Draft_icon} style={{ width: "10px" }} />);
  const [introIcon, setIntroIcon] = useState(<img src={Draft_icon} style={{ width: "10px" }} />);

  //LoadingBar state
  const [progress, setProgress] = useState(0);

  //Snackbar state
  const [newSplash, setNewSplash] = useState(false);
  const [splashStatus, setSplashStatus] = useState(null);
  const [newIntro, setNewIntro] = useState(false);
  const [introStatus, setIntroStatus] = useState(null);
  const [accessSnack, setAccessSnack] = useState(false);
  const [accessSnackMessage, setAccessSnackMessage] = useState("");
  const [accessSnackSeverity, setAccessSnackSeverity] = useState("warning");

  //child component state
  const [open, setOpen] = useState(false);
  const [laptopPreview, setLaptopPreview] = useState(false);
  const [platform, setPlatform] = useState(props?.location?.state?.platform);
  const [platformName, setPlatformName] = useState(props?.location?.state?.platformName);

  console.log("INTRO", introScreens, platform);

  //Splash snackbar copies
  const createSplash = {
    success: `Splash screen has been published.`,
    error: `There seems to be an error publishing the splash screen. Please try again.`,
  };

  //Intro snackbar copies
  const createIntro = {
    success: `Intro screen(s) has been published.`,
    error: `There seems to be an error publishing the intro screen(s). Please try again.`,
  };

  //Screen Tab onChange event function
  const handlechange = (event, value) => {
    setValue(value);
  };

  //onImageError Event function
  const onImgError = (event) => {
    const _defaultImage = "https://cdn-icons-png.flaticon.com/512/4154/4154393.png"
    setSplashScreen(_defaultImage)
  }

  //onClick Event for Calling an action to get a screen's details
  const openScreen = (screenDetails) => {
    console.log("SCREAM", screenDetails);
  };

  //AppScreenView fresh splash load function
  const onLoad = () => {
    setProgress(100);
  };

  useEffect(() => {
    props.getProjectByIdAction(props?.location?.state?.projectId);
    props.getPlatformAction(props?.location?.state?.projectId);
  }, []);

  useEffect(() => {
    setProgress(progress + 20);
    setSplashScreen(`${props?.splashScreens?.[platform]}`);

    let timer = setTimeout(() => {
      setProgress(100);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [platform]);

  useEffect(() => {
      setSplashStatus(props.freshSplash?.data?.hzStatus);
    props.freshSplash?.data?.hzStatus
    ? props.updateSplashScreens({
        platform: props.freshSplash?.config?.params?.platform,
        splash: props.freshSplash?.data?.data,
      })
    : null;

  }, [props.freshSplash]);

  useEffect(() => {
    setIntroStatus(props.freshIntro?.data?.hzStatus);
    props.freshIntro?.data?.hzStatus
      ? props.updateIntros({
          platform: props.freshIntro?.config?.params?.platform,
          intros: props.freshIntro?.data?.data,
        })
      : null;
  }, [props.freshIntro]);

  useEffect(() => {
    if (props.freshIntro?.status === 200 && props.freshIntro?.config?.params?.platform === platform) {
      setIntroScreens(props.freshIntro?.data?.data);
    } else {
      setIntroScreens(props.appConfig?.introScreens?.[platform]?.imageUrls);
    }
  }, [props.freshIntro, platform]);

  useEffect(() => {
    if (props.freshSplash?.status === 200 && props.freshSplash?.data?.data?.includes(platform) ) {
      setSplashScreen(props.freshSplash?.data?.data);
    } else {
      setSplashScreen(props?.splashScreens?.[platform]);
    }
  }, [props.freshSplash, platform]);
  //Open Splash Snack
  useEffect(() => {
   
    splashEvent && splashStatus
      ? [setNewSplash(true), setSplashIcon(<img src={Live_icon} style={{ width: "10px" }} />)]
      :[ setNewSplash(false),setSplashIcon(<img src={Live_icon} style={{ width: "10px" }} />)];
  }, [props.freshSplash, splashStatus]);

  //Open Intro Snack
  useEffect(() => {
    introEvent && introStatus 
    ? [setNewIntro(true), setIntroIcon(<img src={Live_icon} style={{ width: "10px" }} />)]
    :[ setNewIntro(false),setIntroIcon(<img src={Live_icon} style={{ width: "10px" }} />)];
  }, [props.freshIntro, introStatus]);

  return (
    <>
      <div>
        <LoadingBar
          color="#885CF7"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
          shadow={true}
          height={3}
        />
      </div>
      <Box style={{ display: "flex", width: "fitContent" }}>
        <Grid container>
          <Grid item xs={12} md={12} lg={8}>
            <GridOrListViewScreenButton
              platformName={platformName}
              open={open}
              setOpen={setOpen}
              laptopPreview={laptopPreview}
              setLaptopPreview={setLaptopPreview}
              setPlatform={setPlatform}
              setPlatformName={setPlatformName}
              platforms={props?.platforms}
            />
            <Card className={classes.screenBar}>
              <Box display="flex" flexDirection="row">
                <Box paddingLeft="32px" paddingTop="8px">
                  <Avatar style={{ width: "36px", height: "36px" }} />
                </Box>
                <Box paddingLeft="187px">
                  <Tabs
                    value={value}
                    TabIndicatorProps={{
                      style: {
                        background: "#0192D4",
                        height: "4px",
                      },
                    }}
                    onChange={handlechange}
                    style={{ height: "52px" }}
                  >
                    {screens.map((x, i) => {
                      return (
                        <Tab
                          className={classes.screenTab}
                          classes={{
                            selected: classes.selectedScreenTab,
                            labelIcon: classes.lableIcon,
                          }}
                          style={{ minWidth: 50 }}
                          value={i}
                          label={x}
                          icon={i ? introIcon : splashIcon}
                          iconposition="end"
                          onClick={() => {
                            openScreen(x);
                          }}
                          key={i}
                        />
                      );
                    })}
                  </Tabs>
                </Box>
              </Box>
            </Card>
            <Box className={classes.preview}>
              <AppScreenView
                screenName={value ? "Intro" : "Splash"}
                splashScreen={splashScreen}
                introScreens={introScreens}
                onLoad={onLoad}
                onImgError={onImgError}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <SystemScreens
              screens={screens}
              platform={platform}
              p_id={props?.location?.state?.projectId}
              splashScreen={splashScreen}
              setSplashScreen={setSplashScreen}
              splashUrl={SPLASH_URL}
              setSplashEvent={setSplashEvent}
              introScreens={introScreens}
              setIntroScreens={setIntroScreens}
              setIntroEvent={setIntroEvent}
              activeScreen={value}
              activeTab={(value) => handlechange(event, value)}
              setSplashIcon={setSplashIcon}
              setIntroIcon={setIntroIcon}
              setAccessSnack={setAccessSnack}
              setAccessSnackMessage={setAccessSnackMessage}
              setAccessSnackSeverity={setAccessSnackSeverity}
              onImgError={onImgError}
            />
          </Grid>
        </Grid>
        {open ? (
          <AppScreenDevice
            open={open}
            setOpen={setOpen}
            platform={platform}
            value={value}
            screenName={value ? "Intro" : "Splash"}
            splashScreen={splashScreen}
            introScreens={introScreens}
          />
        ) : laptopPreview ? (
          <AppScreenDevice
            laptopPreview={laptopPreview}
            setLaptopPreview={setLaptopPreview}
            platform={platform}
            value={value}
            screenName={value ? "Intro" : "Splash"}
            splashScreen={splashScreen}
            introScreens={introScreens}
          />
        ) : null}
        <Snackbar
          open={newSplash}
          severity={splashStatus === 200 ? "success" : "error"}
          message={splashStatus === 200 ? createSplash.success : createSplash.error}
          setOpen={() => {
            setNewSplash(false) && setSplashEvent(false);
          }}
        />
        <Snackbar
          open={newIntro}
          severity={introStatus === 200 ? "success" : "error"}
          message={introStatus === 200 ? createIntro.success : createIntro.error}
          setOpen={() => {
            setNewIntro(false) && setIntroEvent(false);
          }}
        />
        <Snackbar
          open={accessSnack}
          severity={accessSnackSeverity}
          message={accessSnackMessage}
          setOpen={() => setAccessSnack(false)}
        />
      </Box>
    </>
  );
};

const mapStateToProps = (State) => {
  return {
    platforms: State.screenVisualizationReducer.platforms,
    appConfig: State.ProjectReducer.projectDetails.appConfig,
    splashScreens:State.ProjectReducer.projectDetails.splashScreens,
    freshSplash: State.screenVisualizationReducer.splashResponse,
    freshIntro: State.screenVisualizationReducer.introResponse,
    authToken: State.loginReducer.authTokens,
  };
};

const mapDispatchToState = (dispatch) => {
  return {
    getPlatformAction: (projectId) => {
      dispatch(getPlatformAction(projectId));
    },
    getProjectByIdAction: (projectId) => dispatch(getProjectByIdAction(projectId)),
    updateIntros: (payload) => dispatch(updateIntros(payload)),
    updateSplashScreens: (payload) => dispatch(updateSplashScreens(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToState)(AppScreens);
