import { NoEncryption } from "@material-ui/icons";
import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb,
  darkText,
  gradiant,
  fontColor,
  displayFlexView,
  iconSizes,
} from "assets/jss/material-dashboard-react.js";

const widgetManagerStyel = (theme) => ({
  modalDiv: {
    backgroundColor: "#ffffff",
    width: "1140px",
    height: "722px",
    borderRadius: "16px",
  },
  modalHeadingDiv: {
    paddingTop: "20px",
    paddingLeft: "20px",
  },

  widgetManagerViewTextField: {
    width: "450px",
    height: "35px",
    background: "white",
    border: "none",
    borderRadius: "30px",
    color: fontColor[2],
    "font-size": "14px",
  },
  searchBoxWidgetManager: {
    display: "flex",
    width: "300px",
    height: "35px",
    background: "white",
    border: "none",
    borderRadius: "30px",
    padding: "0px 5px 0px 7px",
    boxSizing: "border-box",
  },
  searchicon: {
    width: "15px",
    height: "15",
    marginRight: "8px",
    marginTop: "2px",
    marginLeft: "3px",
  },

  widgetManagerViewCategoryContainer: {
    ...displayFlexView,
    ...defaultFont,
    "font-size": "14px",
    justifyContent: "space-around",
   // border: "1px solid black",
    width:"fit-content",
    minWidth: "60px",
    height: "35px",
    borderRadius: "30px",
    background: "white",
    padding:"0px 10px",
    gap:"5px"
  },
  dropDownClassWidgetCategory: {
    cursor: "pointer",

  },

  widgetManagerAddButtonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",

    cursor: "pointer",
  },

  playListDiv: {
    backgroundColor: "#fffff",
    width: "242px",
    height: "635px",
    borderRadius: "0px 16px",
    boxShadow: "2px 0px 8px rgba(0, 0, 0, 0.1)",
    paddingTop: "10px",
  },
  listOfCategory: {
    overflow: "auto",
    maxHeight: "90%",
  },
  PlaylistListItem: {
    cursor: "pointer",

    marginBottom: "10px",
  },
  selectedCategory: {
    backgroundColor: "#0192D4",
    color: "white",
  },
  playlistText: {
    ...defaultFont,
    fontSize: "16px",
    lineHeight: "24px",
  },
  playlists: {
    width: "80%",
    marginLeft: "10px",
  },
  playlistUpperDiv: {},
  playlistView: {
    marginTop: "20px",
  },
  PlaylistListSubItem: {
    cursor: "pointer",
    width: "95%",
    marginBottom: "10px",
  },
  selectedSubCategory: {
    backgroundColor: "#0192D4",
    color: "white",
  },
  selectedLastCategory: {
    backgroundColor: "#0192D4",
    color: "white",
  },
  PlaylistListLastItem: {
    cursor: "pointer",
    width: "95%",
  },
  textField: {
    width: "412px",
    background: "white",
    height: "55px",
    borderRadius: "5px",
  },
  textFieldMultiLine: {
    width: "412px",
    background: "white",
    height: "75px",
    borderRadius: "5px",
  },
  widgetDetailContainer: {
    display: "flex",
    justifyContent: "space-between",
   
    maxWidth: "67%",
    margin: "10px 0px 10px 0px",
  },
  imageSourceDiv: {
    ...displayFlexView,
    width: "412px",
    height: "126px",
    border: "1px dotted black",

    margin: "10px 0px 10px 0px",
  },
  typeContainer: {
    border: "1px solid #rc7c7c7",
    background: "#FFFFFF",
    padding: "8px",
    minWidth: "140px",
    width: "fit-content",
    textAlign: "center",
    borderRadius: "8px",
    cursor: "pointer",
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '140%',
    textAlign: 'center',
    color: '#777777',
  },

  bannerSelectedContainer: {
    border: "1px dotted black",
    background: "#FFFFFF",
    marginRight: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "8px",
  },

  categoryForHro: {
    border: "1px solid black",
    background: "#FFFFFF",
    padding: "8px",
    width: "140px",
    textAlign: "center",
    borderRadius: "10px",
    marginRight: "20px",
    cursor: "pointer",
  },
  displayOptionContainer: {
    width: "137px",
    height: "130px",
    border: "1px solid #D0D0D1",
    borderRadius: "8px",
    boxSizing: "border-box",
    cursor: "pointer",
    background: "#FFFFFF",
  },
  indicatorPositionContainer: {
    width: "137px",
    height: "130px",
    border: "1px solid #D0D0D1",
    borderRadius: "8px",
    boxSizing: "border-box",
    cursor: "pointer",
    background: "#FFFFFF",
  },
  cardTypeContainer: {
    width: "137px",
    height: "130px",
    border: "1px solid black",
    borderRadius: "8px",
    boxSizing: "border-box",
    background: "#FFFFFF",
    cursor: "pointer",
  },
  cardSizeContainer: {
    border: "1px solid black",
    borderRadius: "5px",
    width: "140px",
    textAlign: "center",
    background: "#FFFFFF",
    cursor: "pointer",
  },
  cardLayout: {
    border: "1px solid black",
    borderRadius: "5px",
    width: "140px",
    textAlign: "center",
    background: "#FFFFFF",
    cursor: "pointer",
  },
  selectedType: {
    color: "#0192D4",
    border: "1px solid transparent",
   // borderImage: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) 1 ",
    //background: "#F7FCFF",
    boxShadow: " 0px 8px 20px rgba(63, 39, 102, 0.12)",
    backdropFilter: "blur(20px)",
    background:  'linear-gradient(#F7FCFF, #F7FCFF) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)  border-box',
 // bordeRadius: '50em',
  border:' 1px solid transparent',
    // borderImageSlice:'30 ',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    // textAlign: 'center',
  },
  indicatorPositionselectedType: {
    color: "#0192D4",
    border: "1px solid transparent",
   // borderImage: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) 1 ",
    //background: "#F7FCFF",
    boxShadow: " 0px 8px 20px rgba(63, 39, 102, 0.12)",
    backdropFilter: "blur(20px)",
    background:  'linear-gradient(#F7FCFF, #F7FCFF) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)  border-box',
 // bordeRadius: '50em',
  border:' 1px solid transparent',
    // borderImageSlice:'30 ',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    // textAlign: 'center',
  },

  cancelButton: {
    height: "50px",
    width: "180px",
    color:'#FFFFFF',
    fontSize: '16px',
    lineHeight:'140%',
    color: "#0192D4",
    border: "1px solid transparent",
    background:  'linear-gradient(#fff, #fff) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)  border-box',
     borderRadius: '33px',
     border:' 1px solid transparent'
   // borderImage: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) 1",
  },
  widerButton: {
    height: "50px",
    width: "100%",
    color:'#FFFFFF',
    fontSize: '16px',
    lineHeight:'140%',
    color: "#0192D4",
    border: "1px solid transparent",
    background:  'linear-gradient(#fff, #fff) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)  border-box',
     borderRadius: '33px',
     border:' 1px solid transparent'
   // borderImage: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) 1",
  },
  submitButton: {
    color:'#FFFFFF',
    fontSize: '16px',
    lineHeight:'140%',
    height: "50px",
    width: "202px",
    marginLeft: "20px",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
    borderRadius:'33px'
   // border: "1px solid transparent",
   // borderImage: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) 1",
  },


  playListBrowseContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#FFFFFF",
    width: "412px",
    height: "57px",
    border: "1px solid #C7C7C7",
    borderRadius: "5px",
    // cursor: "pointer",
    margin: "10px 0px 0px 0px",
    color: "#777777",
    boxSizing: "border-box",
    padding: "0 10px 0 10px",
  },
  playlistBrowseButton: {
    ...defaultFont,
    color: "#0192D4",
    cursor: "pointer",
    fontSize:'14px',
    background: 'linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "5rem",
  'WebkitBackgroundClip': 'text',
  'WebkitTextFillColor': 'transparent',
  },
  validateBox: {
    display: "flex",
    flexDirection: "column",
   
  },
  validationText:{
    fontSize:'12px',
    color:'#FF2727'
  },
  paginationBox:{ marginRight: "8px",
  // marginLeft: "10px",
  cursor: "pointer",
  border: "1px solid #777777",
  borderRadius: "4px",
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",},
  selectedPageNum:{
  //backgroundColor:'red'
    background:  'linear-gradient(#F7FCFF, #F7FCFF) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)  border-box',
    color: "#0192D4",
    border:' 1px solid transparent'
  },
  assetImageSelect:{
    '&:hover':{
      background:  'linear-gradient(#F7FCFF, #F7FCFF) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)  border-box',
  
      border:' 2px solid transparent',
      cursor:'pointer'
    }
  },
  card:{ 
    width: 'fit-content',
    //height: '530px',
    background: 'rgba(255, 255, 255, 0.6)',
    boxShadow: '0px 8px 20px rgba(63, 39, 102, 0.12)',
    backdropFilter: 'blur(20px)',
    borderRadius: '24px',
    maxheight:'100%',
    maxWidth:'83%',
    //width:'auto'
},
  cardHeader:{
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '25px',
    color: '#141D46',
},
  platformCard:{
    display: 'flex',
    flexDirection: 'column',
    padding: '1vh 1vw',
    gap: '8px',
    background: '#FFFFFF',
    border: '1px solid rgba(199, 199, 199, 0.3)',
    backdropFilter: 'blur(20px)',
    borderRadius: '12px',
    maxWidth: '100%',
    maxHeight: '100%',
    width:'8vw',
    height:'96px',
    justifyContent:'space-between',
    alignItems:'center',
    cursor: 'pointer',
},
selectPlatformCard:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1vh 1vw',
    gap: '8px',
    color: "#0192D4",
    background:  'linear-gradient(#F7FCFF, #F7FCFF) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)  border-box',
    border:' 1px solid transparent',
    backdropFilter: 'blur(10px)',
    borderRadius: '12px',
    maxWidth: '100%',
    maxHeight: '100%',
    width:'8vw',
    height:'96px',
    justifyContent:'space-between',
    cursor: 'pointer',
},
cardTypography:{
    // width:'90px',
    height: '16px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#424242',
    flex: 'none',
    order: '1',
    flexGrow: '0'
},
selectCardTypo:{
    // width:'90px',
    height: '16px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#0192D4',
    flex: 'none',
    order: '1',
    flexGrow: '0'
},
customTextField: {
  width: "312px",
  background: "white",
  height: "55px",
  borderRadius: "5px",
},
dialog: {
  width: "591px",
  // height: "auto",
  height: "80%",
  background: "#FFFFFF",
  "border-radius": "16px",
  boxShadow: "0px 8px 20px rgba(63, 39, 102, 0.12)",
  backdropFilter: "blur(10px)",
},
content: {
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
},
screenName: {
  width: "200px",
  height: "22px",
  "font-family": "Open Sans",
  "font-style": "normal",
  "font-weight": "600",
  "font-size": "16px",
  "line-height": "140%",
  overflow: "hidden",
  "text-overflow": "ellipsis",
  "white-space": "nowrap",
},
ExportTypo: {
  width: "400px",
  height: "33px",
  left: "477px",
  top: "269px",
  "font-family": "Open Sans",
  "font-style": "normal",
  "font-weight": "700",
  "font-size": "24px",
  "line-height": "33px",
  color: "#141D46",
  pointerEvents: "none",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
},
dialogButton: {
  position: "absolute",
  top: "21px",
  right: "21px",
  cursor: "pointer",
  minWidth: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  borderRadius: "50%",
},
dialogWarper: {
  paddingBottom: "20px",
  display: "flex",
  alignContent: "flex-start",
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "space-evenly",
},
dialogGrid: {
  marginTop: "0px",
  maxHeight: "390px",
  overflow: "auto",
},
selectedCard: {
  "box-sizing": "border-box",
  width: "147px",
  minHeight: "103px",
  background: "#F7FCFF",
  "backdrop-filter": "blur(20px)",
  "border-radius": "12px",
  border: "2px solid  rgba(51,159,237)",
  cursor: "pointer",
  boxShadow: "none",
},
exportPltCard: {
  "box-sizing": "border-box",
  width: "147px",
  minHeight: "103px",
  background: "#FFFFFF",
  "border-radius": "12px",
  // border: "1px solid rgba(199, 199, 199, 0.3)",
  border: "none",
  backdropFilter: "blur(10px)",
  cursor: "pointer",
  boxShadow: "none",
},
selectedCardTypo: {
  height: "fit-content",
  "font-family": "Open Sans",
  "font-style": "normal",
  "font-weight": "600",
  "font-size": "12px",
  "line-height": "16px",
  "text-align": "center",
  color: "#0192D4",
  padding: "0 4px 12px",
},
cardTypo: {
  height: "fit-content",
  "font-family": "Open Sans",
  "font-style": "normal",
  "font-weight": "600",
  "font-size": "12px",
  "line-height": "16px",
  "text-align": "center",
  color: "#000000",
  padding: "0 4px 12px",
},
action: {
  position: "absolute",
  bottom: "1em",
  right: "1em"
},
accordion: {
  margin: "10px"
},
cancelTypo: {
  width: "311px",
  height: "22px",
  left: "calc(50% - 311px/2 - 0.5px)",
  top: "calc(50% - 22px/2)",
  "font-family": "Open Sans",
  "font-style": "normal",
  "font-weight": "700",
  "font-size": "16px",
  "line-height": "140%",
  "text-align": "center",
  background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  "background-clip": "text",
  "text-fill-color": "transparent",
  textTransform: "none",
},
saveButton: {
  display: "flex",
  justifyContent: "center",
  alignItems: "cneter",
  height: "48px",
  width: "100%",
  background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
  boxShadow: "0px 2px 20px rgba(0, 60, 118, 0.4)",
  "border-radius": "33px",
},
ButtonText: {
  height: "22px",
  "font-family": "Open Sans",
  "font-style": "normal",
  "font-weight": "700",
  "font-size": "16px",
  "line-height": "140%",
  "text-align": "center",
  color: "#FFFFFF",
  textTransform: "none",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
},
inputLabel: {
  height: "20px",
  top: "calc(50% - 20px/2 + 26px)",
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "140%",
  color: " #141D46",
  paddingBottom: "8px",
},
logofield:{
  boxSizing: 'border-box',
  background: '#FFFFFF',
  opacity: '0.5',
  border: '1px dashed #777777', 
  bordeRadius: '4px',
  height:'40px',
  width: '250px',
  maxWidth:'100%',
  maxHeight:'100%',
  display:"inital",
},
logoTypo:{
  maxWidth:"243px",
  maxHeight:'24px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '18px',
  lineHeight: '24px',
  textAlign: 'center',
  backgroundImage:'linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)',
  'WebkitTextFillColor': 'transparent',
  'WebkitBackgroundClip': 'text'
},
Image16:{
  position: 'relative',
  maxWidth: '100%',
  maxHeight:'100%',
  width:'256px',
  height:'144px',
  //'padding-top': '1px',
  'border-radius': '12px',
  'background': '#2B2B2B',
},
swatch: {
  padding: "5px",
  background: "#fff",
  borderRadius: "3px",
  boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
  display: "inline-block",
  cursor: "pointer",
},
color: {
  width: "36px",
  height: "14px",
  borderRadius: "2px",
},
popover: {
  position: "absolute",
  zIndex: "2",
},
cover: {
  position: "fixed",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
},
});

export default widgetManagerStyel;
