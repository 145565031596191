import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import PlayList from "./PlayList";
import { connect } from "react-redux";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Paper,
  Typography,
  TextField,
  Input,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";

// import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import { calculateImageRatio } from "../../../utils/imageRatioCalculate";

//Actions
import { getCategoryTreeAction, getSingleWidgetAction, createWidgetAction, getProjectByIdAction,editWidgetAction } from "../actions";

// @material-ui/core components
import Primary from "../../../components/Typography/Primary";
import SubHead from "components/Typography/SubHead";
import Snackbar from "components/Snackbar/CustomSnackbar";

//Components
import HorizontalRailsType from "components/widgetManagerComponents/HorizontalRailDataType";
import CaroslType from "components/widgetManagerComponents/CarosalDataType";
import HeroType from "components/widgetManagerComponents/HeroDataType";
import AdvertisementRailType from "components/widgetManagerComponents/AdverTisementDataType";
import EnableSchedule from "components/widgetManagerComponents/EnableSchedule";
import AspectRatio from "../../../components/widgetManagerComponents/AspectRatioBox";
import AspectRatioShape from "../../../components/widgetManagerComponents/AspectRatioShape";
import CustomWidget from "components/widgetManagerComponents/customWidgetDataType";

//Styles
import Style from "../../../assets/jss/material-dashboard-react/views/widgetMangerStyle";
import PlatformSeletor from "components/PlatformScreensView/PlatformSelector";
import Close from "../../../assets/img/icons/svg/close.svg";
import { isDev } from "utils/getEnvironment";

import { updateScreenStatusAction } from "../../PlatformManager/actions/PlatformAction";
import axios from "axios";
import { SketchPicker } from "react-color";
import rgbaToHex from "utils/rgbaToHex";
import { AntSwitch } from "components/Switch/AntSwitch";

const imageDimensions = [
  { width: 170.6666, height: 96, ratio: "16x9", numOfImage: 2 },
  { width: 144, height: 96, ratio: "3x2", numOfImage: 4 },
  { width: 64, height: 96, ratio: "2x3", numOfImage: 6 },
  { width: 96, height: 96, ratio: "1x1", numOfImage: 2 },
  { width: 54, height: 96, ratio: "9x16", numOfImage: 5 },
];
const selRails = ["Hero", "Carousel", "Horizontal Rails", "Custom", "Advertisement"];

const adPlatformOptions = [
  { name: "Facebook Audience Network (FAN)", code: "FAN" },
  { name: "Google Ad Manager (DFP)", code: "DFP" },
];

const adLayoutOptions = [
  { name: "Banner", code: "BAN" },
  { name: "Medium Rectangle", code: "MRC" },
  { name: "Custom", code:"CUS" }
];


const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
))(() => ({
  '& .MuiAccordionSummary-expandIconWrapper': {
    position: "absolute",
    bottom: "0em"
  }
}));


const useStyle = makeStyles(Style);
var changes=0
const AddWidgetManager = (props) => {
  const [assetsOrMannuel, setAssetOrMannuel] = React.useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const [imageRatio, setImageRatio] = React.useState();
  const [imageWidth, setImageWidth] = React.useState(170.6666);
  const [imageHeight, setImageHeight] = React.useState(96);
  const [imageNum, setImageNum] = React.useState(2);
  const [type, setType] = React.useState("Hero");
  const [playListId, setPlayListId] = React.useState("");
  const [typeSelected, setTypeSelected] = React.useState(0);
  const [bannerSelected, setBannerSelected] = React.useState();
  const [getAssetIdBrowse, setAssetIdBrowse] = React.useState(false);
  const [openMandatoryWarn, setOpenMandatoryWarn] = React.useState(false);

  const [displayColorPicker, setDisplayColorPicker] = useState({
    color: false,
  });
  
  const [openSnackbarCreate, setOpenSnackbarCreate] = React.useState(false);
  const [openSnackbarEdit, setOpenSnackbaEdit] = React.useState(false);


  const [imageType, setImageType] = React.useState({
    imageType: "",
  });

  const [widgetCommonDetails, setWidgetCommonDetails] = React.useState({
    name: "",
    description: "",
    playlistId: "",
    playlistName:"",
    enableSchedule: false,
    startDate: undefined,
    endDate: undefined,
    platforms: [],
    status:"DRAFT",
    brandingLogo:{
      imageUrl:"",
      position:"",
      header:false
    }
  });
  console.log("🚀 ~ widgetCommonDetails:", widgetCommonDetails)
  const [imageInputError, setImageInputError] = useState({
    "brandingLogo":""
  });
  const [selectedCategory, setSelectedCategory] = React.useState(0);

  const [heroAssetId, setHeroAssetId] = React.useState({
    assetId: "",
  });

  const [heroManualWidgetDetailsHTM, setHeroManualWidgetDetailsHTM] = React.useState({
    link: "",
    landingPageTitle: "",
  });

  const [parameters, setParameters] = React.useState({
    layout: "HRO",
    type: "AST",
    p_id: props?.projectDetails?.p_id,
  });
  const [heroWidgetData, setHeroWidgetDataType] = React.useState({
    multilingualTitle: {},
    imageSource: "AST",
    imageURL: "",
    landingPage: "",
    isProgram: false,
  });

  const [caresoulWidgetdataType, setCaresoulWidgetDateType] = React.useState({
    listingLayoutContentSize: "",
    title: "",
    multilingualTitle: {},
    showHeader: true,
    enableMultilingualTitle: false,
    viewAllButton: {
      enabled: "",
      viewType: "",
    },
    listingLayout: "",
    titlePosition:"",
    contentIndicator: "",
    autoRotateDuration: undefined,
    autoRotate: false,
  });
  const [horRailWidgetDataType, setHorRailWidgetDataType] = React.useState({
    listingLayoutContentSize: "",
    title: "",
    multilingualTitle: {},
    showHeader: true,
    enableMultilingualTitle: false,
    listingLayout: "LST",
    moreViewConfig: {},
    viewAllButton: {
      enabled: "",
      viewType: "",
    },
    pageSize: undefined,
    showMoreButton: false,
    railCardType: "IMAGE",
    railCardSize: "SMALL",
  });

  const [customWidgetDataType,setCustomWidgetDataType] = React.useState({})

  const [adWidget, setAdWidget] = useState({
    itemData: {
      platform: "",
      adUnitInfo: { adId: "" },
    },
    height:"",
    width:""
  });

  // For Publish Edit All Screen
  const initialExportState = {
    IOS_MOB: false,
    AND_MOB: false,
    WEB_MOB: false,
    AND_TAB: false,
    IOS_TAB: false,
    WEB_DES: false,
    AND_TV: false,
    LG_SMART_TV: false,
    SAMSUNG_SMART_TV: false,
  };
  const [Open, setOpen] = useState(false);
  const [selectExpo, setSelectExpo] = useState(initialExportState);
  const [expoPlatformList, setExpoPlatformList] = useState([]);
  const [widgetUsage, setWidgetUsage] = useState([]);
  const [canPublish, setCanPublish] = useState(false);
  const [screenDetail, setScreenDetail] = useState("");

  const handleClose = () => {
    setOpen(false);
    setSelectExpo(initialExportState);
    setExpoPlatformList([]);
  };

  const handleConfirmExport = () => {
    createWidget();
    setCanPublish(true);
  };
  
  const PlatformNames = {
    WEB_DES: "Web Desktop",
    WEB_MOB: "Web Mobile",
    AND_MOB: "Android Mobile",
    AND_TAB: "Android Tablet",
    IOS_MOB: "  IOS Mobile",
    IOS_TAB: "IOS Tablet",
    AND_TV: "Android Tv",
    SAMSUNG_SMART_TV: "Samsung Smart Tv",
    LG_SMART_TV: "LG Smart Tv",
    APPLE_TV: "Apple TV",
    VIDAA_TV: "Vidaa TV",
    DONGLE:"DONGLE",
    FIRESTICK: "FIRESTICK",
    STB:"Setup Box",
    SKYGLASS_TV:"Sky glass TV"
  };

  const renderExpo = widgetUsage?.filter((widget) => widget.platformShortName !== PlatformNames[widget.platformShortName]);

  const handleExportCard = (value) => {
    const publishedScreen = widgetUsage?.filter(
      (widget) =>{ 
        return widget?.widgetID+widget?.screenId === value}
    );

    console.log(publishedScreen,"publishedScreen")
    if(publishedScreen[0]){
      setScreenDetail(publishedScreen[0] );
    }

  };

  //Messages for Widget Creation Snackbar
  const createMessages = {
    success: `${props.createWidget?.data?.name} widget has been created.`,
    warning: ``,
    error: `There seems to be an error creating ${props.createWidget?.data?.name} widget. Please try again.`,
  };
  const editMessages = {
    success: `${props.editWidget?.data?.name} widget has been edited.`,
    warning: ``,
    error: `There seems to be an error editing ${props.createWidget?.data?.name} widget. Please try again.`,
  };


  React.useEffect(() => {
    console.log("It this working for times",props.createWidget.hzStatus, props.widgetReset);
    props.createWidget?.hzStatus === 200 && props.widgetReset ? setOpenSnackbarCreate(true) : props.createWidget?.hzStatus === 400&& props.widgetReset?setOpenSnackbarCreate(true) :null;
  }, [props.createWidget]);

  React.useEffect(() => {
    props.editWidget?.hzStatus === 200 && props.widgetEdit ?setOpenSnackbaEdit(true) :props.editWidget?.hzStatus === 400 && props.widgetEdit?setOpenSnackbaEdit(true): null;
  }, [props.editWidget]);

  React.useEffect(() => {
    if (canPublish) {
      const allScreensToPublish = props?.widgetUsage?.widgetUsage;
      if (allScreensToPublish) {
      const promises = allScreensToPublish.map(screen => {
        return new Promise((resolve, reject) => {
          let publishParameters = {
            id: screen.screenId,
            p_id: props?.projectDetails?.p_id,
            status: "PUBLISHED",
          };
          props.updateScreenStatusAction(publishParameters)
            .then(() => {
              resolve();
            })
            .catch(error => {
              reject(error);
            });
        });
      });
      async function func(){
        try{
          const res = await  Promise.all(promises)
          setOpen(false);
          setCanPublish(false);
        }catch(error){
          console.error("Error occured while publishing screens:", error);
        }
      }
      
      func()
      
    
      }
    }
  }, [props.editWidget]);

  const [adLayout, setAdLayout] = useState("");
  useEffect(()=>{
   if(props?.location?.pathname==="/admin/widgetmanager/edit") changes++

    console.log("changed",changes)
  })
  
  const classes = useStyle();
  const history = useHistory();

  React.useEffect(() => {
    props.getSingleWidgetAction(props?.location?.state?.widgetDetails?._id, 0, props?.projectDetails?.p_id);
    //Get The project api call action
    props.getProjectByIdAction(props.projectDetails.p_id);
  }, []);

  React.useEffect(() => {
    props.getCategoryTreeAction(props?.projectDetails?.p_id);
  }, []);
  
  React.useEffect(() => {
    setWidgetUsage(props?.widgetUsage?.widgetUsage);
  }, [props?.widgetUsage]);

  React.useEffect(() => {
    /*  setImageRatio(props?.projectAspectRatio?.[0]);
    setImageType({ ...imageType, imageType: props?.projectAspectRatio?.[0] });
    //Function called to calulate the image ratio
  
    setHeroWidgetDataType({
      ...heroWidgetData,
      imageType: props?.projectAspectRatio?.[0],
    }); */
    
if(props?.location?.pathname==="/admin/widgetmanager/edit"){
  let widgetInformation=props?.location?.state?.widgetDetails
 // setParameters({ ...parameters, layout: widgetInformation.layout });
 console.log("Maknsjf",type,widgetInformation);

if(widgetInformation?.layout==="HRO"&&widgetInformation?.item?.imageSource==="MNL"){
  console.log("WidgetInformation",widgetInformation);
  setAssetOrMannuel(true)
  setType("Hero")
  setSelectedCategory(1)
  setParameters({ ...parameters, layout: "HRO",type:"MNL" });
  setTypeSelected(0)
  setHeroWidgetDataType({
    ...heroWidgetData,
    multilingualTitle: widgetInformation?.item?.multilingualTitle?widgetInformation?.item?.multilingualTitle:{},
    imageSource:widgetInformation?.item?.imageSource,
    imageURL: widgetInformation?.item?.imageURL,
    landingPage: widgetInformation?.item?.landingPage?.type,
    isProgram: widgetInformation?.item?.landingPage?.isProgram,
  })
  setWidgetCommonDetails({
    name:widgetInformation.name,
    brandingLogo:(widgetInformation?.brandingLogo?widgetInformation?.brandingLogo:{}),
    color:(widgetInformation?.color ?widgetInformation?.color:null),
    transparency:(widgetInformation?.transparency ?widgetInformation?.transparency:null),
    description:widgetInformation.description,
    playlistId:widgetInformation?.item?.landingPage?.playlist?.kalturaChannelId,
   playlistName:widgetInformation?.item?.landingPage?.playlist?.kalturaChannelName,
    enableSchedule: widgetInformation?.isScheduled,
   startDate: widgetInformation?.startDate,
    endDate: widgetInformation?.endDate,
    platforms: widgetInformation.supportedDevices,
    status:"DRAFT"
  })
  if(widgetInformation?.item?.landingPage?.type==='AST'){

   console.log("sfbjsfjsfhj");
    setHeroAssetId({...heroAssetId,assetId:widgetInformation?.item?.landingPage?.assetId})
  }

  if(widgetInformation?.item?.landingPage?.type==="HTM"){
    /*  const [heroManualWidgetDetailsHTM, setHeroManualWidgetDetailsHTM] = React.useState({
    link: "",
    landingPageTitle: "",
  });;*/
  setHeroManualWidgetDetailsHTM({...heroManualWidgetDetailsHTM,link:widgetInformation?.item?.landingPage?.link,landingPageTitle:widgetInformation?.item?.landingPage?.landingPageTitle,    isProgram: widgetInformation?.item?.landingPage?.isProgram,
  })
  }


}

else if(widgetInformation?.layout==="HRO"&&widgetInformation?.item?.imageSource==="AST"){
setAssetOrMannuel(false)
  setType("Hero")
  setSelectedCategory(0)
  setParameters({ ...parameters, layout: "HRO" ,type:"AST"});
  setTypeSelected(0)
  setHeroWidgetDataType({
    ...heroWidgetData,
    //multilingualTitle: widgetInformation?.item?.multilingualTitle?widgetInformation?.item?.multilingualTitle:{},
    imageSource:widgetInformation?.item?.imageSource,
    imageURL: widgetInformation?.item?.imageURL,
    landingPage: widgetInformation?.item?.landingPage.type,
    isProgram: widgetInformation?.item?.isProgram,
    
  })
  setHeroAssetId({...heroAssetId,assetId:widgetInformation?.item?.assetId})
  setWidgetCommonDetails({
    name:widgetInformation.name,
    brandingLogo:(widgetInformation?.brandingLogo?widgetInformation?.brandingLogo:{}),
    color:(widgetInformation?.color ?widgetInformation?.color:null),
    transparency:(widgetInformation?.transparency ?widgetInformation?.transparency:null),
    description:widgetInformation.description,
    playlistId:widgetInformation?.item?.landingPage?.playlist?.kalturaChannelId,
    playlistName:widgetInformation?.item?.landingPage?.playlist?.kalturaChannelName,
    enableSchedule: widgetInformation?.isScheduled,
   startDate: widgetInformation?.startDate,
    endDate: widgetInformation?.endDate,
    platforms: widgetInformation.supportedDevices,
    status:"DRAFT"
  })
  if(widgetInformation?.item?.landingPage.type==="HTM"){
    /*  const [heroManualWidgetDetailsHTM, setHeroManualWidgetDetailsHTM] = React.useState({
    link: "",
    landingPageTitle: "",
  });;*/
  setHeroManualWidgetDetailsHTM({...heroManualWidgetDetailsHTM,link:widgetInformation?.item?.landingPage?.link,landingPageTitle:widgetInformation?.item?.landingPage?.landingPageTitle})
  }

  console.log("Hari om",widgetInformation,heroAssetId);

}


else if(widgetInformation?.layout==="HOR"){
  setType("Horizontal Rails")
  setParameters({ ...parameters, layout: "HOR", type: "CNT" });
setTypeSelected(2)

//const [horRailWidgetDataType, setHorRailWidgetDataType] = React.useState({

  setHorRailWidgetDataType({...horRailWidgetDataType,
    listingLayoutContentSize:widgetInformation?.item?.listingLayoutContentSize,
    title:widgetInformation?.item?.title,
    multilingualTitle:widgetInformation?.item?.multilingualTitle?widgetInformation?.item?.multilingualTitle:{},
    showHeader: widgetInformation?.item?.showHeader,
    enableMultilingualTitle: widgetInformation?.item?.enableMultilingualTitle,
    listingLayout: widgetInformation?.item?.listingLayout,
    moreViewConfig: widgetInformation?.item?.moreViewConfig,
    viewAllButton: {
      enabled: "",
      viewType: "",
    },
    pageSize: widgetInformation?.item?.pageSize,
    showMoreButton:widgetInformation?.item?.showMoreButton ,
    railCardType: widgetInformation?.item?.railCardType,
    railCardSize: widgetInformation?.item?.railCardSize,

  
  })
  setWidgetCommonDetails({
    name:widgetInformation.name,
    brandingLogo:(widgetInformation?.brandingLogo ?widgetInformation?.brandingLogo:{}),
    color:(widgetInformation?.color ?widgetInformation?.color:null),
    transparency:(widgetInformation?.transparency ?widgetInformation?.transparency:null),
    description:widgetInformation.description,
    playlistId:widgetInformation?.item?.playlist?.kalturaChannelId,
    playlistName:widgetInformation?.item?.playlist?.kalturaChannelName,
    enableSchedule: widgetInformation?.isScheduled,
   startDate: widgetInformation?.startDate,
  endDate: widgetInformation?.endDate,
    platforms: widgetInformation.supportedDevices,
    status:"DRAFT"
  })

}

else if(widgetInformation?.layout==="CAR"){
  console.log("Widget content",widgetInformation)
  setType("Carousel")
  setParameters({ ...parameters, layout: "CAR", type: "CNT" });
  setTypeSelected(1)
  setWidgetCommonDetails({
    name:widgetInformation.name,
    brandingLogo:(widgetInformation?.brandingLogo?widgetInformation?.brandingLogo:{}),
    color:(widgetInformation?.color ?widgetInformation?.color:null),
    transparency:(widgetInformation?.transparency ?widgetInformation?.transparency:null),
    description:widgetInformation.description,
    playlistId:widgetInformation?.item?.playlist?.kalturaChannelId,
    playlistName:widgetInformation?.item?.playlist?.kalturaChannelName,
    enableSchedule: widgetInformation?.isScheduled,
   startDate: widgetInformation?.startDate,
    endDate: widgetInformation?.endDate,
    platforms: widgetInformation.supportedDevices,
    contentIndicator:widgetInformation?.item?.contentIndicator,
    status:"DRAFT"

  })
setCaresoulWidgetDateType({...caresoulWidgetdataType,
  listingLayoutContentSize: widgetInformation?.item?.listingLayoutContentSize,
  title: widgetInformation?.item?.title,
  multilingualTitle:widgetInformation?.item?.multilingualTitle?widgetInformation?.item?.multilingualTitle: {},
  showHeader: widgetInformation?.item?.showHeader,
  enableMultilingualTitle: widgetInformation?.item?.enableMultilingualTitle,
  listingLayout: widgetInformation?.item?.listingLayout,
  //moreViewConfig:widgetInformation?.item?.multilingualTitle?widgetInformation?.item?.multilingualTitle: {},
  viewAllButton: {
    enabled: widgetInformation?.item?.viewAllButton?.enabled,
    viewType: widgetInformation?.item?.viewAllButton?.viewType,
  },
  //pageSize: widgetInformation?.item?.pageSize,
 // showMoreButton: widgetInformation?.item?.showMoreButton,
  //railCardType:widgetInformation?.item?.railCardType ,
  //railCardSize: widgetInformation?.item?.railCardSize,
  contentIndicator: widgetInformation?.item?.contentIndicator,
  titlePosition:  widgetInformation?.item?.titlePosition,
  autoRotateDuration: widgetInformation?.item?.autoRotateDuration,
  autoRotate:widgetInformation?.item?.autoRotate,
})

}

else if(widgetInformation?.layout==="CUS"&&widgetInformation?.type!=="ADS"){
  console.log("Custom widget data details",widgetInformation);
  setType("Custom")
  setWidgetCommonDetails({
    name:widgetInformation.name,
    brandingLogo:(widgetInformation?.brandingLogo?widgetInformation?.brandingLogo:{}),
    color:(widgetInformation?.color ?widgetInformation?.color:null),
    transparency:(widgetInformation?.transparency ?widgetInformation?.transparency:null),
    description:widgetInformation.description,
    playlistId:widgetInformation?.item?.playlist?.kalturaChannelId,
    playlistName:widgetInformation?.item?.playlist?.kalturaChannelName,
    enableSchedule: widgetInformation?.isScheduled,
   startDate: widgetInformation?.startDate,
    endDate: widgetInformation?.endDate,
    platforms: widgetInformation.supportedDevices,
    status:"DRAFT"
  })
  setParameters({ ...parameters, layout: "CUS", type: "CNT" });
  setTypeSelected(3)
  setCustomWidgetDataType(widgetInformation?.customFields)
}

else if(widgetInformation?.type==="ADS"){
  console.log("smfjshjhsfkhj",widgetInformation,widgetInformation?.item?.adUnitInfo?.adId);
  setParameters({ ...parameters, layout:widgetInformation.layout , type: "ADS" });
  setType("Advertisement")
  setTypeSelected(4)
  setWidgetCommonDetails({
    name:widgetInformation.name,
    brandingLogo:(widgetInformation?.brandingLogo?widgetInformation?.brandingLogo:{}),
    color:(widgetInformation?.color ?widgetInformation?.color:null),
    transparency:(widgetInformation?.transparency ?widgetInformation?.transparency:null),
    description:widgetInformation.description,
   // playlistId:widgetInformation?.item?.playlist?.kalturaChannelId,
    enableSchedule: widgetInformation?.isScheduled,
    startDate: widgetInformation?.startDate,
  endDate: widgetInformation?.endDate,
    platforms: widgetInformation.supportedDevices,
    status:"DRAFT"
  })
  setAdWidget({
    itemData: {
      platform:widgetInformation?.item?.platform ,
      adUnitInfo: { adId: widgetInformation?.item?.adUnitInfo?.adId },
    },
    height:widgetInformation?.height,
    width:widgetInformation?.width,
  })
  setAdLayout(widgetInformation?.layout)
}


console.log("widgetInformation",widgetInformation,widgetInformation?.item?.playlist?.playlistId);
  
  setImageRatio(widgetInformation?.kalturaOTTImageType)
  let imageRatioset=calculateImageRatio(widgetInformation?.kalturaOTTImageType)
  props?.projectAspectRatio?.map((value,index)=>{
    let imageRatios=calculateImageRatio(value)
    if(imageRatioset===imageRatios){

      setBannerSelected(index)
    }
    
  })
  setImageType({...imageType,imageType:widgetInformation?.item?.imageType})

}

  }, [props?.projectAspectRatio]);
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  //Onchenge Function
  const getWidgetCommonDetails = (event, category) => {
    if (category === "name") {
      setWidgetCommonDetails({
        ...widgetCommonDetails,
        name: event.target.value,
      });
    }
    else if (category === "transparency") {
      const isNumber = !(Number.isNaN(parseInt(event.target.value)))

      setWidgetCommonDetails({
        ...widgetCommonDetails,
        transparency:isNumber? parseInt(event.target.value) >100?100:parseInt(event.target.value):"",
      });
    
    }
  
    else if (category === "Description") {
      setWidgetCommonDetails({
        ...widgetCommonDetails,
        description: event.target.value,
      });
    } else if (category === "id") {
    }
  };

  const handlePlatforms = (value) => {
    setWidgetCommonDetails((prev) => {
      return {
        ...prev,
        platforms: prev.platforms?.includes(value)
          ? prev.platforms.filter((platform) => platform !== value)
          : [...prev.platforms, value],
      };
    });
  };

  const handleSelectAllPlatforms = (allPlatforms) => {
    setWidgetCommonDetails((prev) => {
      return {
        ...prev,
        platforms:allPlatforms
      };
    })
  };

  //CreateWIdget Function
  const createWidget = () =>{
   

    if(changes<5&&props?.location?.pathname==="/admin/widgetmanager/edit"){

  }else {
    let widgetInformationID
if(props?.location?.pathname==="/admin/widgetmanager/edit"){
   widgetInformationID=props?.location?.state?.widgetDetails?._id}
   console.log("let widgetInformationID",widgetInformationID,type,parameters);
  if (
      !widgetCommonDetails.enableSchedule ||
      (widgetCommonDetails.enableSchedule && widgetCommonDetails.startDate && widgetCommonDetails.endDate)
    ) {
      if (type === "Hero" && parameters.type === "MNL") {

        if (heroWidgetData.landingPage === "PLT") {

          if (
            widgetCommonDetails.name &&
            widgetCommonDetails.description &&
            widgetCommonDetails.playlistId &&
            heroWidgetData.imageURL &&
            imageType.imageType &&
            imageRatio &&
            widgetCommonDetails.platforms?.length > 0
          ) {
         if(props?.location?.pathname==="/admin/widgetmanager/edit"){
           props.editWidgetAction(widgetInformationID,
            {
              ...widgetCommonDetails,
              itemData: {
                ...heroWidgetData,
                ...imageType,
                imageRatio: imageRatio,
              },
            },
            parameters,
            props.location?.state?.prevPage
          );
         }
         else{
          props.createWidgetAction(
            {
              ...widgetCommonDetails,
              itemData: {
                ...heroWidgetData,
                ...imageType,
                imageRatio: imageRatio,
              },
            },
            parameters,
            props.location?.state?.prevPage
          );
         }
            // history.push("/admin/widgetmanager");
          } else {
            setOpenMandatoryWarn(true);
          }
        } else if (heroWidgetData.landingPage === "AST") {
          if (
            widgetCommonDetails.name &&
            widgetCommonDetails.description &&
            heroAssetId.assetId &&
            heroWidgetData.imageURL &&
            imageType.imageType &&
            imageRatio &&
            widgetCommonDetails.platforms?.length > 0
          ) {
            if(props?.location?.pathname==="/admin/widgetmanager/edit"){
              console.log();
              props.editWidgetAction(widgetInformationID,
                {
                  ...widgetCommonDetails,
                  itemData: {
                    ...heroWidgetData,
                    ...heroAssetId,
                    ...imageType,
                    imageRatio: imageRatio,
                  },
                },
                parameters,
                props.location?.state?.prevPage
              )
            }
            else{
            props.createWidgetAction(
              {
                ...widgetCommonDetails,
                itemData: {
                  ...heroWidgetData,
                  ...heroAssetId,
                  ...imageType,
                  imageRatio: imageRatio,
                },
              },
              parameters,
              props.location?.state?.prevPage
            );}
            // history.push("/admin/widgetmanager");
          } else {
            setOpenMandatoryWarn(true);
          }
        } else if (heroWidgetData.landingPage === "HTM") {
          if (
            widgetCommonDetails.name &&
            widgetCommonDetails.description &&
            heroWidgetData.imageURL &&
            heroManualWidgetDetailsHTM.landingPageTitle &&
            heroManualWidgetDetailsHTM.link &&
            imageType.imageType &&
            imageRatio &&
            widgetCommonDetails.platforms?.length > 0
          ) {
            if(props?.location?.pathname==="/admin/widgetmanager/edit"){
              console.log();
              props.editWidgetAction(widgetInformationID,
                {
                  ...widgetCommonDetails,
                  itemData: {
                    ...heroWidgetData,
                    ...heroManualWidgetDetailsHTM,
                    ...imageType,
                    imageRatio: imageRatio,
                  },
                },
                parameters,
                props.location?.state?.prevPage
              )
            }
            else{
            props.createWidgetAction(
              {
                ...widgetCommonDetails,
                itemData: {
                  ...heroWidgetData,
                  ...heroManualWidgetDetailsHTM,
                  ...imageType,
                  imageRatio: imageRatio,
                },
              },
              parameters,
              props.location?.state?.prevPage
            );}
            // history.push("/admin/widgetmanager");
          } else {
            setOpenMandatoryWarn(true);
          }
        } else {
          setOpenMandatoryWarn(true);
        }
      } else if (type === "Hero" && parameters.type === "AST") {
        
        if(heroWidgetData.landingPage === "PLT"){
        if (
          widgetCommonDetails.name &&
          widgetCommonDetails.description &&
          heroAssetId.assetId &&
          imageType.imageType &&
          imageRatio &&
          widgetCommonDetails.platforms?.length > 0
        ) {
          if(props?.location?.pathname==="/admin/widgetmanager/edit"){
            console.log();
            props.editWidgetAction(widgetInformationID,
              {
                ...widgetCommonDetails,
                itemData: {
                  ...heroWidgetData,
                  ...heroAssetId,
                  ...imageType,
                  imageRatio: imageRatio,
                },
              },
              parameters,
              props.location?.state?.prevPage
            )
          }
          else{
          props.createWidgetAction(
            {
              ...widgetCommonDetails,
              itemData: {
                ...heroWidgetData,
                ...heroAssetId,
                ...imageType,
                imageRatio: imageRatio,
              },
            },
            parameters,
            props.location?.state?.prevPage
          );}
          // history.push("/admin/widgetmanager");
        } else {
          setOpenMandatoryWarn(true);
        }
       }
       else if(heroWidgetData.landingPage === 'HTM'){
        if (
          widgetCommonDetails.name &&
          widgetCommonDetails.description &&
          heroAssetId.assetId &&
          imageType.imageType &&
          imageRatio &&
          widgetCommonDetails.platforms?.length > 0
        ) {
          if(props?.location?.pathname==="/admin/widgetmanager/edit"){
            console.log();
            props.editWidgetAction(widgetInformationID,
              {
                ...widgetCommonDetails,
                itemData: {
                  ...heroWidgetData,
                  ...heroAssetId,
                  ...imageType,
                  ...heroManualWidgetDetailsHTM,
                  imageRatio: imageRatio,
                },
              },
              parameters,
              props.location?.state?.prevPage
            )
          }
          else{
          props.createWidgetAction(
            {
              ...widgetCommonDetails,
              itemData: {
                ...heroWidgetData,
                ...heroAssetId,
                ...heroManualWidgetDetailsHTM,
                ...imageType,
                imageRatio: imageRatio,
              },
            },
            parameters,
            props.location?.state?.prevPage
          );}
          // history.push("/admin/widgetmanager");
        } else {
          setOpenMandatoryWarn(true);
        }
       }
      } else if (type === "Carousel") {
        if (
          widgetCommonDetails.name &&
          widgetCommonDetails.description &&
          widgetCommonDetails.playlistId &&
          imageType.imageType &&
          imageRatio &&
          widgetCommonDetails.platforms?.length > 0 &&
          caresoulWidgetdataType.listingLayoutContentSize &&
          (!caresoulWidgetdataType.autoRotate ||
            (caresoulWidgetdataType.autoRotate && caresoulWidgetdataType.autoRotateDuration))
        ) {
          if(props?.location?.pathname==="/admin/widgetmanager/edit"){
            props.editWidgetAction(widgetInformationID,
              {
                ...widgetCommonDetails,
                itemData: {
                  ...imageType,
                  imageRatio: imageRatio,
                  ...caresoulWidgetdataType,
                },
              },
              parameters,
              props.location?.state?.prevPage
            )
          }
          else{
          props.createWidgetAction(
            {
              ...widgetCommonDetails,
              itemData: {
                ...imageType,
                imageRatio: imageRatio,
                ...caresoulWidgetdataType,
              },
            },
            parameters,
            props.location?.state?.prevPage
          );}
          // history.push("/admin/widgetmanager");
        } else {
          setOpenMandatoryWarn(true);
        }
       
      } else if (type === "Horizontal Rails") {
        
        if (
          widgetCommonDetails.name &&
          widgetCommonDetails.description &&
          widgetCommonDetails.playlistId &&
          imageType.imageType &&
          imageRatio &&
          widgetCommonDetails.platforms?.length > 0 &&
          (!horRailWidgetDataType.showMoreButton ||
            (horRailWidgetDataType.showMoreButton && horRailWidgetDataType.pageSize))&&horRailWidgetDataType.listingLayoutContentSize
        ) {
          
          if(props?.location?.pathname==="/admin/widgetmanager/edit"){
         
            props.editWidgetAction(widgetInformationID,
              {
                ...widgetCommonDetails,
                itemData: {
                  ...horRailWidgetDataType,
                  ...imageType,
                  imageRatio: imageRatio,
                },
              },
              parameters,
              props.location?.state?.prevPage
            )
          }
          else{
          props.createWidgetAction(
            {
              ...widgetCommonDetails,
              itemData: {
                ...horRailWidgetDataType,
                ...imageType,
                imageRatio: imageRatio,
              },
            },
            parameters,
            props.location?.state?.prevPage
          );}
          // history.push("/admin/widgetmanager");
        } else {
          setOpenMandatoryWarn(true);
        }
      } else if (type === "Advertisement") {

if(props?.location?.pathname==="/admin/widgetmanager/edit"){
  if (
    widgetCommonDetails.name &&
    widgetCommonDetails.description &&
    widgetCommonDetails.platforms?.length > 0 &&
    adWidget.itemData.platform &&
    adWidget.itemData.adUnitInfo.adId &&
    adLayout && adWidget.height&&adWidget.width
  ) {
    props.editWidgetAction(widgetInformationID,
      {
        name: widgetCommonDetails.name,
        description: widgetCommonDetails.description,
        platforms: widgetCommonDetails.platforms,
        itemData: adWidget.itemData,
        width:adWidget.width,
        height:adWidget.height
      },
      { layout: adLayout, type: "ADS", p_id: props?.projectDetails?.p_id }
    );
  } else {
    setOpenMandatoryWarn(true);
  }
}
else{
        if (
          widgetCommonDetails.name &&
          widgetCommonDetails.description &&
          widgetCommonDetails.platforms?.length > 0 &&
          adWidget.itemData.platform &&
          adWidget.itemData.adUnitInfo.adId &&
          adLayout 
        ) {
          props.createWidgetAction(
            {
              name: widgetCommonDetails.name,
              description: widgetCommonDetails.description,
              platforms: widgetCommonDetails.platforms,
              itemData: adWidget.itemData,
              width:adWidget.width,
              height:adWidget.height
            },
            { layout: adLayout, type: "ADS", p_id: props?.projectDetails?.p_id }
          );
        } else {
          setOpenMandatoryWarn(true);
        }}
      } else if (type === 'Custom'){
        if(props?.location?.pathname==="/admin/widgetmanager/edit"){
          if (
            widgetCommonDetails.name &&
            widgetCommonDetails.description &&
            widgetCommonDetails.platforms?.length > 0 &&
            Object.keys( customWidgetDataType).length>0&& Object.values( customWidgetDataType).length>0
            ){
            console.log("Custofieldm ",customWidgetDataType);
            props.editWidgetAction(widgetInformationID,
              {
                name:widgetCommonDetails.name,
                description:widgetCommonDetails.description,
                platforms:widgetCommonDetails.platforms,
                customFields:customWidgetDataType,
              }, 
              parameters,
              props.location?.state?.prevPage
             )
          }
          else{
            setOpenMandatoryWarn(true);
          }}

        
       else{ 
         if (
          widgetCommonDetails.name &&
          widgetCommonDetails.description &&
          widgetCommonDetails.platforms?.length > 0 &&
         Object.keys( customWidgetDataType).length>0 &&Object.values( customWidgetDataType).length>0
        ){
           props.createWidgetAction(
            {
              name:widgetCommonDetails.name,
              description:widgetCommonDetails.description,
              platforms:widgetCommonDetails.platforms,
              customFields:customWidgetDataType,
              status:"DRAFT"
            }, 
            parameters,
            props.location?.state?.prevPage
           )
        }
        else{
          setOpenMandatoryWarn(true);
        }
      } }
    }else {
      setOpenMandatoryWarn(true);
    }
    
    //  console.log(imageRatio,imageType,"Image type")
  };}

  //Data Item for the Advertisement

  const setTypesOnClick = (value, index) => {
   if(props.location.pathname!=='/admin/widgetmanager/edit'){
    setTypeSelected(index);
    setType(value);

    if (value === "Hero") {
      setParameters({ ...parameters, layout: "HRO" });
    } else if (value === "Carousel") {
      setParameters({ ...parameters, layout: "CAR", type: "CNT" });
    } else if (value === "Horizontal Rails") {
      setParameters({ ...parameters, layout: "HOR", type: "CNT" });
    } else if (value === "Advertisement") {
    }
    else if(value === "Custom"){
      setParameters({ ...parameters, layout: "CUS", type: "CNT" });
    }
   }
  };

  const selectType = (types) => {
    console.log("Type of widget",types);
    if (types === "Hero") {
      console.log("sjfhjhfksksfks",heroAssetId);
      return (
        <HeroType
assetsOrMannuel={assetsOrMannuel}
setAssetOrMannuel={setAssetOrMannuel}
          openModal={openModal}
          setOpenModal={setOpenModal}
          heroWidgetData={heroWidgetData}
          setHeroWidgetDataType={setHeroWidgetDataType}
          playListId={widgetCommonDetails.playlistId}
          playlistName={widgetCommonDetails.playlistName}
          assetId={heroAssetId.assetId}
          getAssetIdBrowse={getAssetIdBrowse}
          setAssetIdBrowse={setAssetIdBrowse}
          openMandatoryWarn={openMandatoryWarn}
          parameters={parameters}
          setParameters={setParameters}
          heroManualWidgetDetailsHTM={heroManualWidgetDetailsHTM}
          setHeroManualWidgetDetailsHTM={setHeroManualWidgetDetailsHTM}
          setHeroAssetId={setHeroAssetId}
          setOpenMandatoryWarn={setOpenMandatoryWarn}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          path={props?.location?.pathname}
        />
        
      );
    } else if (types === "Carousel") {
      return (
        <CaroslType
          caresoulWidgetdataType={caresoulWidgetdataType}
          setCaresoulWidgetDateType={setCaresoulWidgetDateType}
          openModal={openModal}
          setOpenModal={setOpenModal}
          playListId={widgetCommonDetails.playlistId}
          playlistName={widgetCommonDetails.playlistName}
          openMandatoryWarn={openMandatoryWarn}
         

        />
      );
    } else if (types == "Horizontal Rails") {
      return (
        <HorizontalRailsType
          openModal={openModal}
          setOpenModal={setOpenModal}
          horRailWidgetDataType={horRailWidgetDataType}
          setHorRailWidgetDataType={setHorRailWidgetDataType}
          playListId={widgetCommonDetails.playlistId}
          playlistName={widgetCommonDetails.playlistName}
          openMandatoryWarn={openMandatoryWarn}
          
        />
      );
    } else if (types === "Advertisement") {
      return (
        <AdvertisementRailType
          adPlatformOptions={adPlatformOptions}
          adLayoutOptions={adLayoutOptions}
          setAdLayout={setAdLayout}
          adLayout={adLayout}
          adWidget={adWidget}
          setAdWidget={setAdWidget}
          openMandatoryWarn={openMandatoryWarn}
        />
      );
    }
    else if(types === 'Custom'){
      return (
      <CustomWidget
          setCustomWidgetDataType={setCustomWidgetDataType}
          customWidgetDataType={customWidgetDataType}
          openMandatoryWarn={openMandatoryWarn}
          setOpenMandatoryWarn={setOpenMandatoryWarn}
         path= {props?.location?.pathname}
      />
      )
    }
  };

  const getImageDimensions = (imageRa, widt, heigh, num, index) => {
    setBannerSelected(index);
    setImageRatio(imageRa);
    setImageNum(num);
    setImageWidth(widt);
    setImageHeight(heigh);
    //  setImageType({ ...imageType,imageRatio, imageType: imageRa });
    setHeroWidgetDataType({ ...heroWidgetData, imageType: imageRa });
    setWidgetCommonDetails({ ...widgetCommonDetails, imageRatio: imageRa });
    /*  if (imageRa === "16x9") {
      setImageType({ ...imageType, imageType: "LDS" });
      setHeroWidgetDataType({ ...heroWidgetData, imageType: "LDS" });
    } else if (imageRa === "2x3") {
      setImageType({ ...imageType, imageType: "PR2" });
      setHeroWidgetDataType({ ...heroWidgetData, imageType: "PR2" });
    } else if (imageRa === "9x16") {

      setImageType({ ...imageType, imageType: "PR3" });
      setHeroWidgetDataType({ ...heroWidgetData, imageType: "PR3" });
    } */
  };
if(!(typeof props?.projectDetails?.p_id !=="undefined")){
  return <div>{"Loading ...."}</div>;
}

const handleImageInputError = (name, message, reset = false) => {
  setImageInputError((error) => ({
    ...error,
    [name]: message,
  }));
};

const handleColorPicker = () => {
  setDisplayColorPicker((prev) => {
    return {color: !prev.color };
  });
};

const handleColor = (value) => {
  const hexValue = rgbaToHex(
    value.rgb.r,
    value.rgb.g,
    value.rgb.b,
    value.rgb.a,
  );
  setWidgetCommonDetails({
    ...widgetCommonDetails,
    color:hexValue
  })
}
const getRatio = async (url) => {
  console.log("URL rat", url);
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      let ratio = img.width / img.height;
      resolve(ratio);
    };
  });
};

const getBase64 = async (files) => {
  if (files.length === 1) {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = async (e) => {
        const ratio = await getRatio(e.target.result);
        resolve([{ b64: reader.result, ratio }]);
      };
    });
  } else {
    const fileData = Object.values(files);
    const filePathsPromises = [];
    fileData.forEach((file) => {
      filePathsPromises.push(
        new Promise((resolve) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async (e) => {
            const ratio = await getRatio(e.target.result);
            resolve({ b64: reader.result, ratio });
          };
        }),
      );
    });
    const filePaths = await Promise.all(filePathsPromises);
    return filePaths;
  }
};
const imageHandler = async (event,index=null) => {
  const files = event.target.files;

  const imageSizes = [...Array(files.length)].map(
    (_, index) => files[index].size,
  );

  const fileType = [...Array(files.length)].map((_, index) => {
    return files[index].type;
  });
  let _supportedImageTypes=[];
  const _brandingLogoSupportedImageTypes = ["image/png"];
  if (event.target.id=="brandingLogo"){
    _supportedImageTypes = _brandingLogoSupportedImageTypes
  }
  if (
    fileType.every((type) => {
      console.log(type, "type");
      return !_supportedImageTypes.includes(type);
    })
  ) {
    return files.length
      ? handleImageInputError(
          event.target.id,
          `Only ${_supportedImageTypes.map(type=>type.slice(5,type.length)).join(",")} files are supported.`,
        )
      : null;
  }
console.log(widgetCommonDetails?.brandingLogo?.imageURL,"widgetCommonDetails?.brandingLogo?.imageURL")
  if (imageSizes.every((size) => size < 1048576*5)) {
    const images = await getBase64(files);
    console.log("images", images, images.length);

    const uploadAPI = async (images) => {
      let _endpoint = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-file`
      if (event.target.id.toLowerCase().includes("brandingLogo")){
          _endpoint  = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-content-badge`
      }
      return await axios
        .post(
          _endpoint,
          { file: images[0].b64 },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_X_API_KEY,
              Authorization: localStorage.getItem("token"),
            },
          },
        )
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    };

    if (images.length && event.target.id) {
      let S3image;
      S3image = await uploadAPI(images);
      if (S3image?.data?.uploadResult?.Location) {
        handleImageInputError(event.target.id, "");
       if (event.target.id === "brandingLogo"){
        setWidgetCommonDetails({ ...widgetCommonDetails, brandingLogo:{
          ...widgetCommonDetails.brandingLogo,
          imageUrl:S3image?.data?.uploadResult?.Location
        } });  
        }
       
      } else {
        handleImageInputError(
          event.target.id,
          "Image could not be uploaded. Please try again.",
        );
      }
    } else {
      handleImageInputError(
        event.target.id,
        "Image could not be uploaded. Please try again.",
      );
    }
  } else {
    handleImageInputError(event.target.id, "Maximum file limit is 150MB.");
  }
  event.target.value = null;
};


    return (
      <> <> 
      <Snackbar
        open={openSnackbarCreate}
        severity={props.createWidget?.hzStatus === 200 ? "success" : "error"}
        message={
          props.createWidget?.hzStatus === 200 ? createMessages.success : createMessages.error
        }
        setOpen={() => 
         // console.log("Itana sara cash")
          setOpenSnackbarCreate(false)
      }
      />
       <Snackbar
        open={openSnackbarEdit}
        severity={props.editWidget?.hzStatus === 200 ? "success" : "error"}
        message={
          props.editWidget?.hzStatus === 200 ? editMessages.success : editMessages.error
        }
        setOpen={() => setOpenSnackbaEdit(false)}
      /></> <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          overflowY: "auto",
          marginBottom: "50px",
        }}
      >
       
        <Primary>{props.location.pathname!=='/admin/widgetmanager/edit'?"Add New Widget":"Edit Widget"}</Primary>
        <SubHead>Widget Basic Information</SubHead>
        <div className={classes.widgetDetailContainer}>
          <Box className={classes.validateBox}>
            <TextField
              placeholder="Enter Widget Name *"
              variant="outlined"
              value={widgetCommonDetails.name}
              required
              size="Normal"
              className={classes.textField}
              labelWidth={0}
              onChange={(event) => getWidgetCommonDetails(event, "name")}
            />
            {openMandatoryWarn ? (
              widgetCommonDetails?.name ? null : (
                <span className={classes.validationText}>* Mandatory Field</span>
              )
            ) : null}
          </Box>

          <Box className={classes.validateBox} marginLeft="1.5rem">
            <TextField
              placeholder="Enter Widget Description *"
             
              // multiline
              // rows={1}
              value={widgetCommonDetails.description}
              variant="outlined"
              required
              size="Normal"
              className={classes.textField}
              style={{ width: "412px", marginRight: "20px" }}
              labelWidth={0}
              onChange={(event) => getWidgetCommonDetails(event, "Description")}
            />
            {openMandatoryWarn ? (
              widgetCommonDetails?.description ? null : (
                <span className={classes.validationText}>* Mandatory Field</span>
              )
            ) : null}
          </Box>
        </div>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid container>
            <Grid item sx={12} md={6} lg={6} style={{ marginTop: "1rem", display: "table" }}>
              <Box width="100%">
                <SubHead>Content Layout</SubHead>
                <Typography>Select Type</Typography>
                <div className={classes.widgetDetailContainer}>
                  {selRails.map((value, index) => {
                    var selectedTypeClass;
                    if (index === typeSelected) {
                      selectedTypeClass = classNames({
                        ["  " + classes.selectedType]: true,
                      });
                    }
                    return (
                      <span
                        className={classNames(classes.typeContainer, selectedTypeClass)}
                        style={index !== 0 ? { marginLeft: "1rem" } : { marginLeft: 0 }}
                        onClick={() => {
                          setTypesOnClick(value, index);
                        }}
                      >
                        {value}
                      </span>
                    );
                  })}
                </div>

                {type === "Advertisement" || type === "Custom" ? null : (
                  <>
                  
                    
                    <AspectRatio
                      openMandatoryWarn={openMandatoryWarn}
                      classes={classes}
                      projectAspectRatio={props.projectAspectRatio}
                      bannerSelected={bannerSelected}
                      getImageDimensions={getImageDimensions}
                      setWidgetCommonDetails={setWidgetCommonDetails}
                      widgetCommonDetails={widgetCommonDetails}
                      imageRatio={imageRatio}
                    />
                    <AspectRatioShape
                      openMandatoryWarn={openMandatoryWarn}
                      classes={classes}
                      imageType={imageType}
                      setImageType={setImageType}
                      imageRatio={imageRatio}
                      bannerSelected={bannerSelected}
                      setWidgetCommonDetails={setWidgetCommonDetails}
                      widgetCommonDetails={widgetCommonDetails}
                      setOpenMandatoryWarn={setOpenMandatoryWarn}
                    />
                  </>
                )}
                <Box style={{display:"flex", gap:"10px"}}>
            <Box style={{marginBottom:"8px"}}>
                <Typography
                  style={{ marginBottom: "10px" }}
                  className={classes.inputlabel}
                >
                  Branding Logo
                </Typography>
                <Box paddingBottom="5px">
                    <label htmlFor="brandingLogo" style={{ cursor: "pointer" }}>
                      <Input
                        style={{ 
                          display: "none",
                         
                         }}
                        id="brandingLogo"
                        name="brandingLogo"
                        type="file"
                        multiple
                        onChange={imageHandler}
                        required
                        inputProps={{ accept: ".png" }}
                        fullWidth
                      />
                      <Box
                        className={classes.logofield}
                        
                        display="flex"
                        alignItems="center"
                        justifyContent="space-evenly"
                      >
                        <Box>
                          
                          <Typography className={classes.logoTypo}>
                          Upload Image
                          </Typography>
                        </Box>
                      </Box>
                      
                    </label>
               
                    {
            imageInputError["brandingLogo"] && <FormHelperText  style={{ color:"red"}}>{imageInputError["brandingLogo"]}</FormHelperText>
}
                    {widgetCommonDetails?.brandingLogo?.imageUrl && (
                      <Box style={{marginTop:"5px",marginBottom:"5px"}} className={classes.Image16}>
                        <img
                          className={classes.Image16}
                          src={
                            widgetCommonDetails?.brandingLogo?.imageUrl
                          }
                        ></img>
                      </Box>
                    )}
                    
                  </Box>
              </Box>
              <Box>
      
      <Typography
    style={{ marginBottom: "8px" }}
    className={classes.inputlabel}
  >
   Branding Position
  </Typography>
<TextField
disabled = {!widgetCommonDetails?.brandingLogo?.imageUrl || widgetCommonDetails.brandingLogo?.header}
id="Branding-position"
value={widgetCommonDetails?.brandingLogo?.position || ""}
onChange={(e)=>{
  setWidgetCommonDetails(data=>({...data,
    brandingLogo:{
      ...data?.brandingLogo,
      position:e.target.value
    }
  }))
}}
select
size="small"
variant="outlined"
style={{minWidth: "200px", backgroundColor: "#FFF", display: "flex", width:"50%"}}
SelectProps={{
  MenuProps: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    getContentAnchorEl: null
  }
}}
>
<MenuItem value={"TR"}>
      TOP RIGHT
</MenuItem>
<MenuItem value={"TL"}>
      TOP LEFT
</MenuItem>
<MenuItem value={"C"}>
      CENTER
</MenuItem>
<MenuItem value={"BL"}>
      BOTTOM  LEFT
</MenuItem>
<MenuItem value={"BR"}>
      BOTTOM RIGHT
</MenuItem>
</TextField>
{(openMandatoryWarn && widgetCommonDetails?.brandingLogo?.imageUrl ) ? (
(widgetCommonDetails?.brandingLogo?.position || widgetCommonDetails?.brandingLogo?.header) ? null : (
  <span className={classes.validationText}>* Mandatory Field</span>
)
) : null}
<FormHelperText style={{ color: "red", fontSize: "10px" ,marginTop:"5px"}}>
          { (widgetCommonDetails?.brandingLogo?.imageUrl === undefined ||
          widgetCommonDetails?.brandingLogo?.imageUrl === "")
            ? "Note:First upload  branding logo":""
            }
            
        </FormHelperText>   
  </Box>
  {
    type === "Horizontal Rails" &&   <Box style={{marginBottom:"5px"}} className={classes.validateBox}>
    <Typography
        style={{ marginBottom: "10px" }}
        className={classes.inputlabel}
      >
        Branding header
      </Typography>
      <Box >
        
      <AntSwitch  disabled={widgetCommonDetails.brandingLogo?.imageUrl?false:true}  key={"Branding Header"} defaultChecked={widgetCommonDetails.brandingLogo?.header?widgetCommonDetails.brandingLogo?.header:false} name="BrandingHeder" onChange={(event)=>{
         if(widgetCommonDetails.brandingLogo?.imageUrl){
          setWidgetCommonDetails({ ...widgetCommonDetails, brandingLogo:{
            ...widgetCommonDetails.brandingLogo,
            header:event.target.checked
          } });  
         }
     
        
      }} />
      </Box>

         </Box>
  }
            
                
        

                
                </Box>
              <Box paddingBottom="6px" style={{display:"flex",flexDirection:"row" , gap:"10px", alignItems:"center" }}>
                <Box>
              <Typography className={classes.inputLabel}>
                        Color
                        
                    </Typography>
                    <Box
                      className={classes.swatch}
                     
                      style={{
                        background: widgetCommonDetails?.color, 
                        padding: "11px",                                           
                      }}
                      onClick={handleColorPicker}
     
                    >
                      <Box
                        className={classes.color}
                        style={{
                          background:
                          widgetCommonDetails?.color,
                        }}
                      />
                    </Box>
                    {displayColorPicker.color ? (
                      <Box className={classes.popover}>
                        <Box
                          className={classes.cover}
                          onClick={handleColorPicker}
                        />
                        <SketchPicker
                          key="colorPicker"
                          color={widgetCommonDetails?.color|| "white"}
                          onChange={handleColor}
                        />
                      </Box>
                    ) : null}
                    </Box>
              
                  <Box style={{marginBottom:"5px"}} className={classes.validateBox}>
                  <Typography className={classes.inputLabel}>
                  Transparency
                    </Typography>
            <TextField
              placeholder="0-100"
              variant="outlined"
              value={widgetCommonDetails?.transparency}
              required
              size="small"
              style={
                {
                  width: "150px",
                  background: "white",
                  height: "40px",
                  borderRadius: "5px",
                }
              }
              labelWidth={0}
              onChange={(event) => getWidgetCommonDetails(event, "transparency")}
            />
            {(openMandatoryWarn && widgetCommonDetails.color) ? (
             (   widgetCommonDetails?.transparency) ? null: (
                <span className={classes.validationText}>* Mandatory Field</span>
              )
            ) : null}
                   </Box>
               
                    </Box>

                {selectType(type)}
                {type === "Advertisement" ? null : (
                  <EnableSchedule
                    widgetCommonDetails={widgetCommonDetails}
                    setWidgetCommonDetails={setWidgetCommonDetails}
                    openMandatoryWarn={openMandatoryWarn}
                  />
                )}
                <div
                  className={classes.widgetDetailContainer}
                  style={{
                    width: "300px",
                  }}
                >

                  {type === "Hero" ? (
                    heroWidgetData.landingPage === "HTM" ? (
                      <div style={{ marginBottom: "30px" }}>
                        <Typography>Image Source</Typography>
                        <div className={classes.imageSourceDiv}>
                          <Button onClick={handleClick}>
                            {" "}
                            <input type="file" ref={hiddenFileInput} hidden style={{ zIndex: "9999" }} />
                            Upload Your File
                          </Button>
                        </div>
                      </div>
                    ) : null
                  ) : null}
                </div>
                <PlayList
                  open={openModal}
                  setOpenModal={setOpenModal}
                  image={imageRatio}
                  totalPlayList={props?.categoryList?.totalPlayList }
                  categories={props?.categoryList?.categories }
                  imageWidth={imageWidth}
                  imageHeight={imageHeight}
                  numOfImage={imageNum}
                  playListId={playListId}
                  setPlayListId={setPlayListId}
                  widgetCommonDetails={widgetCommonDetails}
                  setWidgetCommonDetails={setWidgetCommonDetails}
                  getAssetIdBrowse={getAssetIdBrowse}
                  setAssetIdBrowse={setAssetIdBrowse}
                  heroAssetId={heroAssetId}
                  setHeroAssetId={setHeroAssetId}
                  heroWidgetData={heroWidgetData}
                  setHeroWidgetDataType={setHeroWidgetDataType}
                  projectId={props?.projectDetails?.p_id}
                  loading = {props?.categoryList?.loading}
                >
<div>{props?.assetCategoryError.length > 0 ? props?.assetCategoryError : "Loading ...."}</div>
                </PlayList>
                {/* {type === "Advertisement" ? null : ( */}
                <Paper
                  style={{
                    width: "95%",
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    width: "100vw",
                    zIndex: 2,
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      height: "80px",
                      paddingRight: "60px",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className={classes.cancelButton}
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                        className={classes.submitButton}
                        onClick={() => {
                          if (
                            props?.location?.pathname ===
                              "/admin/widgetmanager/edit" &&
                            renderExpo?.length > 0
                          ) {
                            setOpen(!Open);
                          } else {
                            handleConfirmExport();
                          }
                        }}
                      >
                        {props?.location?.pathname ===
                        "/admin/widgetmanager/edit"
                          ? "Continue"
                          : "Submit"}
                      </Button>

                    {/* Dialog for Publish Edit All Screen */}
                    <Dialog open={Open} onClose={handleClose} classes={{ paper: classes.dialog }}>
                      <DialogContent className={classes.content}>
                        <Box ml="0" display="flex" flexDirection="row" justifyContent="space-between">
                          <Box>
                            <Typography className={classes.ExportTypo}>
                              {/* Export <span style={{ color: "#0192D4" }}> {props.screenName}</span> to */}
                              Update Widget <span style={{ color: "#0192D4" }}> </span>
                            </Typography>
                          </Box>
                          <Button onClick={handleClose} className={classes.dialogButton}>
                            <img src={Close}></img>
                          </Button>
                        </Box>
                        <Box className={classes.dialogWarper}>
                          <Grid container spacing={0} className={classes.dialogGrid}>
                            <p>Updating this widget may affect all the platforms and screens where this widget is being used.</p>
                            {renderExpo?.map((x, i) => {
                              return (
                                <Grid item xs={4} key={i}>
                                  <Accordion expanded={x.widgetID+x.screenId===screenDetail.widgetID+screenDetail.screenId}  
                                  onClick={() =>
                                          handleExportCard(x.widgetID+x.screenId)
                                        } 
                                        className={classes.accordion}>
                                  <AccordionSummary
                        
                                        aria-controls="panel1d-content"
                                        id="panel1d-header"
                                      >
                                      <Card
                                        className={selectExpo[screenDetail.platformShortName] ? classes.selectedCard : classes.exportPltCard}
                                      >
                                        <Box display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="center">
                                          <Box paddingTop="10px" width="75px">
                                            <img
                                              src={`${process.env.REACT_APP_BASE_ASSETS_URL}${isDev() ? "" : "/assets"}/platformIcons/${
                                                x?.platformShortName
                                              }.svg`}
                                              width="75px"
                                              height="75px"
                                            />
                                          </Box>
                                          <Box>
                                            <Typography
                                              className={selectExpo[x.platformShortName] ? classes.selectedCardTypo : classes.cardTypo}
                                            >
                                              {x.platformName}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Card>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {screenDetail != "" && x.platformShortName === screenDetail.platformShortName && <Typography>
                                        Screen Name : {screenDetail.screenName}
                                      </Typography>}
                                    </AccordionDetails>
                                  </Accordion>

                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                        <Box>
                          <DialogActions className={classes.action}>
                            {/* <Button
                              variant="outlined"
                              className={classes.cancelButton}
                              style={{ width: "180px" }}
                              onClick={handleClose}
                            >
                              <Typography className={classes.cancelTypo}>Cancel</Typography>
                            </Button> */}
                            {/* <Button
                              variant="outlined"
                              className={classes.widerButton}
                              onClick={createWidget}
                            >
                              <Typography className={classes.cancelTypo}>Edit but don't Publish</Typography>
                            </Button> */}
                            <Button
                              variant="contained"
                              className={classes.saveButton}
                              style={{ width: "180px", marginLeft: "20px" }}
                              onClick={handleConfirmExport}
                            >
                              <Typography className={classes.ButtonText}>PUBLISH ALL</Typography>
                            </Button>
                          </DialogActions>
                        </Box>
                      </DialogContent>
                    </Dialog>
                    {/* End Dialog */}
                  </div>
                </Paper>
                {/* )} */}
              </Box>
            </Grid>

            <PlatformSeletor
              handleSelectAll={handleSelectAllPlatforms}
              clickHandler={handlePlatforms}
              platformSelected={widgetCommonDetails.platforms}
              selectedPlatforms={props.projectDetails?.platformList}
              ErrorMessage={props.ErrorMessage}
              widgetHelper={openMandatoryWarn}
              platformList={props.projectDetails?.platformList}
            />
          </Grid>
        </Box>
       
      </div>
      
      </>
    );
  
};

const mapStateToProps = (state) => {
  return {
    categoryList: state.PlayListReducer,
    projectDetails: state.ProjectReducer.projectDetails,
    projectAspectRatio: state.PlayListReducer.projectAspectRatio,
    assetCategoryError: state.PlayListReducer.categoryTreeErrorMessage,

    createWidget: state.PlayListReducer.createdWidget,
    widgetReset: state.PlayListReducer.widgetReset,
    widgetEdit: state.PlayListReducer.widgetEdit,
    editWidget: state.PlayListReducer.editWidget,
    widgetUsage: state.screenVisualizationReducer.widgetUsage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryTreeAction: (projectId) => dispatch(getCategoryTreeAction(projectId)),
    getSingleWidgetAction: (widget, dispOrder, projectId) => dispatch(getSingleWidgetAction(widget, dispOrder, projectId)),

    createWidgetAction: (body, parameters, prevScreen) => dispatch(createWidgetAction(body, parameters, prevScreen)),
    editWidgetAction: (id,body, parameters, prevScreen) => dispatch(editWidgetAction(id,body, parameters, prevScreen)),

    getProjectByIdAction: (projectId) => dispatch(getProjectByIdAction(projectId)),
    updateScreenStatusAction: (details) => {
      dispatch(updateScreenStatusAction(details));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWidgetManager);
