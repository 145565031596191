import {
  Box,
  Button,
  Card,
  Grid,
  ListItemText,
  makeStyles,
  MenuItem,
  MenuList,
  Table,
  TableHead,
  Typography,
  Select,
  InputLabel,
  FormControl
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import style from "assets/jss/material-dashboard-react/views/userStyle";
import UsersTable from "components/Table/UserTable";
import { useHistory } from "react-router-dom";
import { getAllUsers } from "../actions";
import { connect } from "react-redux";
import { getAllUsersAction,getAllAuditLogs,downloadAuditLogsAction } from "../actions";
import LoadingBar from "react-top-loading-bar";
import Addicon from "../../../assets/img/icons/svg/Ic_add.svg";
import Snackbar from "components/Snackbar/CustomSnackbar";
import AuditTable from "components/Table/AuditTable";

const useStyle = makeStyles(style);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: "menu",
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

const Users = (props) => {
  const classes = useStyle();

  const [openUser, setOpenUser] = useState(false);
  const [progress, setProgress] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [accessSnack, setAccessSnack] = useState(false);
  const [accessSnackMessage, setAccessSnackMessage] = useState("");
  const [deleteSnackbar,setDeleteSnackbar] = useState(false);
  const [updateSnackbar,setUpdateSnackbar] = useState(false)
  const [Change, setChange] = useState(0);
  const [username,setUsername] = useState("");

  const history = useHistory();

  const handleAddUser = () => {
    props.accessLevel === "superAdmin"
      ? history.push({ pathname: "/admin/users/adduser" })
      : [setAccessSnackMessage("You do not have access to add a new user."), setAccessSnack(true)];
  };

  const createMessages = {
    success: `User has been created.`,
    warning: ``,
    error: `There seems to be an error creating user. Please try again.`,
  };

  const deleteMessages = {
    success: `User has been deleted.`,
    warning: ``,
    error: `There seems to be an error deleting user. Please try again.`,
  };

  const updateMessages = {
    success: `User has been Updated.`,
    warning: ``,
    error: `There seems to be an error deleting user. Please try again.`,
  };

  useEffect(() => {
    props.getAllUsersAction();
    props.getAllAuditlogs(props.projectDetails.p_id,"");
  }, [props.usersReset,props.resetData,props.updateReset]);

  useEffect(() => {
    props.createUserFail?.hzStatus !== 200 && props.usersReset ? setOpenSnackbar(true) : null;
  }, [props.createUserFail]);

  useEffect(() => {
    props.createUser?.hzStatus === 200 && props.usersReset ? setOpenSnackbar(true) : null;
  }, [props.createUser]);

  useEffect(() => {
    props.deleteUser?.hzStatus === 200 && props.deleteReset === true ? setDeleteSnackbar(true) : null;
  }, [props.deleteUser]);

  useEffect(() => {
    props.deleteUserFail?.hzStatus !== 200 && props.deleteReset === true ? setDeleteSnackbar(true) : null;
  }, [props.deleteUserFail]);

  useEffect(() => {
    props.updateUser?.hzStatus === 200 && props.updateReset === true ? setUpdateSnackbar(true) : null;
  }, [props.updateUser]);

  useEffect(() => {
    props.updateUserFail?.hzStatus !== 200 && props.updateReset === true ? setUpdateSnackbar(true) : null;
  }, [props.updateUserFail]);

  useEffect(() => {
    if (!progress) setProgress(progress + 20);

    if (props?.allUsers?.length) setProgress(100);

    let timer = setTimeout(() => {
      setProgress(100);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [props?.allUsers]);

  const handleUsers = ()=>{
     setChange(0);
     setUsername("");
  }

  const handleUserName = (event)=>{
    setUsername(event.target.value);
      //  props.getAllAuditLogs(props.projectDetails.p_id,value);
  }

  useEffect(()=>{
    props.getAllAuditlogs(props.projectDetails.p_id,username);
  },[username])

  const handleAudit = ()=>{
    setChange(1);
  }

  const handleDownload = ()=>{
    props.accessLevel === "superAdmin"
    ? props.downloadAuditLogsAction(props.projectDetails.p_id)
    : [setAccessSnackMessage("You do not have access to Download a audit logs."), setAccessSnack(true)];
  }

  return (
    <Box>
      <div>
        <LoadingBar
          color="#885CF7"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
          shadow={true}
          height={3}
        />
      </div>
      <Snackbar
        open={openSnackbar}
        severity={props.createUser?.hzStatus === 200 ? "success" : "error"}
        message={
          props.createUser?.hzStatus === 200
            ? createMessages.success
            : props.createUserFail?.message
        }
        setOpen={() => setOpenSnackbar(false)}
      />
      <Snackbar
        open={deleteSnackbar}
        severity={props.deleteUser?.hzStatus === 200  ? "success" : "error"}
        message={
          props.deleteUser?.hzStatus === 200 
            ? deleteMessages.success
            : props.deleteUserFail?.message
        }
        setOpen={() => setDeleteSnackbar(false)}
      />
       <Snackbar
        open={updateSnackbar}
        severity={props.updateUser?.hzStatus === 200  ? "success" : "error"}
        message={
          props.updateUser?.hzStatus === 200 
            ? updateMessages.success
            : props.updateUserFail?.message
        }
        setOpen={() => setUpdateSnackbar(false)}
      />
      <Snackbar
        open={accessSnack}
        severity={"warning"}
        message={accessSnackMessage}
        setOpen={() => setAccessSnack(false)}
      />
      <Grid container style={{paddingBottom:'10px'}}>
        <Grid item xs={3} md={3} lg={3}>
          <Box pb={2}>
            <Typography className={classes.heading}>Users</Typography>
          </Box>
        </Grid>
        <Grid item xs={9} md={9} lg={9}>
         {Change === 0 ? 
         <Box display="flex" justifyContent="right">
            <Button onClick={handleAddUser} className={classes.button}>
              <img src={Addicon} style={{ paddingRight: "0.75rem" }} />
              Add New User
            </Button>
          </Box> : ""}
          {Change === 1 ? 
           (
            
            <Box display='flex' justifyContent='space-between'>
              {props.accessLevel === 'superAdmin' ?
              <FormControl>
              <Typography id="Select Language"  style={{width:'300px'}}>
                    Select user Name
                  </Typography>
              <Select
              labelId="Select Languages"
              className={classes.textfield}
              variant="outlined"
              fullWidth
              size="small"
              style={{ width: "100%" ,height:'50px'}}
              onChange={handleUserName}
              defaultValue=""
              MenuProps={MenuProps}>
                <MenuItem value="">
                  ALL
                </MenuItem>
                 {props.allUsers.map((x, i) => {
                      return (
                        <MenuItem value={x.name} key={i}>
                          {x.name}
                        </MenuItem>
                      );
                    })}
              </Select> 
              </FormControl> : 
              <Box>
                </Box> }
              <Button className={classes.downloadAddButton} onClick={handleDownload}>
                      <Typography className={classes.downloadButtonText}>DOWNLOAD CSV</Typography>
                   </Button>
            </Box>
           )  : ""}

        </Grid>
      </Grid>
      <Box>
        <Grid container spacing={2}>
          <Grid item sm={4} md={4} lg={3}>
            <Card className={classes.card}>
              <Box paddingLeft="6px">
                <MenuList>
                  <MenuItem onClick={handleUsers}>
                    <ListItemText className={Change === 0 ? classes.selectedList : classes.list}>Users</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleAudit}>
                    <ListItemText className={Change === 1 ? classes.selectedList: classes.list}>Audit Logs</ListItemText>
                  </MenuItem>
                </MenuList>
              </Box>
            </Card>
          </Grid>
          <Grid item sm={8} md={8} lg={9}>
            {Change === 0 ?
            <UsersTable
              tableHead={["SNo", "Username", "Email", "Phonenumber", "Role", "Edit", "Remove"]}
              tableData={props?.allUsers}
              accessLevel={props.accessLevel}
            />
            : ""}
            {Change === 1 ?  <AuditTable
              tableHead={["Username", "Project Name", "User Action", "Usertype", "updated Time"]}
              tableData={props?.auditLogs}
              projectDetails={props.projectDetails}
            /> : "" }
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const mapStateToProps = (State) => {
  return {
    allUsers: State.usersReducers.allUsers,
    usersReset: State.usersReducers.usersReset,
    createUser: State.usersReducers.createUser,
    createUserFail: State.usersReducers.createUserFail,
    accessLevel: State.loginReducer.authTokens?.accessLevel,
    deleteUser:State.usersReducers.deleteUser,
    deleteUserFail:State.usersReducers.deleteUserFail,
    deleteReset:State.usersReducers.deleteReset,
    resetData:State.usersReducers.resetData,
    updateReset:State.usersReducers.updateReset,
    updateUser:State.usersReducers.updateUser,
    updateUserFailed:State.usersReducers.updateUserFailed,
    auditLogs:State.usersReducers.auditLogs,
    projectDetails: State.ProjectReducer.projectDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsersAction: () => {
      dispatch(getAllUsersAction());
    },
    getAllAuditlogs:(projectId,username)=>{
       dispatch(getAllAuditLogs(projectId,username))
    },
    getCategoryTreeAction: (projectId) => {
      dispatch(getCategoryTreeAction(projectId))
    },
    downloadAuditLogsAction:(projectId)=>{
      dispatch(downloadAuditLogsAction(projectId))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
