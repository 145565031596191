import {
  Avatar,
  Box,
  Button,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@material-ui/core";
import {
  AddCircle,
  Android,
  Apple,
  DesktopMac,
  DesktopWindows,
  MoreVert,
  Settings,
  Tablet,
  TvSharp,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import Card from "components/Card/Card";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Live_icon from "../../../assets/img/icons/Group_live_icon.svg";
import style from "../../../assets/jss/material-dashboard-react/views/projectStyle";
import platformStyle from "../../../assets/jss/material-dashboard-react/views/platformStyle";
import { getAllProjectAction, addProjectNameInNavbarAction, getCMSConfig } from "../actions/projectActions";
import { useHistory } from "react-router-dom";

import { platformIcons } from "components/PlatformList";
import Setting from "../../../assets/img/icons/svg/Ic_Settings Edit Delete.svg";
import Edit from "../../../assets/img/icons/svg/Ic_Edit.svg";
import Delete from "../../../assets/img/icons/svg/Ic_Delete.svg";
import LoadingBar from "react-top-loading-bar";
import AddProject from "./addProject";

import Addicon from "../../../assets/img/icons/svg/Ic_add.svg";
import Snackbar from "../../../components/Snackbar/CustomSnackbar";
import { isDev } from "utils/getEnvironment";
import { clearScreenWidgetDetailsArray } from "screens/PlatformManager/actions/PlatformAction";
import { getAllPlatformAction } from "screens/PlatformManager/actions/PlatformAction";

const useStyle = makeStyles(style);
const usePlaformStyles = makeStyles(platformStyle)

const Platforms = {
  WEB_DES: "Web Desktop",
  WEB_MOB: "Web Mobile",
  AND_MOB: "Android Mobile",
  AND_TAB: "Android Tablet",
  IOS_MOB: "IOS Mobile",
  IOS_TAB: "IOS Tablet",
  AND_TV: "Android TV",
  SAMSUNG_SMART_TV: "Samsung TV",
  LG_SMART_TV: "LG Smart TV",
  APPLE_TV: "Apple TV",
  VIDAA_TV: "Vidaa TV",
  DONGLE:"DONGLE",
  FIRESTICK:"FIRESTICK",
  STB:"Setup Box",
  SKYGLASS_TV:"Sky glass TV"
};

const Projects = (props) => {
  const [Projectopen, setProjectsopen] = useState(false);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [Open, setOpen] = useState(false);
  const [projectDetails, setProjectDetails] = useState("");
  const [progress, setProgress] = useState(0);
  const [projectCreated, setProjectCreated] = useState(false);
  const [projectUpdated, setProjectUpdated] = useState(false);
  const [accessSnack, setAccessSnack] = useState(false);
  const [accessSnackCopy, setAccessSnackCopy] = useState("");

  const [screenDetails, setScreenDetails] = useState("");
  const [openScreensPopup, setOpenScreensPopup] = useState(false);
  let PlatformArr =[]
  console.log(props.allPlatforms,"platforms")
  if(props?.platforms && props.projectDetails){
    PlatformArr = props.projectDetails?.platformList?.map((platform) =>
      props?.platforms?.find((platObj) => platObj?._id?.shortName === platform)
    );
  }
  const handleScreen = () => {
    console.log(projectDetails.p_id,"projectDetails.p_id")
    props.clearScreenWidgetDetailsArray();
    history.push({
      pathname: "/admin/projectsplateformmanager/screens",
      state: {
        projectId: projectDetails.p_id,
        platform: screenDetails._id.shortName,
        platformName: screenDetails._id.name,
      },
    });
    localStorage.setItem("platform", JSON.stringify(screenDetails._id));
  };

  const handleaddProject = () => {
    props.accessLevel !== "user"
      ? history.push({ pathname: "/admin/projects/addprojects" })
      : [setAccessSnackCopy("You do not have access to add new projects."), setAccessSnack(true)];
  };

  const createMessages = {
    success: `${props.createProject?.data?.commonName} project has been created.`,
    warning: ``,
    error: `There seems to be an error creating ${props.createProject?.data?.commonName} project. Please try again.`,
  };

  const editMessages = {
    success: `${props.updateProject?.data?.commonName} project has been updated.`,
    warning: ``,
    error: `There seems to be an error updating ${props.updateProject?.data?.commonName} project. Please try again.`,
  };

  useEffect(() => {
    [props.getAllProjectAction(),props.getAllPlatformAction(props?.projects)]
    
    //setOpen(false);
  }, [props.projectReset]);
  

  useEffect(() => {
    const project = props.projects?.[0];

    if (project && !props.projectDetails?.name)
      props.addProjectNameInNavbarAction({
        name: project.commonName,
        icon: project.projectIcon["1x1"],
        p_id: project.p_id,
        platformList: project.platforms,
        appConfig: project.config,
        isAll:true,
        splashScreens:project.splash
      });
    
  }, [props.projects]);

  const handleAppScreen = () => {    
    history.push({
      pathname: "/admin/projectsplateformmanager/appscreens",
      state: {
        projectId: projectDetails.p_id,
        platform: screenDetails._id.shortName,
        platformName: screenDetails._id.name,
      },
    });
  };

  const classes = useStyle();

  const platformClasses = usePlaformStyles();
  const handleClick = (_screenDetails,_projectDetails) => (event) => {
     event.stopPropagation()
     if(projectDetails?.p_id !== _projectDetails?.p_id){
       setProjectDetails(_projectDetails)
       props.addProjectNameInNavbarAction({
         name: _projectDetails.commonName,
         icon: _projectDetails.projectIcon["1x1"],
         p_id: _projectDetails.p_id,
         platformList: _projectDetails.platforms,
         appConfig:_projectDetails.config,
         splashScreens:_projectDetails.splash
       });
     }
     setScreenDetails(_screenDetails);
     setAnchorEl(event.currentTarget);
     setOpenScreensPopup(true);
   };

  const handleOpen = () => {
    return <Redirect to="/projectsplateformmanager" />;
  };

  const handlepopup = (x) => (event) => {
    setProjectDetails(x);
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (Open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleEdit = () => {
    props.accessLevel !== "user"
      ? [
          props.getCMSConfig(projectDetails.p_id),
          history.push({
            pathname: "/admin/projects/edit",
            state: { data: projectDetails },
          }),
        ]
      : [setAccessSnackCopy("You do not have access to edit projects."), setAccessSnack(true)];
  };

  const handleDelete = () => {
    props.accessLevel !== "user"
      ? null
      : [setAccessSnackCopy("You do not have access to delete projects."), setAccessSnack(true)];
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const open2 = Boolean(anchorEl);
  const id = open2 ? "simple-popover" : undefined;

  useEffect(() => {
    if (!progress) setProgress(progress + 20);

    if (props?.projects?.length) setProgress(100);

    let timer = setTimeout(() => {
      setProgress(100);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [props?.projects]);

  useEffect(() => {
    props.createProject?.hzStatus === 200 && props.createReset ? setProjectCreated(true) : null;
  }, [props.createProject]);

  useEffect(() => {
    props.updateProject?.hzStatus === 200 && props.updateReset ? setProjectUpdated(true) : null;
  }, [props.updateProject]);

console.log(props.allPlatforms,"props.allPlatforms")

  if (Projectopen) {
    <AddProject />;
  } else {
    return (
      <Box>
        <div>
          <LoadingBar
            color="#885CF7"
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
            shadow={true}
            height={3}
          />
        </div>
        <Snackbar
          open={projectCreated}
          severity={props.createProject?.hzStatus === 200 ? "success" : "error"}
          message={props.createProject?.hzStatus === 200 ? createMessages.success : createMessages.error}
          setOpen={() => setProjectCreated(false)}
        />
        <Snackbar
          open={projectUpdated}
          severity={props.updateProject?.hzStatus === 200 ? "success" : "error"}
          message={props.updateProject?.hzStatus === 200 ? editMessages.success : editMessages.error}
          setOpen={() => {
            setTimeout(setProjectUpdated(false),1000)
          }}
        />
        <Snackbar
          open={accessSnack}
          severity={"warning"}
          message={accessSnackCopy}
          setOpen={() => setAccessSnack(false)}
        />
        {props?.projects?.length>0 && Object.keys(props.allPlatforms).length>0 && <>
          <Grid container>
          <Grid item xs={6} md={6} lg={6}>
            <Box px={2} pb={2}>
              <Typography className={classes.heading}>Projects</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Box display="flex" justifyContent="right">
              <Button className={classes.Button} onClick={handleaddProject}>
                <img src={Addicon} style={{ paddingRight: "0.75rem" }} />
                Add New Project
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Grid container>
            {props?.projects?.map((x, i) => {
              return (
                <Grid  onClick={()=>{
                  if(projectDetails?.p_id !== x?.p_id){
                    setProjectDetails(x)
                    props.addProjectNameInNavbarAction({
                      name: x.commonName,
                      icon: x.projectIcon["1x1"],
                      p_id: x.p_id,
                      platformList: x.platforms,
                      appConfig:x.config,
                      splashScreens:x.splash
                    });
                  }
                }} item xs={12} md={6} lg={4} key={i}>
                  <Box padding="12px">
                    <Card className={classes.card} key={i} aria-describedby={id}>
                      <Box mx={1} my={1}>
                        <CardHeader
                          className={classes.cardHeader}
                          avatar={<Avatar src={x.projectIcon["1x1"]}></Avatar>}
                          action={
                            <IconButton onClick={handlepopup(x)}>
                              <MoreVert style={{ color: "#0192D4" }} />
                            </IconButton>
                          }
                          title={x.commonName}
                          subheader={x.description}
                          subheaderTypographyProps={{ varient: "subtitle" }}
                          classes={{
                            title: classes.cardHeader,
                            subheader: classes.subheader,
                          }}
                        />
                      </Box>
                      <Divider variant="middle" className={classes.divider} />
                      <Box
                        py={2}
                        paddingTop="16px"
                        display="flex"
                        alignContent="flex-start"
                        alignItems="center"
                        flexWrap="wrap"
                        justifyContent="sapce-evenly"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // history.push({
                          //   pathname: "/admin/projectsplateformmanager",
                          //   state: x.p_id,
                          // });
                          localStorage.setItem("projectname",x.commonName)
                          localStorage.setItem("projectIcon",x.projectIcon)
                          
                         
                        
                        }}
                      >
                  {props.allPlatforms[x.p_id]?.map((platform, index) => {
                          return (
                              <Box
                              paddingLeft="28px"
                              paddingRight="12px"
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-evenly"
                              alignItems="center"
                              key={index}
                              onClick={handleClick(platform,x)}
                            >
                              <Box paddingBottom="12px">
                                <Avatar className={classes.avatar} variant="rounded">
                                  <img
                                    src={`${process.env.REACT_APP_BASE_ASSETS_URL}${
                                      isDev() ? "" : "/assets"
                                    }/platformIcons/${platform?._id?.shortName}.svg`}
                                    className={classes.icon}
                                  />
                                </Avatar>
                              </Box>
                              <Box paddingBottom="12px">
                              <Typography className={classes.typography}>{Platforms[platform?._id?.shortName]}</Typography>
                              </Box>
                            </Box>                                                          
                          );
                        })}
                      </Box>
                    </Card>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        </>}
       
        {openScreensPopup ? (
        <ClickAwayListener onClickAway={handleClose}>
          <Popover
            open={open2}
            anchorEl={anchorEl}
            id={id}
            className={platformClasses.popover}
            classes={{ paper: platformClasses.popoverContent }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              style: {
                background: "transparent",
                width: "273px",
                display: "flex",
                justifyContent: "flex-end",
                boxShadow: "none",
              },
            }}
            onClose={handleClose}
          >
            <Box className={platformClasses.popperContainter}>
              <Box className={platformClasses.popper}>
                <Box paddingBottom="16px" paddingTop="16px" paddingLeft="16px" paddingRight="16px">
                  <Box>
                    <Typography className={platformClasses.popTypo}>{screenDetails._id.name}</Typography>
                  </Box>
           
                 
                  <Box style={{marginTop:"10px"}}>
                  <Box display="flex" flexDirection="row" borderbottomalign="2px">
                              <Box paddingTop="10px">
                                <Typography className={classes.totalScreen}>{screenDetails.liveScreens.length}</Typography>
                              </Box>
                              <Box paddingLeft="19px">
                                <Typography className={classes.screenTypo}>
                                {screenDetails.liveScreens.length === 1 ? "Live Screen" : "Live Screens"}
                                </Typography>
                              </Box>
                   </Box>
                   <Box display="flex" flexDirection="row" borderbottomalign="2px">
                              <Box paddingTop="10px">
                                <Typography className={classes.totalScreen}>{screenDetails?.unpublishedScreens.length}</Typography>
                              </Box>
                              <Box paddingLeft="19px">
                                <Typography className={classes.screenTypo}>
                                {screenDetails?.unpublishedScreens?.length === 1 ? "Unpublished Screen" : "Unpublished Screens"}
                                </Typography>
                              </Box>
                   </Box>
                 
                  <Box display="flex" flexDirection="row" borderbottomalign="2px">
                              <Box paddingTop="10px">
                                <Typography className={classes.totalScreen}>{screenDetails?.draftScreens.length}</Typography>
                              </Box>
                              <Box paddingLeft="19px">
                                <Typography className={classes.screenTypo}>
                                {screenDetails?.draftScreens?.length === 1 ? "Draft Screen" : "Draft Screens"}
                                </Typography>
                              </Box>
                   </Box>
                  </Box>
                  <Divider />
                  <Box className={platformClasses.screenTypoContainer}>
                    {screenDetails.liveScreens
                      .sort((a, b) => {
                        return a.displayOrder - b.displayOrder;
                      })
                      .map((s_name, i) => {
                        return (
                          <Box
                            paddingTop="12px"
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-evenly"
                            key={i}
                          >
                            <Typography className={platformClasses.allScreenTypo}>
                              Screen - {i + 1} : {s_name?.name}
                            </Typography>
                            <Box>
                              <img src={Live_icon} />
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                  <Box paddingTop="24px" paddingRight="8px">
                    <Button className={platformClasses.popButton} onClick={handleScreen}>
                      <Typography className={platformClasses.popbtnTypo}>View all Screens</Typography>
                    </Button>
                  </Box>
                  <Box paddingTop="24px" paddingRight="8px">
                    <Button className={platformClasses.popButton} onClick={handleAppScreen}>
                      <Typography className={platformClasses.popbtnTypo}>View App Screens</Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Popover>
        </ClickAwayListener>
      ) : (
        ""
      )}
        {Open ? (
          <Popover
            open={open2}
            anchorEl={anchorEl}
            id={id}
            onClose={handleClose}
            classes={{ paper: classes.popper }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box paddingTop="14px" paddingBottom="14px">
              <MenuList>
                <MenuItem onClick={handleEdit}>
                  <Box paddingLeft="14px">
                    <img src={Edit} />
                  </Box>
                  <Box paddingLeft="14px">
                    <Typography className={classes.popTypo}>Edit</Typography>
                  </Box>
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                  <Box paddingLeft="14px">
                    <img src={Delete} />
                  </Box>
                  <Box paddingLeft="14px">
                    <Typography className={classes.popTypo}>Delete</Typography>
                  </Box>
                </MenuItem>
              </MenuList>
            </Box>
          </Popover>
        ) : (
          ""
        )}
      </Box>
    );
  }
};

const mapStateToProps = (State) => {
  return {
    projects: State.ProjectReducer.allProjects,
    projectReset: State.ProjectReducer.projectReset,
    createProject: State.ProjectReducer.createdData,
    updateProject: State.ProjectReducer.updatedData,
    createReset: State.ProjectReducer.createReset,
    updateReset: State.ProjectReducer.updateReset,
    projectDetails: State.ProjectReducer.projectDetails,
    accessLevel: State.loginReducer.authTokens?.accessLevel,
    allPlatforms:State.screenVisualizationReducer.allPlatforms
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllProjectAction: () => {
      dispatch(getAllProjectAction());
    },
    addProjectNameInNavbarAction: (details) => {
      dispatch(addProjectNameInNavbarAction(details));
    },
    getCMSConfig: (pid) => {
      dispatch(getCMSConfig(pid));
    },
    getAllPlatformAction: (projectIds) => {
      dispatch(getAllPlatformAction(projectIds));
    },
    clearScreenWidgetDetailsArray: () => {
      dispatch(clearScreenWidgetDetailsArray());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
