import React from "react";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button,
  Paper,
  Switch,
  OutlinedInput,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import { AntSwitch } from "components/Switch/AntSwitch";

import styles from "assets/jss/material-dashboard-react/views/widgetMangerStyle";

const useStyles = makeStyles(styles);

const EnableSchedule = (props) => {
  const classes = useStyles();
const[start,setStart]=React.useState()
 const[end,setEnd]=React.useState()
 function convert(str) {
  var date = new Date(str*1000),
  year = date.getFullYear(),
  month = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2),
  hours = ("0" + date.getHours()).slice(-2),
  minutes = ("0" + date.getMinutes()).slice(-2),
  seconds = ("0" + date.getSeconds()).slice(-2);
  
return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}
 React.useEffect(()=>{
   if(props.widgetCommonDetails?.enableSchedule){
     setStart(convert(props.widgetCommonDetails?.startDate))
   setEnd(convert(props.widgetCommonDetails?.endDate))
   }
 },[])
  //OnChange Functions
  const enableSchedule = (event) => {
    props.setWidgetCommonDetails({
      ...props.widgetCommonDetails,
      enableSchedule: event.target.checked,
    });
  };

  

  const getStartAndEndDate = (event) => {
    if (event.target.name === "Start") {
      const date = new Date(event.target.value);
      // const timestampInMs = date.getTime()

      const unixTimestamp = Math.floor(date.getTime() / 1000);
     // console.log("Date Time",unixTimestamp,event.target.value,date)
     setStart(event.target.value)
      props.setWidgetCommonDetails({
        ...props.widgetCommonDetails,
        startDate: unixTimestamp,
      });
    } else if (event.target.name === "End") {
      const date = new Date(event.target.value);
      //  const timestampInMs = date.getTime()
setEnd(event.target.value)
      const unixTimestamp = Math.floor(date.getTime() / 1000);
      props.setWidgetCommonDetails({
        ...props.widgetCommonDetails,
        endDate: unixTimestamp,
      });
    }
  };

  return (
    <>
      {}
      <div
        className={classes.widgetDetailContainer}
        style={{
          width: "385px",
        }}
      >
        {" "}
        <Typography style={{}}>Enable Schedule</Typography>{" "}
        <AntSwitch key={props?.widgetCommonDetails?.enableSchedule+"Enable Schedule"} name="checkedC" onChange={enableSchedule} defaultChecked={(props?.widgetCommonDetails?.enableSchedule)} />
      </div>
      <div>
        <div className={classes.widgetDetailContainer}>
          {" "}
          <Box
            style={{
              width: "410px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography style={{}}>Start Date</Typography>{" "}
              <Box className={classes.validateBox}>
                <TextField
                  name="Start"
                value={start}

                  disabled={!props.widgetCommonDetails.enableSchedule}
                  type="datetime-local"
                  size="medium"
                  variant="outlined"
                  className={classes.textField}
                  inputProps={{min: new Date().toISOString().split('T')[0] + 'T00:00'}}
                  style={{ width: "190px" }}
                  onChange={getStartAndEndDate}
                />
                {props.openMandatoryWarn ? (
                  props.widgetCommonDetails.enableSchedule ? (
                    props.widgetCommonDetails.startDate ? null : (
                      <span className={classes.validationText}>
                        * Mandatory Field
                      </span>
                    )
                  ) : null
                ) : null}{" "}
              </Box>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <Typography style={{}}>End Date</Typography>{" "}
              <Box className={classes.validateBox}>
                <TextField
                  name="End"
               value={ end}
                  type="datetime-local"
                  disabled={!props.widgetCommonDetails.enableSchedule}
                  size="medium"
                  variant="outlined"
                  className={classes.textField}
                  inputProps={{min: new Date().toISOString().split('T')[0] + 'T00:00'}}
                  style={{ width: "190px" }}
                  onChange={getStartAndEndDate}
                />
                {props.openMandatoryWarn ? (
                  props.widgetCommonDetails.enableSchedule ? (
                    props.widgetCommonDetails.endDate ? null : (
                      <span className={classes.validationText}>
                        * Mandatory Field
                      </span>
                    )
                  ) : null
                ) : null}
              </Box>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
};

export default EnableSchedule;
