import {
  GET_ASSET_BY_ID,
  GET_CATEGORY_TREE_DATA,
  GET_CATEGORY_TREE_VISUALIZATION,
  GET_PLAYLIST_BY_ID,
  SUCCESSFULLY_GET_ASSET_DETAILS,
  SUCCESSFULLY_GET_PLAYLIST_BY_ID,
  GET_CATEGORY_TREE_VISUALIZATION_ERROR_MESSAGE,
   CLEAR_CACHE_ACTION} from "../actionTypes";

export const getAssetByIdAction = (assetID, projectID) => {
  return { type: GET_ASSET_BY_ID, payload: assetID, projectID: projectID };
};

export const getAseetDetailsAction = (assetDetails) => {
  return { type: SUCCESSFULLY_GET_ASSET_DETAILS, payload: assetDetails };
};

export const getCategoryTreeVisualizationAction = (data) => {
  return { type: GET_CATEGORY_TREE_VISUALIZATION, payload: data };
};

export const getCategoryTreeVisualizationData = (alldata) => {
  return { type: GET_CATEGORY_TREE_DATA, payload: alldata };
};

export const getPlaylistByIdAction = (playlistId, projectId) => {
  return {
    type: GET_PLAYLIST_BY_ID,
    payload: playlistId,
    projectId: projectId,
  };
};

export const succcessfullyGetPlaylistById = (playlistData) => {
  return { type: SUCCESSFULLY_GET_PLAYLIST_BY_ID, payload: playlistData };
};

export const getCategoryTreeVisualizationErrorMessage = (errorMessage) => {
  return {
    type: GET_CATEGORY_TREE_VISUALIZATION_ERROR_MESSAGE,
    payload: errorMessage,
  };
};


//Clear Cache Action

export const clearCacheAction=(pid)=>{
  return{type:CLEAR_CACHE_ACTION,payload:pid}
}
