import { Box, Button, makeStyles, Tab, Tabs, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import KalturaExplorer from './KalthraExplorer';
import AssestExplorer from './AssestsExplorer';
import Snackbar from "components/Snackbar/CustomSnackbar";

import { getCategoryTreeVisualizationAction ,clearCacheAction} from '../actions';
import { getProjectByIdAction, } from "screens/WidgetManager/actions";

import Styles from '../../../assets/jss/material-dashboard-react/views/contentExplorerStyle';
import { connect } from 'react-redux';

const useStyle  = makeStyles(Styles);

const ContactExplorer =(props)=>{
  const [value,setValue] = useState(0)
  const [cacheRefreshed,setCacheRefreshCache]=useState(false) 

  const classes = useStyle();

 

  useEffect(()=>{
    props.getCategoryTreeVisualizationAction(props.projectDetails.p_id);
    props.getProjectByIdAction(props.projectDetails.p_id)
  },[]);


  const handleChange = (event,value)=>{
    setValue(value)
  }

  const refreshCacheClick=async()=>{
 const result= await props.clearCacheAction(props?.projectDetails?.p_id)
    
  }

  return(
    <Box>
      <Box>
         <Box>
           <Typography className={classes.heading}>Content Explorer</Typography>
         </Box>
         <Box paddingTop='16px'>
           <Tabs value={value} TabIndicatorProps={{ style: { background: "#0192D4",height:'3px' } }} onChange={handleChange}>
              <Tab value={0} className={classes.unSelectedTab} classes={{selected:classes.tab}} label='Content explorer'/>
              <Tab value={1} className={classes.unSelectedTab} classes={{selected:classes.tab}} label='CMS Visualization'/>
              <Button className={classes.refreshButton} onClick={refreshCacheClick}>  <Typography className={classes.ButtonText} >Refresh CMS Cache</Typography></Button>

           </Tabs>
            {value == 0 ? <AssestExplorer/> : ""}
            {value == 1 ? <KalturaExplorer data={props?.treeData}
                                           imageRatio={props.projectAspectRatio}
            />:""}
         </Box>
      </Box>
   { /*  <Snackbar
                open={cacheRefreshed}
                severity={
                  // props.createScreen?.data?.acknowledged
                  //   ? "warning"
                  //   : props.createScreen?.hzStatus === 200
                  //   ? "success"
                     "error"
                }
                message={
                "Messge"
                }
               setOpen={()=>{setCacheRefreshCache(false)}}
              />*/}
    </Box>
  )
}

const mapStateToProps = (State)=>{
  return {
      treeData:State.ContentExplorerReducer?.allCategories,
      projectDetails:State.ProjectReducer.projectDetails,
      projectAspectRatio:State.PlayListReducer.projectAspectRatio,
      visualizationErrorMessage:State.ContentExplorerReducer.visualizationErrorMessage
  }
}

const mapDispatchToProps = (dispatch)=>{
  return{
      getCategoryTreeVisualizationAction:(data)=>{
          dispatch(getCategoryTreeVisualizationAction(data));
      },
      getProjectByIdAction:(projectId)=>{
        dispatch(getProjectByIdAction(projectId))
      },
      clearCacheAction:(pid)=>{
        dispatch(clearCacheAction(pid))
      }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ContactExplorer)