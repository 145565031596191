const Screen_Widgets = {
  paper: {
    width: "412px",
    maxHeight: "763px",
    backgroundColor: "#efecfb",
    boxShadow: "none",
    "backdrop-filter": "blur(26.1418px)",
    "border-radius": "24px",
  },
  paper3: {
    // width: "460px",
    maxHeight: "763px",
    background: "rgba(255, 255, 255, 0.2)",
    "backdrop-filter": "blur(26.1418px)",
    "border-radius": "24px",
  },
  tab: {
    width: "208px",
    maxWidth: "100%",
    height: "56px",
    background: "#FFFFFF",
    "border-radius": "12px 12px 0px 0px",
    color: "#141D46",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22.4px",
    fontFamily: "Open Sans",
  },
  apptab: {
    width: "100%",
    height: "56px",
    background: "#FFFFFF",
    "border-radius": "12px 12px 0px 0px",
    color: "#141D46",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22.4px",
    fontFamily: "Open Sans",
    textTransform: "none",
  },
  unSelectedAppTab: {
    maxWidth: "100%",
    height: "56px",
    color: "#141D46",
    fontSize: "16px",
    lineHeight: "22.4px",
    fontFamily: "Open Sans",
    textTransform: "none",
  },
  unSelectedTab: {
    width: "206px",
    maxWidth: "100%",
    height: "56px",
    color: "#141D46",
    fontSize: "16px",
    lineHeight: "22.4px",
    fontFamily: "Open Sans",
    textTransform: "none",
    borderRadius: "24px 24px 0 0",
  },
  paper2: {
    width: "412px",
    // height: '200px',
    left: "980px",
    top: "630px",
    background: "#1D2F46",
    "box-shadow": "13.0709px 13.0709px 104.567px rgba(63, 39, 102, 0.1)",
    "backdrop-filter": "blur(26.1418px)",
    "border-radius": "24px",
  },
  paper4: {
    // width: "460px",
    // height: '200px',
    left: "980px",
    top: "630px",
    background: "#1D2F46",
    "box-shadow": "13.0709px 13.0709px 104.567px rgba(63, 39, 102, 0.1)",
    "backdrop-filter": "blur(26.1418px)",
    "border-radius": "24px",
  },
  imgUpload: {
    width: "max-content",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.75,
      "& $uploadText": {
        opacity: 1,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, 10%)",
      },
      "& $splashInfotext":{
        opacity: 1,
      }
    },
  },
  introUploadContainer: {
    display: "flex",
    cursor: "pointer",
  },
  splashImage:{
    "&:hover": {
      opacity: 0.75,
    },
  },
  introUpload: {
    width: "max-content",
    overflowX: "auto",
    overscrollBehaviorX: "contain",
    "-webkit-overflow-scrolling": "touch",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&:hover": {
      "& $deleteImg": {
        opacity: 1,
      },
    },
  },
  introUploadSingle: {
    position: "relative",
    padding: "1rem",
    marginTop: "6px",
    "&:hover": {
      opacity: 0.75,
      "& $deleteImg": {
        opacity: 1,
      },
    },
    display:"flex",
    flexDirection:"column"
  },
  uploadText: {
    display: "flex",
    position: "absolute",
    opacity: 0,
    color: "#FFF",
    textShadow: "0px 0px 12px #000000",
  },
  splashInfotext:{
    width: "max-content",
    color:"red",
    fontSize:"10px",
    opacity:0
  },
  uploadIntro: {
    display: "flex",
    position: "absolute",
    opacity: 0,
  },
  introImg: {
    display: "flex",
    cursor: "grab",
    "&:hover": {
      cursor: "grab",
      opacity: 0.75,
    },
    "&:active:hover": {
      cursor: "grabbing",
    },
    maxWidth: "180px",
    maxHeight: "200px",
    borderRadius: "4px",
  },
  introContainer: {
    // gap: "1rem",
    display: "flex",
    "-webkit-overflow-scrolling": "touch",
  },
  imgContainer: {
    // opacity: 0.75,
    "&:hover": {
      "& $deleteImgs": {
        opacity: 1,
      },
    },
  },
  screenCard: {
    maxHeight: "500px",
    background: "rgba(255, 255, 255, 0.6)",
    "border-radius": "16px",
    boxShadow: "none",
    margin: "1rem",
  },
  screenName: {
    // width: "200px",
    height: "22px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "600",
    "font-size": "16px",
    "line-height": "140%",
    // overflow: "hidden",
    // "text-overflow": "ellipsis",
    // "white-space": "nowrap",
  },
  introScreenName: {
    width: "max-content",
    height: "22px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "600",
    "font-size": "16px",
    "line-height": "140%",
    overflow: "hidden",
    "text-overflow": "ellipsis",
    "white-space": "nowrap",
    color: "#FFF",
    textShadow: "0px 0px 12px #000000",
  },
  Exports: {
    width: "330px",
    height: "22px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": 600,
    "font-size": "16px",
    "line-height": "140%",
    color: "#FFFFFF",
    textTransform: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  divider: {
    margin: "0rem 1.5rem",
    "box-sizing": "border-box",
    bottom: "224px",
    background: "#344358",
  },
  saveButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "cneter",
    height: "48px",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    boxShadow: "0px 2px 20px rgba(0, 60, 118, 0.4)",
    "border-radius": "33px",
  },
  saveButtonDisabled: {
    display: "flex",
    justifyContent: "center",
    alignItems: "cneter",
    height: "48px",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    boxShadow: "0px 2px 20px rgba(0, 60, 118, 0.4)",
    "border-radius": "33px",
    pointerEvents: "none",
    filter: "contrast(30%)",
  },
  ButtonText: {
    height: "22px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "700",
    "font-size": "16px",
    "line-height": "140%",
    "text-align": "center",
    color: "#FFFFFF",
    textTransform: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  draftTypo: {
    height: "22px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "700",
    "font-size": "16px",
    "line-height": "140%",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    "background-clip": "text",
    "text-fill-color": "transparent",
    textTransform: "none",
    filter: "contrast(110%)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  draftTypoDisabled: {
    height: "22px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "700",
    "font-size": "16px",
    "line-height": "140%",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    "background-clip": "text",
    "text-fill-color": "transparent",
    textTransform: "none",
    pointerEvents: "none",
    filter: "contrast(15%)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  dialog: {
    width: "537px",
    height: "auto",
    background: "#FFFFFF",
    "border-radius": "16px",
    boxShadow: "0px 8px 20px rgba(63, 39, 102, 0.12)",
    backdropFilter: "blur(10px)",
  },
  ExportTypo: {
    width: "400px",
    height: "33px",
    left: "477px",
    top: "269px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "700",
    "font-size": "24px",
    "line-height": "33px",
    color: "#141D46",
    pointerEvents: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  ExportHelpText: {
    width: "400px",
    left: "477px",
    top: "269px",
    "font-weight": "400",
    "line-height": "33px",
    color: "#141D46",
    pointerEvents: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginLeft:"24px"
  },
  cardTypo: {
    height: "fit-content",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "600",
    "font-size": "12px",
    "line-height": "16px",
    "text-align": "center",
    color: "#000000",
    padding: "0 4px 12px",
  },
  selectedCardTypo: {
    height: "fit-content",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "600",
    "font-size": "12px",
    "line-height": "16px",
    "text-align": "center",
    color: "#0192D4",
    padding: "0 4px 12px",
  },
  selectedCard: {
    "box-sizing": "border-box",
    width: "147px",
    minHeight: "103px",
    background: "#F7FCFF",
    "backdrop-filter": "blur(20px)",
    "border-radius": "12px",
    border: "2px solid  rgba(51,159,237)",
    cursor: "pointer",
    boxShadow: "none",
  },
  exportPltCard: {
    "box-sizing": "border-box",
    width: "147px",
    minHeight: "103px",
    background: "#FFFFFF",
    "border-radius": "12px",
    border: "1px solid rgba(199, 199, 199, 0.3)",
    backdropFilter: "blur(10px)",
    cursor: "pointer",
    boxShadow: "none",
  },
  dialogWarper: {
    paddingBottom: "20px",
    display: "flex",
    alignContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    // justifyContent: "space-evenly",
  },
  dialogGrid: {
    marginTop: "20px",
    maxHeight: "390px",
    overflow: "auto",
  },
  cancelButton: {
    width: "180px",
    height: "48px",
    boxSizing: "border-box",
    borderRadius: "33px",
    background:
      "linear-gradient(#FFF, #FFF) padding-box, linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) border-box",
    border: "1px solid transparent",
    boxShadow: "none",
  },
  cancelTypo: {
    width: "311px",
    height: "22px",
    left: "calc(50% - 311px/2 - 0.5px)",
    top: "calc(50% - 22px/2)",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "700",
    "font-size": "16px",
    "line-height": "140%",
    "text-align": "center",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    "background-clip": "text",
    "text-fill-color": "transparent",
    textTransform: "none",
  },
  content: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  dialogButton: {
    position: "absolute",
    top: "21px",
    right: "21px",
    cursor: "pointer",
    minWidth: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: "50%",
  },
  dialogDesc: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#424242",
  },
  dialogDescFocus: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#141D46",
  },
  action: {
    display: "flex",
    justifyContent: "center",
  },
  exportButton: {
    width: "95%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "20px",
    cursor: "pointer",
    borderRadius: "24px",
  },
  addnew: {
    width: "53px",
    height: "16px",
    "font-family": "Open Sans",
    "font-style": "normal",
    "font-weight": "600",
    "font-size": "11px",
    "line-height": "16px",
    "text-align": "center",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    "background-clip": "text",
    "text-fill-color": "transparent",
    "& $splashInfotext": {
      opacity: 1,
    },
  },
  deleteContainer: {
    padding: "4px",
  },
  deleteImg: {
    width: "18px",
    opacity: 0,
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.2)",
      transition: "all 0.2s ease-in-out",
    },
    margin:"auto",
  },
  SplashdeleteImg: {
    width: "18px",
    cursor: "pointer",
    margin:"auto"
  },
  deleteImgs: {
    position: "absolute",
    width: "18px",
    top: "-5%",
    right: 0,
    opacity: 0,
    cursor: "pointer",
    padding: "6px",
  },
  chipTabBox: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    margin: "18px 0",
    right: "114px",
    alignItems: "end",
  },
  chipIndicator: {
    width: "3px",
    height: "49px",
    backgroundColor: "rgb(1, 146, 212)",
    right: "-3px",
    position: "relative",
    zIndex: 1,
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
};

export default Screen_Widgets;
