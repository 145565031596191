import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import SignInErrorMsg from "components/signIn/SignInErrorMessage";

import { Link } from "react-router-dom";

//import useNavigate from 'react-router-dom';

import avatar from "assets/img/faces/marc.jpg";
import {
  Box,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

import { LoginAction } from "../actions/loginAction";
import { connect } from "react-redux";

import SigninImg from "../../../assets/img/hero-1 (1).jpg";
import Logo from "../../../assets/img/horizonlogo.png";
import Logo2 from "../../../assets/img/HorizonLogo2.svg";
import VivreLogo from "../../../assets/img/vivre-logo.png";

//import styles from '../../../assets/jss/material-dashboard-react/views/dashboardStyle'

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    textAlign: "center",
  },
};

const useStyles = makeStyles(styles);

function Signin(props) {
  const classes = useStyles();
  const [authDetails, setAuthDetails] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => { }, [props.loginDetails.loginSuccesFully]);

  useEffect(() => {
    const isEmailValid = authDetails?.email && authDetails.email.length >= 3 && emailRegex.test(authDetails.email);
    const isPasswordValid = authDetails?.password && authDetails.password.length >= 3;
    setIsButtonDisabled(!(isEmailValid && isPasswordValid));
  }, [authDetails]);

  const removeErrorMsg = (event) => {
    setErrorMessage(false);
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleEmail = (event) => {
    setAuthDetails({ ...authDetails, email: event.target.value });
    setIsButtonDisabled(
      event.target.value.length < 3 || !authDetails.email || !authDetails.password || !emailRegex.test(event.target.value) || errorMessage
    );
  };

  const handlePassword = (event) => {
    setAuthDetails({ ...authDetails, password: event.target.value });
    setIsButtonDisabled(
      event.target.value.length < 3 || !authDetails.email || !emailRegex.test(authDetails.email) || !authDetails.password || errorMessage
    );
  };

  const handleSubmit = () => {
    if (authDetails?.email && authDetails.password) {
      props.LoginAction(authDetails);
    } else {
      setErrorMessage(true);
    }
  };

  const inputProps = {
    min: 3,
  };

  if (localStorage.getItem("token")) {
    return <Redirect to="/admin" />;
  } else {
    return (
      <Box
        width="100vw"
        height="100%"
        minHeight="100vh"
        sx={{ overflow: "hidden" }}
      >
        <Box width="100%" height="100vh">
          <Grid container spacing={0} style={{ height: "100vh" }}>
            <Grid item xs={11} sm={12} md={6} lg={8} xl={8}>
              <Box height="100vh" pr={10}>
                <img
                  src={`${VivreLogo}`}
                  style={{ width: "100%", height: "100vh" }}
                  alt="Vivre Logo"
                ></img>
              </Box>
              <Typography
                style={{
                  position: "absolute",
                  top: "95%",
                  left: "30%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "0.8rem",
                  fontFamily: "open Sans",
                  fontWeight:600,
                }}
              >
                Powered by Horizon Broadband
              </Typography>
            </Grid>

            <Grid item xs={11} sm={12} md={6} lg={4} xl={4}>
              <Box pt={15} pr={10} display="flex" justifyContent="center">
                <img src={Logo} style={{ width: "70px" }} alt="Logo 2"></img>
              </Box>
              <Box mt={4} pr={10}>
                <Card>
                  <Box
                    variant="gradient"
                    borderRadius="60px"
                    textAlign="center"
                  >
                    <CardHeader color="primary" style={{background:"#3f51b5"}}>
                      <Typography variant="h5" color="initial">
                        User Login
                      </Typography>
                    </CardHeader>
                  </Box>
                  <Box pt={4} pb={3} px={3} mt={3}>
                    <Box component="form" role="form">
                      {errorMessage ? (
                        <SignInErrorMsg errmsg="Please enter the credentials" />
                      ) : null}
                      <Box mb={2}>
                        <TextField
                          name="Email"
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="Email"
                          onFocus={removeErrorMsg}
                          onChange={handleEmail}
                          type="email"
                          required
                          inputProps={inputProps}
                          helperText="Please enter the email. Minimum 3 characters required."
                        />
                      </Box>
                      <Box mb={2}>
                        <TextField
                          name="Password"
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="Password"
                          type="password"
                          onFocus={removeErrorMsg}
                          onChange={handlePassword}
                          required
                          inputProps={inputProps}
                          helperText="Please enter the password. Minimum 3 characters"
                        />
                      </Box>
                      <Box mt={4} mb={1}>
                        <Button
                          color="primary"
                          fullWidth
                          onClick={handleSubmit}
                          disabled={isButtonDisabled}
                          style={{ textTransform: 'none' }}
                          
                        >
                           Login
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Box>
              <Box pt={25}>
                <Typography
                  style={{ fontSize: "0.8rem", fontFamily: "open Sans" }}
                >
                  &copy; {1900 + new Date().getYear()}{" "}All rights reserved Horizon Broadband Pvt Ltd.
                </Typography>
                {/* <div style={{float:"right",marginRight:'30px'}}>
                    <Typography  style={{ fontSize: "0.8rem", fontFamily: "open Sans" }}>
                      <Link to="/tearmsandconditions">
                        Tearms and Conditions
                      </Link>
                    </Typography>
                    <Typography  style={{ fontSize: "0.8rem", fontFamily: "open Sans" }}>
                      <Link to="/privacypolicy">
                         Privacy Policy
                      </Link>
                    </Typography>
                </div> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return { loginDetails: state.loginReducer };
};

const mapDispatchToProps = (dispatch) => {
  return {
    LoginAction: (authDetails) => {
      dispatch(LoginAction(authDetails));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
