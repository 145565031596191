import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont,
  fontColor,
} from "assets/jss/material-dashboard-react.js";

const tableStyle = (theme) => ({
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: primaryColor[0],
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",

    backgroundColor: " rgba(255, 255, 255, 0.6)",

    //borderCollapse: 'collapse'
  },

  tableHeadCell: {
    // borderTopLeftRadius:'24px',
    //borderTopRightRadius:'24px',

    color: fontColor[0],
    ...defaultFont,
    backgroundColor: primaryColor[6],
    "&, &$tableCell": {
      fontSize: "16px",
      fontWeight: 600,
      fontFamily: "Open Sans",
      fontStyle: "normal",
      lineHeight: "22px",
    },
  },
  tableCell: {
  
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "2px 13px",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
    textAlign: "center",
  
  },
  tableBodyCell: {
    border: "none",
    color: fontColor[1],
  },
  tableResponsive: {
    width: "100%",
    marginTop: "34px",
    overflowX: "auto",
    borderRadius: "24px",
    boxShadow: " 0px 8px 20px rgba(63, 39, 102, 0.12)",
  },
  tableHeadRow: {
    // borderTopLeftRadius:'24px',
    //borderTopRightRadius:'24px',
    height: "50px",

    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
    boxShadow: "0px 4px 8px rgba(63, 39, 102, 0.12)",
  },
  tableBodyRow: {
    height: "8px",
 cursor:'pointer',
     color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",

    "&:hover": {
      background: "rgba(255, 255, 255, 0.9)",
      boxShadow: "4px 4px 32px rgba(63, 39, 102, 0.1)",
      backdropFilter: "blur(26.1418px)",
      borderRadius: "15px",
      "& $copyIcon":{
         display:"block",
         
      }
    },
  },
  copyIcon:{
    display:"none",
    position:"absolute",
    right:"15px",
    top:"11px"
  },
  tablePagination: {
    display: "flex",
    justifyContent: "center",
  },
  userTableResponsive:{
    width: "100%",
    //marginTop: theme.spacing(3),
    overflowX: "auto",
    borderRadius: "24px",
    boxShadow: " 0px 8px 20px rgba(63, 39, 102, 0.12)",
  },
   previewButton:{
     
    color: "#FFFFFF",
  //  fontSize: "14px",
    lineHeight: "140%",
    height: "40px",
    width: "100px",
    marginLeft: "20px",
    background: "linear-gradient(135deg, #339FED 0%, #885CF7 65.94%) ",
  
    margin: "0px 10px 0px 0px",
    marginLeft: "-1px",
  },
  dictLabel: {
    position: "relative",
    marginLeft: 0,
    "& .MuiFormControlLabel-label": {
      position: "relative",
      top: "4px",
      fontSize: "12px",
      fontWeight: 800,
      lineHeight: 0.5,
      color: "#000",
    },
  },
  dictSwitch: {
    "& .Mui-checked": {
      color: "#09ce5f",
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#28c86e !important",
    },
  },
});

export default tableStyle;
