import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";

// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { TableFooter,Button } from "@material-ui/core";

//Import delete icon
import deleteBucket from "assets/img/platformScreenVisualization/deleteBucket.svg";

const useStyles = makeStyles(styles);

export default function BlackoutScheduleTable(props) {
  const [expand, setExpand] = React.useState(false);
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor, pagination } = props;

  //Onclick actions
  const expandMoreOrLess = () => {
    setExpand(!expand);
  };

  const selectPlaylistId = (props) => {};
  //style={{maxHeight:'330px',overflow:'auto'}}
  return (
    <div className={classes.tableResponsive}>
      <TableContainer style={{ maxHeight: "500px" }}>
        {" "}
        <Table className={classes.table} stickyHeader>
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow className={classes.tableHeadRow}>
                {tableHead.map((prop, key) => {
                  return (
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                      key={key}
                    >
                      {prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {tableData?.map((prop, key) => {
              return (
                <TableRow key={key} className={classes.tableBodyRow}>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableBodyCell}
                    key={key}
                  >
                    {prop.channelName}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableBodyCell}
                    key={key}
                  >
                    {new Date(prop?.startDate/1000).toLocaleString()}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableBodyCell}
                    key={key}
                  >
                    {new Date(prop?.endDate/1000).toLocaleString()}
                  </TableCell>

                  <TableCell
                    className={classes.tableCell + " " + classes.tableBodyCell}
                    key={key}
                  >
                    {prop?.message}
                  </TableCell>
               {/*   {prop?.content?.type === "image" ? (
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableBodyCell
                      }
                      key={key}
                    >
                      <img
                        src={prop?.blackoutContent}
                        style={{ height: "30px", width: "50px" }}
                      />
                    </TableCell>
                  ) :   <TableCell
                  className={
                    classes.tableCell + " " + classes.tableBodyCell
                  }
                  key={key}
                >
                  <img
                    src={prop?.blackoutContent}
                    style={{ height: "30px", width: "50px" }}
                  />
                </TableCell>} */}
                  <TableCell
                    className={classes.tableCell + " " + classes.tableBodyCell}
                    key={key}
                  >
                    <img
                      src={deleteBucket}
                      style={{
                        height: "20px",
                        width: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => props.deleteSchedule(prop)}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell + " " + classes.tableBodyCell}
                    key={key}
                  >
                  <Button className={classes.previewButton}
                  onClick={()=>{
                    props.setPreviewDetails(prop)
                    props.setPreviewOpen(true)}}
                  >Preview</Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          {/*   <TableFooter>
            <TableRow>
              <TableCell colSpan={12} style={{ paddingBottom: "32px" }} >
                {pagination}
              </TableCell>
            </TableRow>
          </TableFooter> */}
        </Table>
      </TableContainer>
    </div>
  );
}
