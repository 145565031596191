import React from "react";
import { connect } from "react-redux";

import axios from "axios";

//Import history from react router dom
import { useHistory } from "react-router-dom";

//Material ui component
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  Button,
  Paper,
  Switch,
  OutlinedInput,
  CardHeader,
  Card,
  FormHelperText,
  Input,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";

//Material ui custom component
import Primary from "components/Typography/Primary";
import SubHead from "components/Typography/SubHead";
import MandatoryFieldMessage from "../../components/inputFieldsMessage/MandatoryFieldMessage";

//Style File
import style from "assets/jss/material-dashboard-react/views/blackoutScheduleStyle";

//Actions
import {
  scheduleBlackoutAction,
  uploadBlackoutContentS3Action,
} from "../../screens/BlackoutSchedule/actions";
import { process_params } from "express/lib/router";

const useStyle = makeStyles(style);

//Temporary array
const platform = ["Android", "IOS", "Web App"];

const aspectRatio = ["1x1", "16x9", "3x2"];

//Schedule Details Component
const ScheduleBlackoutDetails = ({
  scheduleBlackoutDetails,
  setScheduleBlackoutDetails,
  channelDetails,
  epgDetails,
  projectDetails,
  scheduleBlackoutAction,
  uploadBlackoutContentS3Action,
  epgStartTimeSelected,
  selectedEpgIndex,
  setImageUpload,
  imageUpload,
}) => {
  const classes = useStyle();
  const history = useHistory();

  const [timeShow, setTimeShow] = React.useState("");
  const [endTimeShow, setEndTimeShow] = React.useState("");
  const [blackoutContentRatio, setBlackoutRatio] = React.useState();
  const [blackoutContent, setBlackoutContent] = React.useState({});
  const [mandatoryField, setMandatoryField] = React.useState(false);

  React.useEffect(() => {
    if (selectedEpgIndex !== undefined) {
      let StartShowTime = new Date(scheduleBlackoutDetails?.startDate * 1000);
      let endShowTime = new Date(scheduleBlackoutDetails?.endDate * 1000);
      let startMonth =
        StartShowTime.getMonth() + 1 < 10
          ? `0${StartShowTime.getMonth() + 1}`
          : StartShowTime.getMonth() + 1;
      let startDateFor =
        StartShowTime.getDate() < 10 ? `0${StartShowTime.getDate()}` : StartShowTime.getDate();
      let startHour =
        StartShowTime.getHours() < 10 ? `0${StartShowTime.getHours()}` : StartShowTime.getHours();
      let startMin = StartShowTime.getMinutes() === 0 ? "00" : StartShowTime.getMinutes();

      let endMonth =
        endShowTime.getMonth() + 1 < 10
          ? `0${endShowTime.getMonth() + 1}`
          : endShowTime.getMonth() + 1;
      let endDateFor =
        endShowTime.getDate() < 10 ? `0${endShowTime.getDate()}` : endShowTime.getDate();
      let endHour =
        endShowTime.getHours() < 10 ? `0${endShowTime.getHours()}` : endShowTime.getHours();
      let endMin = endShowTime.getMinutes() === 0 ? "00" : endShowTime.getMinutes();
      let show = `${StartShowTime.getFullYear()}-${startMonth}-${startDateFor}T${startHour}:${startMin}`;
      let endShow = `${endShowTime.getFullYear()}-${endMonth}-${endDateFor}T${endHour}:${endMin}`;

      setTimeShow(show);
      setEndTimeShow(endShow);
    }
  }, [selectedEpgIndex]);

  //Add channel Name
  const getChannelName = (event) => {
    setScheduleBlackoutDetails({
      ...scheduleBlackoutDetails,
      channelName: event.target.value,
    });
  };

  //Add Channem Id
  const getChannelId = () => {
    setScheduleBlackoutDetails({
      ...scheduleBlackoutDetails,
      channelId: event.target.value,
    });
  };

  //Add start and end time for the Blackout for the channel
  const blackoutStartAndEndTime = (event, name) => {
    //consverting normal time into epoch
    let myDate = new Date(event.target.value);
    let epochTime = myDate.getTime() / 1000;
    //Needed to change the time in the epoch time

    if (name === "start") {
      //setEpgStartTimeSelected(false)
      setTimeShow(event.target.value);
      setScheduleBlackoutDetails({
        ...scheduleBlackoutDetails,
        startDate: epochTime,
      });
    } else if (name === "end") {
      setEndTimeShow(event.target.value);
      setScheduleBlackoutDetails({
        ...scheduleBlackoutDetails,
        endDate: epochTime,
      });
    }
  };

  //Add blackout region for the blackout for the content
  const addBlackoutRegion = (event) => {
    setScheduleBlackoutDetails({
      ...scheduleBlackoutDetails,
      blockRegion: event.target.value,
    });
  };

  //Add platforms for the blackout for the channels
  const platfromsSelection = (event, value) => {
    setScheduleBlackoutDetails({
      ...scheduleBlackoutDetails,
      blockDevice: [...value],
    });
  };

  const getBase64 = async (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  //Upload Blackout content
  const uploadBlackoutContent = async (event, ratios) => {
    const files = event.target.files;
    const file = files[0];
    const images = await getBase64(file);
    let s3Image;
    if (typeof images !== undefined) {
      s3Image = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-file`,
        { file: images },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            Authorization: localStorage.getItem("token"),
          },
        }
      );
    }
    /* */
    if (ratios === "1x1") {
      /* setScheduleBlackoutDetails({
        ...scheduleBlackoutDetails,
        blackoutContent: {["1x1"]:s3Image?.data?.uploadResult?.Location},
      }) */
      scheduleBlackoutDetails.blackoutContent["1x1"] = s3Image?.data?.uploadResult?.Location;
    } else if (ratios === "3x2") {
      scheduleBlackoutDetails.blackoutContent["3x2"] = s3Image?.data?.uploadResult?.Location;
    } else if (ratios === "16x9") {
      scheduleBlackoutDetails.blackoutContent["16x9"] = s3Image?.data?.uploadResult?.Location;
    }
    setImageUpload(!imageUpload);
    /*  

    ; */
  };

  //Message for the blackout Content
  const blackoutMessage = (event) => {
    setScheduleBlackoutDetails({
      ...scheduleBlackoutDetails,
      message: event.target.value,
    });
  };

  //Add Schedule for the Selected channel functio
  const addSchedule = () => {
    if (
      scheduleBlackoutDetails.channelName &&
      scheduleBlackoutDetails.channelId &&
      scheduleBlackoutDetails.blockRegion &&
      scheduleBlackoutDetails.endDate &&
      scheduleBlackoutDetails.startDate &&
      scheduleBlackoutDetails.message &&
      scheduleBlackoutDetails.blockRegion &&
      scheduleBlackoutDetails.blockDevice.length > 0 &&
      scheduleBlackoutDetails.blackoutContent["1x1"] &&
      scheduleBlackoutDetails.blackoutContent["16x9"] &&
      scheduleBlackoutDetails.blackoutContent["3x2"]
    ) {
      setMandatoryField(false);
      scheduleBlackoutAction(scheduleBlackoutDetails, projectDetails.p_id);
    } else {
      setMandatoryField(true);
      console.log(scheduleBlackoutDetails, "Detailsss");
    }
    // scheduleBlackoutAction(scheduleBlackoutDetails, projectDetails.p_id);
  };

  //Cancel Schedule for the Selected Channel
  const cancelSchedule = () => {
    history.push("/admin/blackoutschedule");
  };

  console.log("Image upload", scheduleBlackoutDetails);

  return (
    <div style={{ paddingLeft: "18px" }}>
      <Box className={classes.channelDetailsBox}>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography>Channel Name</Typography>
          <TextField
          disabled
            placeholder="ChannelId"
            variant="outlined"
            required
            size="Normal"
            value={channelDetails?.name}
            className={classes.textField}
            labelWidth={0}
            onChange={getChannelName}
          />{" "}
          {mandatoryField ? (
            scheduleBlackoutDetails.channelName ? null : (
              <MandatoryFieldMessage />
            )
          ) : null}
        </Box>

        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography>Channel Id</Typography>{" "}
          <TextField
          disabled
            placeholder="Enter Channel Id *"
            variant="outlined"
            required
            value={channelDetails?.id}
            size="Normal"
            className={classes.textField}
            labelWidth={0}
            onChange={getChannelId}
          />{" "}
          {mandatoryField ? (
            scheduleBlackoutDetails.channelId ? null : (
              <MandatoryFieldMessage />
            )
          ) : null}
        </Box>
      </Box>
      <Box className={classes.channelDetailsBox}>
        {console.log("Time show", timeShow)}
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography>Blackout Start</Typography>
          <TextField
            placeholder="Enter Start Time"
            type="datetime-local"
            variant="outlined"
            value={timeShow}
            required
            size="Normal"
            className={classes.textField}
            labelWidth={0}
            inputProps={{min: new Date().toISOString().split('T')[0] + 'T00:00'}}
            onChange={(event) => blackoutStartAndEndTime(event, "start")}
          />
          {mandatoryField ? (
            scheduleBlackoutDetails.startDate ? null : (
              <MandatoryFieldMessage />
            )
          ) : null}
        </Box>

        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography>Blackout End</Typography>{" "}
          <TextField
            placeholder="Enter End Time"
            variant="outlined"
            type="datetime-local"
            value={endTimeShow}
            required
            size="Normal"
            inputProps={{min: new Date().toISOString().split('T')[0] + 'T00:00'}}
            className={classes.textField}
            labelWidth={0}
            onChange={(event) => blackoutStartAndEndTime(event, "end")}
          />
          {mandatoryField ? (
            scheduleBlackoutDetails.endDate ? null : (
              <MandatoryFieldMessage />
            )
          ) : null}
        </Box>
      </Box>
      <Box className={classes.channelDetailsBox}>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography>Block Region</Typography>
          <TextField
            placeholder="Enter Region"
            variant="outlined"
            required
            size="Normal"
            className={classes.textField}
            labelWidth={0}
            onChange={addBlackoutRegion}
          />
          {mandatoryField ? (
            scheduleBlackoutDetails.blockRegion ? null : (
              <MandatoryFieldMessage />
            )
          ) : null}
        </Box>

        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography>Block Device</Typography>{" "}
          <Autocomplete
            multiple
            // disabled={imageRatio == null}
            options={projectDetails?.platformList}
            getOptionLabel={(option) => option}
            // style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                size="medium"
                className={classes.textField}
                {...params}
                placeholder="Select Platform"
                variant="outlined"
              />
            )}
            onChange={platfromsSelection}
          />
          {mandatoryField ? (
            scheduleBlackoutDetails.blockDevice.length > 0 ? null : (
              <MandatoryFieldMessage />
            )
          ) : null}
        </Box>
      </Box>
      <Typography>Blackout Content</Typography>
      <Box className={classes.channelDetailsBox}>
        <Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "10px",
            }}
          >
            <Typography>Image 1x1</Typography>
            <label htmlFor="upload-photo1" style={{ cursor: "pointer" }}>
              <Input
                style={{ display: "none" }}
                id="upload-photo1"
                name="upload-photo1"
                type="file"
                multiple
                onChange={(event) => uploadBlackoutContent(event, "1x1")}
                required
                fullWidth
                inputProps={{ accept: "image/*" }}
              />
              <Box
                className={classes.logofield}
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <Box>
                  <Typography className={classes.logoTypo}>
                    {scheduleBlackoutDetails?.blackoutContent["1x1"].length > 0
                      ? scheduleBlackoutDetails.blackoutContent["1x1"]
                      : " 1x1 Image"}
                  </Typography>
                </Box>
              </Box>
            </label>
          </Box>
          {mandatoryField ? (
            scheduleBlackoutDetails.blackoutContent["1x1"] ? null : (
              <MandatoryFieldMessage />
            )
          ) : null}
          {scheduleBlackoutDetails?.blackoutContent["1x1"].length > 0 ? (
            <img
              src={scheduleBlackoutDetails.blackoutContent["1x1"]}
              style={{
                width: "90px",
                height: "90px",
                marginTop: "5px",
                margin: "5px",
              }}
            />
          ) : null}
        </Box>
        <Box>
          {" "}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "10px",
            }}
          >
            <Typography>Image 16x9</Typography>
            <label htmlFor="upload-photo2" style={{ cursor: "pointer" }}>
              <Input
                style={{ display: "none" }}
                id="upload-photo2"
                name="upload-photo2"
                type="file"
                multiple
                onChange={(event) => uploadBlackoutContent(event, "16x9")}
                required
                fullWidth
                inputProps={{ accept: "image/*" }}
              />
              <Box
                className={classes.logofield}
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
                width="10px"
              >
                <Box>
                  <Typography className={classes.logoTypo}>
                    {scheduleBlackoutDetails?.blackoutContent["16x9"].length > 0
                      ? scheduleBlackoutDetails.blackoutContent["16x9"]
                      : "16x9 image"}
                  </Typography>
                </Box>
              </Box>
            </label>
          </Box>
          {mandatoryField ? (
            scheduleBlackoutDetails.blackoutContent["16x9"] ? null : (
              <MandatoryFieldMessage />
            )
          ) : null}
          {scheduleBlackoutDetails?.blackoutContent["16x9"].length > 0 ? (
            <img
              src={scheduleBlackoutDetails.blackoutContent["16x9"]}
              style={{
                width: "160px",
                height: "90px",
                marginTop: "5px",
                margin: "5px",
              }}
            />
          ) : null}
        </Box>
        <Box>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography>Image 3x2</Typography>
            <label htmlFor="upload-photo3" style={{ cursor: "pointer" }}>
              <Input
                style={{ display: "none" }}
                id="upload-photo3"
                name="upload-photo3"
                type="file"
                multiple
                onChange={(event) => uploadBlackoutContent(event, "3x2")}
                required
                fullWidth
                inputProps={{ accept: "image/*" }}
              />
              <Box
                className={classes.logofield}
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
                width="10px"
              >
                <Box>
                  <Typography className={classes.logoTypo}>
                    {scheduleBlackoutDetails?.blackoutContent["3x2"].length > 0
                      ? scheduleBlackoutDetails.blackoutContent["3x2"]
                      : "3x2 image"}
                  </Typography>
                </Box>
              </Box>
            </label>
          </Box>
          {mandatoryField ? (
            scheduleBlackoutDetails.blackoutContent["3x2"] ? null : (
              <MandatoryFieldMessage />
            )
          ) : null}
          {scheduleBlackoutDetails?.blackoutContent["3x2"].length > 0 ? (
            <img
              src={scheduleBlackoutDetails.blackoutContent["3x2"]}
              style={{
                width: "160px",
                height: "90px",
                marginTop: "5px",
                margin: "5px",
              }}
            />
          ) : null}
        </Box>
      </Box>
      <Box className={classes.channelDetailsBox}>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Typography>Blackout Message</Typography>
          <TextField
            placeholder="Blackout Message"
            multiline
            rows={2}
            // type="datetime-local"
            variant="outlined"
            required
            size="Normal"
            className={classes.multiLineTextField}
            labelWidth={0}
            onChange={blackoutMessage}
          />
          {mandatoryField ? (
            scheduleBlackoutDetails.message ? null : (
              <MandatoryFieldMessage />
            )
          ) : null}
        </Box>
      </Box>
      <Box className={classes.channelDetailsBox}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            height: "80px",
            //  paddingRight: "95px",
            alignItems: "center",
          }}
        >
          <Button className={classes.submitButton} onClick={addSchedule}>
            Submit
          </Button>
          <Button className={classes.cancelButton} onClick={cancelSchedule}>
            Cancel
          </Button>
        </div>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    scheduleBlackoutAction: (scheduleDetails, p_id) =>
      dispatch(scheduleBlackoutAction(scheduleDetails, p_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleBlackoutDetails);
