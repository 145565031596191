/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
      <div className={classes.main} style={{marginBottom:'50px'}}>
         <div>
            <div className={classes.left}>
            <p>
              <span>
                &copy; {1900 + new Date().getYear()}{" "}
                <a
                  href=""
                  target="_blank"
                  className={classes.a}
                >
                Horizon Broadband
                </a>
              </span>
             </p>
            </div>
         </div>
         <div className={classes.right}>
        <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="#home" className={classes.block}>
                Terms and Conditions
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="#company" className={classes.block}>
                Privacy Policy
              </a>
            </ListItem>
          </List>
        </div>
      </div>
  );
}
