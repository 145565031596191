import React from "react";
import PropTypes from "prop-types";

//Icons
import { Edit, Image as ImageIcon } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";


//Mui components import
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Input,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);
//Style import
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const MessageDictionaryTable = ({
  selected,
  setSelected,
  handleSelectAllClick,
  headCells,
  handleStatusAll,
  filteredDicts,
  filteredAddDicts,
  isSelected,
  handleClickSwitch,
  tableHead,
  tableData,
  tableHeaderColor,
  pagination,
  status,
  setOpenDict,
  editMessagePopOpen,
  addOrEdit,
  deleteMessage,handleClickCheckbox
}) => {
  const classes = useStyles();

console.log("Table mssg",filteredDicts)
  return (
    <TableContainer className={classes.container}>
      <Table
        className={classes.table}
        stickyHeader
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
          <TableRow className={classes.tableHeadRow}>
            <TableCell
              className={classes.tableCell + " " + classes.tableHeadCell}
              padding="checkbox"
            >
              <Checkbox
                indeterminate={
                  selected.length > 0 &&
                  selected.filter((dictId) =>
                    filteredDicts.map((dict) => dict.dictionary.messages[0].eventId).includes(dictId.dictionary.messages[0].eventId)
                  ).length < filteredDicts?.length &&
                  selected.filter((dictId) =>
                    filteredDicts.map((dict) => dict.dictionary.messages[0].eventId).includes(dictId.dictionary.messages[0].eventId)
                  ).length > 0
                }
                checked={
                  selected.length===filteredDicts.length
                  // selected.length > 0 &&
                  // filteredDicts?.length > 0 &&
                  // selected.filter((dictId) =>
                  //   filteredDicts.map((dict) => dict.dictionary.messages[0].eventId).includes(dictId)
                  // ).length === filteredDicts?.length
                }
                onChange={(event)=>{handleSelectAllClick(event)}}
                inputProps={{ "aria-label": "select all messages" }}
              />
            </TableCell>
            {headCells.map((headCell) => (
              <TableCell
                className={classes.tableCell + " " + classes.tableHeadCell}
                key={headCell.id}
              >
                {headCell.label}
              </TableCell>
            ))}
            <TableCell
              className={classes.tableCell + " " + classes.tableHeadCell}
            >
              <FormControlLabel
                value="top"
                control={
                  <Switch
                    className={classes.dictSwitch}
                    checked={status ? true : false}
                    inputProps={{ "aria-labelledby": "select all status" }}
                    onChange={handleStatusAll}
                  />
                }
                className={classes.dictLabel}
                style={{
                  "& .MuiFormControlLabelLabel": {
                    color: true ? "green" : "gray",
                  },
                }}
                label={status ? "Live" : "Draft"}
                labelPlacement="top"
              />

            </TableCell>
            <TableCell
              className={classes.tableCell + " " + classes.tableHeadCell}
            >
         
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {filteredDicts?.map((dict, index) => (
          
            <TableRow
              //hover
              role="checkbox"
              aria-checked={isSelected(dict._id)}
              tabIndex={-1}
              key={dict.dictionary.messages[0].eventId}
              selected={isSelected(dict._id)}
              className={classes.tableBodyRow}
            >
              <TableCell
                // padding="checkbox"
                // style={{ paddingRight: "24px", outline: "none" }}
                className={classes.tableCell + " " + classes.tableBodyCell}
              >
                <Checkbox
                  checked={isSelected(dict._id)}
                  onClick={() => handleClickCheckbox(dict)}
                />
              </TableCell>
              <TableCell
                component="th"
                id={`enhanced-table-checkbox-${index}`}
                scope="row"
                //  style={{ paddingLeft: "24px" }}
                className={classes.tableCell + " " + classes.tableBodyCell}
              >
                {dict.dictionary.messages[0].event}
              </TableCell>
              <TableCell
                className={classes.tableCell + " " + classes.tableBodyCell}
              >
                {dict.dictionary.messages[0].code}
              </TableCell>
              <TableCell
                className={classes.tableCell + " " + classes.tableBodyCell}
              >
                {dict.dictionary.messages[0].message}
              </TableCell>
              <TableCell
                className={classes.tableCell + " " + classes.tableBodyCell}
              >
                {dict.dictionary.messages[0].actions?.join(", ")}
              </TableCell>
              <TableCell
                className={classes.tableCell + " " + classes.tableBodyCell}
              >
                <Switch
                  className={classes.dictSwitch}
                  checked={dict.dictionary.messages[0].status === "LIVE" ? true : false}
                  inputProps={{
                    "aria-labelledby": `enhanced-table-switch-${index}`,
                  }}
                  onClick={() => handleClickSwitch(dict.dictionary.messages[0].eventId)}
                />
              </TableCell>
              <TableCell
                className={classes.tableCell + " " + classes.tableBodyCell}
            >
              <Edit onClick={()=>{editMessagePopOpen(dict)}} />
            </TableCell>
            <TableCell
                className={classes.tableCell + " " + classes.tableBodyCell}
            >
              <DeleteIcon onClick={()=>{deleteMessage(dict)}} />
            </TableCell>
            </TableRow>
          ))}
          {console.log("Table Cell")}

          {addOrEdit=='Add'&&filteredAddDicts?.map((dict, index) => (
            <TableRow
              // hover
              role="checkbox"
              aria-checked={isSelected(dict.eventId)}
              tabIndex={-1}
              key={dict.eventId}
              selected={isSelected(dict.eventId)}
              className={classes.tableBodyRow}
            >
              <TableCell
                className={classes.tableCell + " " + classes.tableBodyCell}
              >
                <Checkbox
                  checked={isSelected(dict.eventId)}
                  onClick={() => handleClickCheckbox(dict)}
                />
              </TableCell>
              <TableCell
                component="th"
                id={`enhanced-table-checkbox-${index}`}
                scope="row"
                className={classes.tableCell + " " + classes.tableBodyCell}
              >
                {dict.event}
              </TableCell>
              <TableCell
                className={classes.tableCell + " " + classes.tableBodyCell}
              >
                {dict.code}
              </TableCell>
              <TableCell
                className={classes.tableCell + " " + classes.tableBodyCell}
              >
                {dict.message}
              </TableCell>
              <TableCell
                className={classes.tableCell + " " + classes.tableBodyCell}
              >
                {dict.actions?.join(", ")}
              </TableCell>
              <TableCell
                className={classes.tableCell + " " + classes.tableBodyCell}
              >
                <Switch
                  className={classes.dictSwitch}
                  checked={dict.status === "LIVE" ? true : false}
                  inputProps={{
                    "aria-labelledby": `enhanced-table-switch-${index}`,
                  }}
                  onClick={() => handleClickSwitch(dict.eventId, "add")}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
MessageDictionaryTable.defaultProps = {
  tableHeaderColor: "gray",
};

MessageDictionaryTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default MessageDictionaryTable;
