import React from 'react';

const Help = () => {


  const handleTileClick = (url) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      alert('Video is coming soon!');
    }
  };

  const sections = [
    {
      title: 'Projects',
      tiles: [
        {
          text: 'Create Project',
          url: '', 
          
        },
        {
          text: 'Edit Project',
          url: '', 
        },
        {
          text: 'Delete Project',
          url: '', 
        },
      ],
    },
    
    {
      title: 'Screens',
      tiles: [
        {
          text: 'Create Screen',
          url: 'https://youtu.be/WS-hN3SfmVE', 
        },
        {
          text: 'Publish/Unpublish Screen',
          url: 'https://www.youtube.com/watch?v=VFj2iPrF8WU', 
        },
        {
          text: 'Reorder  screen',
          url: 'https://youtu.be/W67uBe1Wcyc?si=aZDkryY83ATjmFWf', 
        },
      ],
    },

    {
      title: 'Widgets',
      tiles: [
        {
          text: 'Create Widget',
          url: '', 
        },
        {
          text: 'Add Widget',
          url: ' https://youtu.be/tQyH2JT830E?si=vQnG8U8qwFZSGWjH', 
        },
        {
          text: 'Reorder widget',
          url: 'https://youtu.be/ED3ha2_7bjk?si=MqeNTDBnQ0lzqF-P', 
        },
      ],
    },
    {
      title: 'Admin',
      tiles: [
        {
          text: 'Create Admin',
          url: '', 
        },
        {
          text: 'Delete Admin',
          url: '', 
        },
        
      ],
    }
  ];

  const getTileBackgroundColor = (sectionTitle) => {
    switch (sectionTitle) {
      case 'Projects':
        return '#c9c1d5';
      case 'Screens':
        return '#80c280';
      case 'Widgets':
        return '#64c7f078'
      case 'Admin':
        return '#fdfd9c'
      default:
        return '#f0f0f0';
    }
  };

  return (
    <div>
      {sections.map((section, index) => (
        <div key={index} style={{ marginBottom: '40px' }}>
          <h3><b>{section.title}</b></h3>
          <div style={{ display: 'flex', gap: '20px' }}>
            {section.tiles.map((tile, idx) => (
              <div
                key={idx}
                onClick={() => handleTileClick(tile.url)}
                style={{
                  width: '300px', 
                  height: '150px', 
                  padding: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  borderColor: 'purple',
                  backgroundColor: getTileBackgroundColor(section.title),
                  borderRadius: '15px', 
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: "0px 5px 10px 0px black",
                  transition: 'transform 0.2s',
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = 'translateY(-10px)';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = 'translateY(0)';
                }}
              >
                {/* <span><h4><b>{tile.text}</b></h4></span> */}
                <h4 style={{ color: 'black', fontWeight: 'bold', margin: 0 }}>
                  {tile.text}
                </h4>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Help;

