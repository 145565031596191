import { drawerWidth, transition, container } from "assets/jss/material-dashboard-react.js";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    background: "linear-gradient(135.7deg, #E0F1FF -1.85%, #ECE2FF 98.91%)",
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    overflowScrolling: "touch",
  },
  mainPanelOp: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 90px)`,
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    overflowScrolling: "touch",
  },
  /* appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
   ...transition
  },*/
  content: {
    marginRight: "70px",
    padding: "30px 15px",
    // minHeight: "calc(100vh - 123px)",
  },
  container,
  map: {
    // marginTop: "20px",
  },
});

export default appStyle;
