import { LoginActionType, SuccessfullyLoginType ,UNSUCCESSFULL_LOGIN} from "../actionTypes/loginActionType"

export const LoginAction = (authDetails)=>{
    return {type:LoginActionType,payload:authDetails}
}

export const SuccessfullyLoginAction = (token)=>{
    return {type:SuccessfullyLoginType,payload:token}
}

export const UnsuccesFullyLogin=(errorMessage)=>{
return{type:UNSUCCESSFULL_LOGIN,action:errorMessage}
}