import {
  blackColor,
  boxShadow,
  dangerColor,
  darkText,
  defaultFont,
  drawerWidth,
  grayColor,
  hexToRgb,
  infoColor,
  primaryBoxShadow,
  primaryColor,
  successColor,
  transition,
  warningColor,
  whiteColor,
} from "assets/jss/material-dashboard-react.js";

const sidebarStyle = (theme) => ({
  drawerPaper: {
    background: "#3f51b5",
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "1",
    flexShrink: 0,
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,

      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    /*transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }), */
    ...transition,
    display: "flex",
    justifyContent: "space-between",
  },
  drawerClose: {
    /* transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }), */
    ...transition,
    overflowX: "hidden",
    width: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(10.5),
    },
  },
  appVersion: {
    display: "flex",
    justifyContent: "center",
    color: "white",
    fontWeight: 600,
    opacity: 0,
    animation: "$itemText 0s .19s forwards",
  },
  drawerPaperRTL: {
    [theme.breakpoints.up("md")]: {
      left: "auto !important",
      right: "0 !important",
    },
    [theme.breakpoints.down("sm")]: {
      left: "0  !important",
      right: "auto !important",
    },
  },
  logo: {
    position: "relative",
    padding: "15px 15px",
    zIndex: "4",
  },
  logoLink: {
    ...defaultFont,
    textTransform: "uppercase",
    padding: "5px 0",
    display: "flex",
    justifyContent: "center",
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: whiteColor,
    },
  },
  logoLinkRTL: {
    textAlign: "right",
  },
  logoImage: {
    width: "50px",

    display: "inline-block",
    height: "50px",
    // marginLeft: "10px",
    // marginRight: "15px",
  },
  img: {
    width: "50px",
    height: "50px",
    top: "22px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0",
   // border radius only for vivre logo
    //"border-radius": "50px",
  },
  background: {
    backgroundColor: "red",
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: blackColor,
      opacity: ".8",
    },
  },
  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset",
  },
  item: {
    position: "relative",
    display: "block",
    height: "50px",
    textDecoration: "none",
    marginLeft: "10px",

    "&:hover,&:focus,&:visited,&": {
      color: "grey",
    },
  },
  itemLink: {
    width: "auto",
    // transition: "all 300ms linear",
    margin: "10px 15px 0",

    position: "relative",
    display: "block",
    padding: "10px 8px",
    // backgroundColor: "transparent",

    ...defaultFont,
  },

  itemIcon: {
    width: "25px",
    height: "25px",
    marginTop: "6px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
  },
  itemIconRTL: {
    marginRight: "3px",
    marginLeft: "15px",
    float: "right",
  },
  itemText: {
    ...defaultFont,
    margin: "0",
    opacity: 0,
    animation: "$itemText 0s .19s forwards",
    lineHeight: "30px",
    fontSize: "14px",
    color: whiteColor,
  },
  "@keyframes itemText": {
    "to": { opacity: 1 },
  },
  itemTextRTL: {
    textAlign: "right",
  },
  whiteFont: {
    color: darkText,
  },
  purple: {
    backgroundColor: primaryColor[0],
    ...primaryBoxShadow,
    "&:hover,&:focus": {
      backgroundColor: primaryColor[0],
      ...primaryBoxShadow,
    },
  },
  blue: {
    borderBottomLeftRadius: "50px",
    borderTopLeftRadius: "50px",
    backgroundColor: "#e6f4ff",
    // zIndex:'99999',
    "&:before": {
      content: '""',
      position: "absolute",
      right: 0,

      top: -40,

      height: "40px",
      width: "40px",
      borderBottomRightRadius: "40%",
      boxShadow: "0 20px 0 0 #e6f4ff",
    },
    "&:after": {
      content: '""',
      position: "absolute",
      right: 0,
      height: "50px",

      bottom: -40,

      height: "40px",
      width: "40px",
      borderTopRightRadius: "40%",
      boxShadow: "20px 0 0 0 #e6f4ff",
    },
    /*// boxShadow:
     // "0 12px 20px -10px rgba(" +
      hexToRgb(infoColor[4]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(infoColor[4]) +
      ",.2)",*/
    "&:hover,&:focus": {
      background: "#e6f4ff",
      /* boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(infoColor[4]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(infoColor[4]) +
        ",.2)",*/
    },
  },
  green: {
    backgroundColor: successColor[0],
    boxShadow: "0 12px 20px -10px rgba(" +
      hexToRgb(successColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(successColor[0]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: successColor[0],
      boxShadow: "0 12px 20px -10px rgba(" +
        hexToRgb(successColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(successColor[0]) +
        ",.2)",
    },
  },
  orange: {
    backgroundColor: warningColor[0],
    boxShadow: "0 12px 20px -10px rgba(" +
      hexToRgb(warningColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(warningColor[0]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: warningColor[0],
      boxShadow: "0 12px 20px -10px rgba(" +
        hexToRgb(warningColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ",.2)",
    },
  },
  red: {
    backgroundColor: dangerColor[0],
    boxShadow: "0 12px 20px -10px rgba(" +
      hexToRgb(dangerColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(dangerColor[0]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: dangerColor[0],
      boxShadow: "0 12px 20px -10px rgba(" +
        hexToRgb(dangerColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ",.2)",
    },
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflow: "auto",
    width: drawerWidth,
    zIndex: "4",
    overflowScrolling: "touch",
  },
  activePro: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: "100%",
      bottom: "13px",
    },
  },
});

export default sidebarStyle;
